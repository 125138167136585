import { memo, ReactNode } from 'react'
import clsx from 'clsx'

import { useSider } from './sider.context'

import _ from './sider.module.css'

interface ISider {
    children: ReactNode
}

const Sider = ({ children }: ISider) => {
    const { closed, close, open } = useSider()

    return (
        <>
            <div className={clsx(_.wrap, closed && _.closed)}>
                <div className={_.buttonFilter} onClick={() => (closed ? open() : close())}>
                    Фильтры
                </div>
                {children}
            </div>
        </>
    )
}

export default memo(Sider)
