import React, { memo, useEffect, useState } from 'react'
import { ColorPicker, Modal, Select } from 'antd'
import clsx from 'clsx'

import Button from 'components/Button'
import Table from 'components/Table'
// import ColorPicker from 'app/component/color-picker/color-picker'

import { setOptionsFormat, showErrors, inputCheckErrors, selectCheckErrors } from 'pages/EditorPage/helpers'

import _ from '../../CreateModal.module.css'
import { Color } from 'antd/es/color-picker'

const CreateBrandModal = ({ onClose, onCreate, dataList, open }: any) => {
    const [errors, setErrors] = useState<string[]>([])

    const [name, setName] = useState('')
    const [shortname, setShortname] = useState('')
    const [color, setColor] = useState('#ffffff')
    const [company, setCompany] = useState(null)

    const [options, setOptions] = useState(dataList.companies ? setOptionsFormat(dataList.companies) : [])

    useEffect(() => {
        if (dataList.companies?.length) setOptions(setOptionsFormat(dataList.companies))
    }, [JSON.stringify(dataList.companies)]) // eslint-disable-line

    const inputCheckField = inputCheckErrors(setErrors, errors)
    const selectCheckField = selectCheckErrors(setErrors, errors)

    return (
        <Modal onCancel={onClose} title='Добавление бренда' open={open} style={{ width: 400 }} footer={null}>
            <Table style={{ width: '100%' }}>
                <tr>
                    <td>Имя*</td>
                    <td>
                        <input
                            className={clsx(_.input, errors.includes('name') && _.error)}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onBlur={() => inputCheckField(setName, name, 'name')}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Сокращение*</td>
                    <td>
                        <input
                            className={clsx(_.input, errors.includes('short_name') && _.error)}
                            value={shortname}
                            onChange={(e) => setShortname(e.target.value)}
                            onBlur={() => inputCheckField(setShortname, shortname, 'short_name')}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Компания*</td>
                    <td>
                        <Select
                            style={{ width: '100%' }}
                            value={company}
                            placeholder='Компания'
                            options={options}
                            onChange={setCompany}
                            className={clsx(errors.includes('company') && _.error)}
                            onBlur={() => selectCheckField(company, 'company')}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Цвет*</td>
                    <td>
                        <ColorPicker
                            onChangeComplete={(color: Color) => setColor(`#${color.toHex()}`)}
                            value={color}
                            format='hex'
                        />
                    </td>
                </tr>
            </Table>
            <div className={_.btnContainer}>
                <Button name='Отмена' mods={['blue']} onClick={onClose} />
                <Button
                    name='Сохранить'
                    onClick={() => {
                        if (name && shortname && company)
                            onCreate({ name, short_name: shortname, color, company_id: company })
                        showErrors({ name, shortname, company }, setErrors, ['company'])
                    }}
                />
            </div>
        </Modal>
    )
}

export default memo(CreateBrandModal)
