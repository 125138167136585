// custom icons
import materialSupply from 'assets/state_material_supply.svg'
import changeDraft from 'assets/state_change_draft.svg'
import preparation from 'assets/state_preparation.svg'
import preReport from 'assets/state_pre_report.svg'
import appointed from 'assets/state_appointed.svg'
import byClient from 'assets/state_by_client.svg'
import deliver from 'assets/state_deliver.svg'
import search from 'assets/state_search.svg'
import order from 'assets/state_order.svg'
import wip from 'assets/state_wip.svg'

import {
  FaBuilding,
  FaTimes,
  FaDraftingCompass,
  FaPencilAlt,
} from 'react-icons/fa';
import {
  GiElectricalResistance,
  GiComputerFan,
  GiDrill,
  GiSandsOfTime,
  GiTv,
} from 'react-icons/gi';
import { GoGear } from 'react-icons/go';
import {
  MdLibraryAdd,
  MdWork,
  MdComputer,
  MdDone,
  MdDoneAll,
} from 'react-icons/md';

export const branchesIcons = {
  'Аудио/Видео': <GiTv />,
  Вентиляция: <GiComputerFan />,
  Модернизация: <GoGear />,
  Стройка: <FaBuilding />,
  'Техническая эксплуатация': <GiDrill />,
  Электричество: <GiElectricalResistance />,
};

const style = { width: 17 }

export const statesIcons = {
  'Новая': <MdLibraryAdd />,
  'Назначена': <img style={style} src={appointed} />,
  'Отклонена': <FaTimes />,
  'Осмотр': <img style={style} src={search} />,
  'Заказ у клиента': <img style={style} src={byClient} />,
  'Выполнение работ': <img style={style} src={wip} />,
  'Поставка материала': <img style={style} src={materialSupply} />,
  'Предотчет': <img style={style} src={preReport} />,
  'Подготовка КП': <img style={style} src={preparation} />,
  'Согласование сметы': <FaDraftingCompass />,
  'Смета изменена': <img style={style} src={changeDraft} />,
  'Доставка': <img style={style} src={deliver} />,
  'Заказ материалов': <img style={style} src={order} />,
  'Выполнено': <MdDone />,
  'Подписание': <FaPencilAlt />,
  'Закрыта': <MdDoneAll />,
  'Возвращена': '',
  'Дубль': '',
  'В ожидании': <GiSandsOfTime />,
  'В работе': <MdWork />,
  'Согласована, в работе': <MdComputer />,
};

