import React, { memo, useEffect, useState } from 'react'
import { ColorPicker, Modal, Select } from 'antd'
import clsx from 'clsx'

import Button from 'components/Button'
import Table from 'components/Table'

import { setOptionsFormat, showErrors, inputCheckErrors, selectCheckErrors } from 'pages/EditorPage/helpers'

import _ from '../../CreateModal.module.css'
import { Color } from 'antd/es/color-picker'

const CreateBuildingModal = ({ onClose, onCreate, dataList, open }: any) => {
    const [errors, setErrors] = useState<string[]>([])

    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [color, setColor] = useState('#ffffff')
    const [city, setCity] = useState(null)

    const [options, setOptions] = useState(dataList.cities ? setOptionsFormat(dataList.cities) : [])

    useEffect(() => {
        if (dataList.cities?.length) setOptions(setOptionsFormat(dataList.cities))
    }, [JSON.stringify(dataList.cities)]) // eslint-disable-line

    const inputCheckField = inputCheckErrors(setErrors, errors)
    const selectCheckField = selectCheckErrors(setErrors, errors)

    return (
        <Modal open={open} onCancel={onClose} title='Добавление ТЦ' footer={null} style={{ width: 400 }}>
            <Table style={{ width: '100%' }}>
                <tr>
                    <td>Имя*</td>
                    <td>
                        <input
                            className={clsx(_.input, errors.includes('name') && _.error)}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onBlur={() => inputCheckField(setName, name, 'name')}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Адрес*</td>
                    <td>
                        <input
                            className={clsx(_.input, errors.includes('address') && _.error)}
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            onBlur={() => inputCheckField(setAddress, address, 'address')}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Город*</td>
                    <td>
                        <Select
                            style={{ width: '100%' }}
                            value={city}
                            placeholder='Выберите город'
                            options={options}
                            onChange={setCity}
                            className={clsx(errors.includes('city') && _.error)}
                            onBlur={() => selectCheckField(city, 'city')}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Цвет</td>
                    <td>
                        <ColorPicker value={color} onChangeComplete={(color: Color) => setColor(`#${color.toHex()}`)} />
                    </td>
                </tr>
            </Table>
            <div className={_.btnContainer}>
                <Button name='Отмена' mods={['blue']} onClick={onClose} />
                <Button
                    name='Сохранить'
                    onClick={() => {
                        if (name && address && city)
                            onCreate({
                                name,
                                address,
                                color,
                                city_id: city,
                            })
                        showErrors({ name, address, city }, setErrors, ['city'])
                    }}
                />
            </div>
        </Modal>
    )
}

export default memo(CreateBuildingModal)
