import { memo, ReactNode } from 'react'
import clsx from 'clsx'
import { AiFillCaretUp, AiOutlineClear, AiOutlineClose } from 'react-icons/ai'

import { IEditorColumn } from 'types'

import _ from './styles.module.css'

interface ITable {
    children?: ReactNode
    headers?: IEditorColumn[]
    sorts?: any
    headerClick?: (h: IEditorColumn) => void
    resetSort?: (force?: boolean) => void
    style?: React.CSSProperties
    showBorder?: boolean
    isSticky?: boolean
}

const Table = ({ style = {}, children, headers = [], showBorder, isSticky, headerClick, sorts, resetSort }: ITable) => (
    <table style={style} className={clsx(_.table, showBorder && _.tableBorders, isSticky && _.stickyTable)}>
        {headers.length > 0 && (
            <thead>
                <tr>
                    {headers.map((h: any) => (
                        <td
                            onClick={() => headerClick && headerClick(h)}
                            key={h.name}
                            style={{ width: h.width, minWidth: h.minWidth || h.width }}
                            className={clsx(_.headerCell, sorts && 'pointer')}
                        >
                            {h.name}

                            {sorts && sorts[h.path] && (
                                <div className={clsx(_.arrow, sorts[h.path] === 'desc' && _.rotate)}>
                                    <AiFillCaretUp />
                                </div>
                            )}
                        </td>
                    ))}
                    {resetSort && (
                        <td className={clsx(_.headerCell, _.sortCell)}>
                            <AiOutlineClear title='Сброс сортировок' className={_.icon} onClick={(e) => resetSort()} />
                            <AiOutlineClose
                                title='Очистка сортировок'
                                className={_.icon}
                                onClick={(e) => resetSort(true)}
                            />
                        </td>
                    )}
                </tr>
            </thead>
        )}
        <tbody>{children}</tbody>
    </table>
)

export default memo(Table)
