import { memo, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'

import { IPriority } from 'types'
import { SHOPS_QUERY } from 'connectors/gql/shops.gql'
import { PRIORITIES_QUERY } from 'connectors/gql/priorities.gql'
import { DRAFT_UPDATE, DRAFTS_QUERY } from 'connectors/gql/drafts.gql'

import { setOptionsFormat } from 'pages/EditorPage/helpers'
import { useEntity, useUpdate } from 'pages/EditorPage/hooks'

import Table from 'components/Table'
import Loader from 'components/Loader'
import Row from '../../components/Row'

import { columns } from './columns'

import _ from '../styles.module.css'

interface IShopPrepared {
    id: number
    name: string
}

const filters = {}
// @ts-ignore
columns.forEach((c) => (filters[c.path] = null))

const Drafts = () => {
    const [draftList, draftsLoading, refetchDrafts] = useEntity(DRAFTS_QUERY, 'drafts')

    const [shops, shopsLoading] = useEntity(SHOPS_QUERY, 'shops', { sorting: [], filters: [], perPage: 999 })
    const { data: prioritiesList } = useQuery(PRIORITIES_QUERY)

    const [priorities, setPriorities] = useState(prioritiesList?.ticket || [])
    const [prioritiesPrepared, setPrioritiesPrepared] = useState(setOptionsFormat(priorities) || [])
    const [shopsPrepared, setShopsPrepared] = useState<IShopPrepared[]>([])

    useEffect(() => {
        if (prioritiesList) {
            setPriorities(prioritiesList.priorities)
            setPrioritiesPrepared(prioritiesList.priorities.map((p: IPriority) => ({ id: p.id, name: p.name })))
        }
    }, [JSON.stringify(prioritiesList?.priorities || {})]) // eslint-disable-line

    useEffect(() => {
        const _list = shops
            .filter((l: any) => l.state.id !== '4' && l.state.id !== '3')
            .map((l: any) => {
                const label = `${l.building.city.name} ТЦ ${l.building.name} - ${l.brand.name} ${l.code}`
                return {
                    id: l.id,
                    name: label,
                }
            })

        setShopsPrepared(_list)
    }, [JSON.stringify(shops)])

    const updateAction = useUpdate(DRAFT_UPDATE, 'draft')

    const _updateAction = (id: number) => (value: number) => {
        updateAction(id)(value)
        refetchDrafts()
    }

    return (
        <div className={_.wrapper}>
            <div className={_.title}>Черновики</div>

            <div className={_.tableWrapper}>
                <Table headers={columns} headerClick={() => {}} showBorder isSticky>
                    {draftList.map((b: any) => (
                        <Row
                            key={b.id}
                            {...b}
                            columns={columns}
                            dataListForField={{
                                shop: shopsPrepared,
                                priority: prioritiesPrepared,
                            }}
                            updateAction={_updateAction}
                        />
                    ))}
                </Table>
            </div>

            {(draftsLoading || shopsLoading) && <Loader />}
        </div>
    )
}

export default memo(Drafts)
