import React, { memo } from 'react'
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai'
import clsx from 'clsx'

import _ from './styles.module.css'

interface IButtonSizeProps {
    onClick?: () => void
    bigSize?: boolean
}

const ButtonSize = ({ onClick, bigSize }: IButtonSizeProps) => {
    return (
        <div className={clsx(_.button)} onClick={onClick}>
            {bigSize ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />}
        </div>
    )
}

export default memo(ButtonSize)
