import React, { memo, useState } from 'react'
import { useMutation } from '@apollo/client'
import DatePicker from 'react-date-picker'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { FaShoppingBag, FaStoreAlt } from 'react-icons/fa'
import { MdLocationCity } from 'react-icons/md'
import clsx from 'clsx'

import {
    TICKET_UPDATE_DETAILS,
    TICKET_UPDATE_MILESTONE,
    TICKET_UPDATE_STATE,
    TICKET_UPDATE_TOPIC,
    TICKET_UPDATE_PRIORITY,
} from 'connectors/gql/ticket.gql'

import { dateFmt, dateFmtFromStr } from 'tools'
import { ticketMutationProps, useTextMutation } from '../../helper'
import { ClickableTicketCode } from 'components/ClickableTicketCode'

import TextAreaWithButtonSize from 'components/TextAreaWithButtonSize'
import TicketStatesSelect from 'components/EntitySelects/TicketStatesSelect'
import PrioritiesSelect from 'components/EntitySelects/PrioritiesSelect'
import ShopData from './components/ShopData'

import _ from './styles.module.css'

const TicketMainData = ({
    id,
    external_code,
    number,
    created,
    milestone,
    state,
    priority,
    shop,
    details,
    topic,
    type,
}: any) => {
    const [det, setDet] = useState(details || '')
    const [top, setTop] = useState(details || '')
    const [showShopData, setShowShopData] = useState(false)

    const [milestoneMutator] = useMutation(TICKET_UPDATE_MILESTONE, ticketMutationProps)
    const setMilestone = (value: Date) => {
        milestoneMutator({ variables: { id, value: value.getTime() } })
    }

    const [stateMutator] = useMutation(TICKET_UPDATE_STATE, ticketMutationProps)
    const setState = (value: number) => stateMutator({ variables: { id, value } })

    const [priorityMutator] = useMutation(TICKET_UPDATE_PRIORITY, ticketMutationProps)
    const setPriority = (value: number) => priorityMutator({ variables: { id, value } })

    const updateDetails = useTextMutation(id, TICKET_UPDATE_DETAILS, ticketMutationProps, det, setDet)
    const updateTopic = useTextMutation(id, TICKET_UPDATE_TOPIC, ticketMutationProps, top, setTop)

    return (
        <div className={_.wrapper}>
            <div className={_.header}>
                Заявка №{' '}
                <span className={_.blueColor}>
                    {type?.code || ''}
                    {number} (<ClickableTicketCode code={external_code || ''} />)
                </span>{' '}
                от {created && dateFmtFromStr(created)}
            </div>

            <div className={_.topRow}>
                <TicketStatesSelect value={state?.id} onChange={setState} isSmall />
                <PrioritiesSelect value={priority?.id} onChange={setPriority} isSmall />

                <div className={_.dateWrapper}>
                    <AiOutlineClockCircle className={_.dateIcon} />
                    <DatePicker
                        onChange={setMilestone}
                        value={milestone && new Date(milestone)}
                        calendarIcon={null}
                        clearIcon={null}
                        format='dd.MM.y'
                    />
                </div>

                <div className={_.shopData} onClick={() => setShowShopData(true)}>
                    <FaShoppingBag title='Бренд' />
                    <span>{shop?.brand?.name}</span>
                    <FaStoreAlt title='ТЦ' />
                    <span>ТЦ {shop?.building?.name}</span>
                    <MdLocationCity title='Город' />
                    <span>{shop?.building?.city?.name}</span>

                    {shop && showShopData && <ShopData shop={shop} />}
                </div>
            </div>

            <div>
                <input
                    type='text'
                    className={clsx(_.textInput, _.longInput)}
                    defaultValue={topic}
                    onBlur={updateTopic}
                    placeholder='Тема'
                />
            </div>

            <div>
                <TextAreaWithButtonSize
                    triggers={[details]}
                    className={clsx(_.textarea, _.longInput)}
                    defaultValue={details}
                    onBlur={updateDetails}
                />
            </div>

            {showShopData && <div className={_.hiderShopData} onClick={() => setShowShopData(false)} />}
        </div>
    )
}

export default memo(TicketMainData)
