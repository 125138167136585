import React from 'react'
import { FaQuestion } from 'react-icons/fa';
import { dateFmtFromStr } from 'tools'
import { Flex, Popover } from 'antd';

import  {statesIcons, branchesIcons} from './iconsConst'
import { ClickableTicketCode } from 'components/ClickableTicketCode';

const priorityColor = {
    '1': '#52c41a',
    '2': '#ffa940',
    '3': '#ff4d4f',
}

const content = (icons) => (
    <div>
      {Object.keys(icons).map(name => <div>{icons[name]} {name}</div>)}
    </div>
  );

const stateIconHelp = () => (
    <Popover content={content(statesIcons)} trigger="hover" placement="rightTop">
        <FaQuestion />
    </Popover>
)
const branchIconHelp = () => (
    <Popover content={content(branchesIcons)} trigger="hover" placement="rightTop">
        <FaQuestion />
    </Popover>
)

export const columnsList = [
    { value: 'priority', title: 'Приоритет', show: true},
    { value: 'state-icon', title: 'Статус (иконка)', Data: stateIconHelp, show: true},
    { value: 'state', title: 'Статус', show: false},
    { value: 'external_code', title: 'Внешний номер', show: false},
    { value: 'branch-icon', title: 'Отрасль (иконка)', Data: branchIconHelp, show: true},
    { value: 'branch', title: 'Отрасль', show: false},
    { value: 'topic', title: 'Тема', show: false},
    { value: 'shop', title: 'Объект', show: true},
    { value: 'city', title: 'Город', show: true},
    { value: 'building', title: 'ТЦ', show: true},
    { value: 'brand', title: 'Бренд', show: true},
    { value: 'shop-code', title: 'Номер объекта', show: false},
    { value: 'details', title: 'Описание заявки', show: true},
    { value: 'complete_jobs', title: 'Описание выполнения', show: true},
    { value: 'supplied_by_client', title: 'Поставлено заказчиком', show: false},
    { value: 'supplied_by_service', title: 'Поставлено исполнителем', show: false},
    { value: 'left_in_shop', title: 'Оставлено на объекте', show: false},
    { value: 'technical_remark', title: 'Замечания', show: false},
    { value: 'created', title: 'Создана', show: true},
    { value: 'milestone', title: 'Срок выполнения', show: true},
    { value: 'closed', title: 'Дата закрытия', show: true},
    { value: 'longRow', title: 'Набор данных', show: true},
]

const longRowStyle = { overflow: 'hidden', whiteSpace: 'initial', maxHeight: 38 };

export const columns = [
    {
        title: '',
        dataIndex: 'priority',
        key: 'priority',
        width: 30,
        render: (priority) => <div style={{ width: 13, height: 30, background: priorityColor[priority?.id] }} title={priority?.name} />
    },
    {
        title: '',
        dataIndex: 'state',
        key: 'state-icon',
        width: 30,
        render: (state) => <div style={{ fontSize: 18 }} title={state?.name}>{statesIcons[state?.name] || ''}</div>
    },
    {
        title: 'Статус',
        dataIndex: 'state',
        key: 'state',
        width: 150,
        render: (state) => <div title={state?.name}>{state?.name || ''}</div>
    },
    {
        title: 'Внешний номер',
        dataIndex: 'external_code',
        key: 'external_code',
        width: 70,
        render: (code) => <ClickableTicketCode code={code || ''} />
    },
    {
        title: '',
        dataIndex: 'branch',
        key: 'branch-icon',
        width: 30,
        render: (branch) => <span style={{ fontSize: 18 }} title={branch?.name}>{branchesIcons[branch?.name] || ''}</span>
    },
    {
        title: 'Отрасль',
        dataIndex: 'branch',
        key: 'branch',
        width: 145,
        render: (branch) => <div>{branch?.name}</div>
    },
    {
        title: 'Тема',
        dataIndex: 'topic',
        key: 'topic',
        width: '40%',
        render: (topic) => <div style={longRowStyle} title={topic}>{topic}</div>
    },   
    {
        title: 'Объект',
        dataIndex: 'shop',
        key: 'shop',
        width: 180,
        render: (shop) => `${shop?.brand?.short_name}: ${shop?.building?.name} - ${shop?.building?.city?.abbreviation}`
    },
    {
        title: 'Город',
        dataIndex: 'shop',
        key: 'city',
        width: 120,
        render: (shop) => shop?.building?.city?.name
    },
    {
        title: 'ТЦ',
        dataIndex: 'shop',
        key: 'building',
        width: 110,
        render: (shop) => shop?.building?.name
    },
    {
        title: 'Бренд',
        dataIndex: 'shop',
        key: 'brand',
        width: 40,
        render: (shop) => shop?.brand?.name
    },
    {
        title: 'Номер объекта',
        dataIndex: 'shop',
        key: 'shop-code',
        width: 75,
        render: (shop) => shop?.code
    },
    {
        title: 'Описание заявки',
        dataIndex: 'details',
        key: 'details',
        width: '40%',
        render: (details) => <div style={longRowStyle} title={details}>{details}</div>
    },
    {
        title: 'Описание выполнения',
        dataIndex: 'complete_jobs',
        key: 'complete_jobs',
        width: '40%',
        render: (jobs) => <div style={longRowStyle} title={jobs}>{jobs}</div>
    },
    {
        title: 'Поставлено заказчиком',
        dataIndex: 'supplied_by_client',
        key: 'supplied_by_client',
        width: '40%',
        render: (upplied) => <div style={longRowStyle} title={upplied}>{upplied}</div>
    },
    {
        title: 'Поставлено исполнителем',
        dataIndex: 'supplied_by_service',
        key: 'supplied_by_service',
        width: '40%',
        render: (upplied) => <div style={longRowStyle} title={upplied}>{upplied}</div>
    },
    {
        title: 'Оставлено на объекте',
        dataIndex: 'left_in_shop',
        key: 'left_in_shop',
        width: '40%',
        render: (left) => <div style={longRowStyle} title={left}>{left}</div>
    },
    {
        title: 'Замечания',
        dataIndex: 'technical_remark',
        key: 'technical_remark',
        width: '40%',
        render: (remark) => <div style={longRowStyle} title={remark}>{remark}</div>
    },    
    {
        title: 'Набор данных',
        dataIndex: 'longRow',
        key: 'longRow',
        minWidth: '40%',
        render: (_, record) =>  {
            const obj = `${record?.shop?.brand?.short_name}: ${record?.shop?.building?.name} - ${record?.shop?.building?.city?.abbreviation}`

            return (
                <Flex style={{whiteSpace: 'initial'}}>
                    <b style={{ minWidth: 180 }}>{obj}</b>
                    <div>{record.details}</div>
                </Flex>
            )
        }
    }, 
    {
        title: 'Создана',
        dataIndex: 'created',
        key: 'created',
        width: 65,
        render: (date) => dateFmtFromStr(date)
    },
    {
        title: 'Срок выполнения',
        dataIndex: 'milestone',
        key: 'milestone',
        width: 65,
        render: (date) => dateFmtFromStr(date)
    },
    {
        title: 'Дата закрытия',
        dataIndex: 'closed',
        key: 'closed',
        width: 65,
        render: (date) => dateFmtFromStr(date)
    },
   
    // {
    //     title: 'Сообщений',
    //     dataIndex: 'messages',
    //     key: 'messages',
    // }, 
]
