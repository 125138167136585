import { IEditorColumn } from 'types'

export const defaultSorts = [
    { path: 'name', type: 'input', sort: 'asc' },
    { path: 'presence', type: 'checkbox', sort: 'asc' },
]

export const columns: IEditorColumn[] = [
    {
        name: 'ID',
        path: 'id',
        type: 'id',
        width: '30px',
    },
    {
        name: 'Имя',
        path: 'name',
        type: 'input',
        width: '225px',
    },
    {
        name: 'Аббр.',
        path: 'abbreviation',
        type: 'input',
        width: '65px',
    },
    {
        name: 'Регион',
        path: 'region',
        type: 'select',
        width: '235px',
        placeholder: 'Выберите Регион',
    },
    {
        name: 'Присутствие',
        path: 'has_presence',
        type: 'checkbox',
        width: '85px',
    },
    {
        name: 'Центр',
        path: 'is_center',
        type: 'checkbox',
        width: '70px',
    },
    {
        name: 'Цвет',
        path: 'color',
        type: 'color',
        width: '70px',
    },
]
