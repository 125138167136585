import React, { memo, useState } from 'react'
import clsx from 'clsx'
// @ts-ignore
import _get from 'lodash.get'
import { AiOutlineDelete } from 'react-icons/ai'

import Cell from '../Cell'

import _ from './row.page.module.css'
import { Modal } from 'antd'
import Button from 'components/Button'

// классы для статусов
const classByShopState = {
    '1': 'serve',
    '2': 'one-time',
    '3': 'no-serve',
    '4': 'closed',
}

const fieldNameFixer = (fn: any) => {
    if (fn.type === 'select') return `${fn.path}_id`
    return fn.path
}

const Row = ({ canDelete, columns, dataListForField, updateAction, deleteAction, colorRows, ...fields }: any) => {
    const [showAcceptModal, setShowAcceptModal] = useState(false)

    return (
        <>
            <tr
                // @ts-ignore
                className={clsx(_.row, colorRows && _[classByShopState[_get(fields, 'state.id')]])}
            >
                {columns.map((fn: any) => (
                    <Cell
                        key={`building-${fn.path}-${fields.id}`}
                        type={fn.type}
                        placeholder={fn.placeholder}
                        defaultValue={_get(fields, fn.type === 'select' ? `${fn.path}.id` : `${fn.path}`)}
                        updateHandler={updateAction(fields.id)}
                        fieldName={fieldNameFixer(fn)}
                        list={dataListForField[fn.path] || []}
                        mods={fn.mods}
                    />
                ))}
                {canDelete && (
                    <td className={_.iconCell}>
                        <AiOutlineDelete title='Удалить' className={_.icon} onClick={() => setShowAcceptModal(true)} />
                    </td>
                )}
            </tr>

            <Modal
                open={showAcceptModal}
                width={400}
                title='Вы уверены что хотите удалить запись?'
                onCancel={() => setShowAcceptModal(false)}
                footer={null}
            >
                <div className={_.btnContainer}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button name='Отмена' mods={['blue']} onClick={() => setShowAcceptModal(false)} />
                        <Button
                            mods={['red']}
                            name='Удалить'
                            onClick={() => deleteAction(fields.id).then(() => setShowAcceptModal(false))}
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default memo(Row)
