import React, { memo, useEffect, useState } from 'react'
import { Modal, Select } from 'antd'
import clsx from 'clsx'

import Button from 'components/Button'
import Table from 'components/Table'

import { setOptionsFormat, showErrors, inputCheckErrors, selectCheckErrors } from 'pages/EditorPage/helpers'

import _ from '../../CreateModal.module.css'

const CreateCompanyModal = ({ onClose, onCreate, dataList, open }: any) => {
    const [errors, setErrors] = useState<string[]>([])

    const [name, setName] = useState('')
    const [shortname, setShortName] = useState('')
    const [chronology, setChronology] = useState('')
    const [state, setState] = useState<number | null>(null)

    const [options, setOptions] = useState(dataList.companyStates ? setOptionsFormat(dataList.companyStates) : [])

    useEffect(() => {
        if (dataList.companyStates?.length) setOptions(setOptionsFormat(dataList.companyStates))
    }, [JSON.stringify(dataList.companyStates)]) // eslint-disable-line

    const inputCheckField = inputCheckErrors(setErrors, errors)
    const selectCheckField = selectCheckErrors(setErrors, errors)

    return (
        <Modal onCancel={onClose} open={open} style={{ width: 400 }} title='Добавление компании' footer={null}>
            <Table style={{ width: '100%' }}>
                <tr>
                    <td>Имя*</td>
                    <td>
                        <input
                            className={clsx(_.input, errors.includes('name') && _.error)}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onBlur={() => inputCheckField(setName, name, 'name')}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Сокращение*</td>
                    <td>
                        <input
                            className={clsx(_.input, errors.includes('short_name') && _.error)}
                            value={shortname}
                            onChange={(e) => setShortName(e.target.value)}
                            onBlur={() => inputCheckField(setShortName, shortname, 'short_name')}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Хронология</td>
                    <td>
                        <textarea
                            className={_.input}
                            value={chronology}
                            onChange={(e) => setChronology(e.target.value)}
                            onBlur={() => setChronology(chronology.trim())}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Статус*</td>
                    <td>
                        <Select
                            style={{ width: '100%' }}
                            value={state}
                            placeholder='Статус'
                            options={options}
                            onChange={setState}
                            className={clsx(errors.includes('state') && _.error)}
                            onBlur={() => selectCheckField(state, 'state')}
                        />
                    </td>
                </tr>
            </Table>
            <div className={_.btnContainer}>
                <Button name='Отмена' mods={['blue']} onClick={onClose} />
                <Button
                    name='Сохранить'
                    onClick={() => {
                        if (name && shortname && state)
                            onCreate({ name, short_name: shortname, chronology, state_id: state })
                        // @ts-ignore
                        showErrors({ name, shortname, state }, setErrors, 'state')
                    }}
                />
            </div>
        </Modal>
    )
}

export default memo(CreateCompanyModal)
