import React, { memo, useEffect, useState } from 'react'
import moment from 'moment'
import DatePicker from 'react-date-picker'
import { Select, Switch } from 'antd'
import clsx from 'clsx'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { BsX } from 'react-icons/bs'

import { setOptionsFormat } from '../../helpers'
import { IOption } from 'components/Sider/sider-filters'

import _ from './cell.module.css'

const defaultVal = {
    checkbox: false,
    date: undefined,
}

const FilterCell = ({ updateHandler, type, defaultValue, fieldName, list, placeholder }: any) => {
    const [options, setOptions] = useState<IOption[]>(list ? setOptionsFormat(list) : [])
    const newVal =
        '' ||
        (type === 'input' && defaultValue) ||
        (type === 'date' && defaultValue?.date && defaultValue.date) ||
        // @ts-ignore
        defaultVal[type]
    const [val, setVal] = useState(newVal)

    const [datePos, setDatePos] = useState('>')
    const updateDatePos = () => {
        if (datePos === '>') return setDatePos('<')
        if (datePos === '<') return setDatePos('=')
        if (datePos === '=') return setDatePos('>')
    }

    useEffect(() => {
        if (list?.length) setOptions(setOptionsFormat(list))
    }, [JSON.stringify(list)]) // eslint-disable-line

    useEffect(() => {
        if (defaultValue && (!val || defaultValue !== val)) setVal(newVal)
        if (!defaultValue && (type === 'input' || type === 'text' || type === 'textarea')) setVal('')
        if (!defaultValue && type === 'date') setVal(undefined)
    }, [defaultValue]) // eslint-disable-line

    // отслеживание знака для даты
    useEffect(() => {
        if (type === 'date') {
            updateHandler({ [fieldName]: { date: val, pos: datePos } })
        }
    }, [datePos]) // eslint-disable-line

    const update = (event: any) => {
        if (type === 'date') {
            return updateHandler({ [fieldName]: { date: event, pos: datePos } })
        }

        if (type === 'checkbox') {
            updateHandler({ [fieldName]: !val })
            return setVal(!val)
        }

        if (type === 'number') {
            return updateHandler({ [fieldName]: parseInt(event.target.value, 10) })
        }

        const trimmedValue = event.target.value.trim()

        if (defaultValue !== trimmedValue) {
            updateHandler({ [fieldName]: trimmedValue })
            setVal(trimmedValue)
        }
    }

    if (type === 'input' || type === 'text' || type === 'textarea') {
        return (
            <td>
                <input
                    className={clsx(_.textarea)}
                    type='text'
                    value={val}
                    // onChange={update}
                    onChange={(e) => {
                        setVal(e.target.value)
                    }}
                    onBlur={update}
                />
            </td>
        )
    }

    if (type === 'number' || type === 'id') {
        return (
            <td>
                <input
                    className={clsx(_.textarea)}
                    type='number'
                    value={defaultValue || ''}
                    onChange={update}
                    onBlur={update}
                />
            </td>
        )
    }

    if (type === 'select') {
        return (
            <td className={clsx(_.filterCell, _.selectCell)}>
                <Select
                    className='fullWidth'
                    value={defaultValue || []}
                    placeholder={placeholder}
                    options={options}
                    onChange={(e) => {
                        updateHandler({ [fieldName]: e || null })
                    }}
                    mode='multiple'
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    allowClear
                />
            </td>
        )
    }

    if (type === 'date') {
        return (
            <td className={_.filterCell}>
                <div className='flex align-center'>
                    <div className={_.datePos} onClick={updateDatePos}>
                        {datePos === '<' && <AiOutlineLeft />}
                        {datePos === '>' && <AiOutlineRight />}
                        {datePos === '=' && '='}
                    </div>
                    <div className={_.dateWrapper}>
                        <DatePicker
                            onChange={update}
                            value={val === undefined ? undefined : moment(val).toDate()}
                            calendarIcon={null}
                            clearIcon={null}
                            format='dd.MM.y'
                        />
                    </div>
                    <div className={_.datePos} onClick={() => update(null)}>
                        <BsX />
                    </div>
                </div>
            </td>
        )
    }

    if (type === 'color') {
        return <td className={_.colorCell} />
    }

    if (type === 'checkbox') {
        return (
            <td>
                <div className={_.checkBoxCell}>
                    <Switch defaultChecked={val} onChange={update} />
                </div>
            </td>
        )
    }

    return (
        <td>
            <div className={_.textCell}>{defaultValue}</div>
        </td>
    )
}

export default memo(FilterCell)
