import { IEditorColumn } from 'types'

export const defaultSorts = [{ path: 'name', type: 'input', sort: 'asc' }]

export const columns: IEditorColumn[] = [
    {
        name: 'ID',
        path: 'id',
        type: 'id',
        width: '30px',
    },
    {
        name: 'Имя',
        path: 'name',
        type: 'input',
        width: '225px',
    },
]
