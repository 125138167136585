import { memo, useEffect, useState } from 'react'
import { Modal } from 'antd'
// @ts-ignore
import { useMutation } from '@apollo/client'
// @ts-ignore
import _isEqual from 'lodash.isequal'

import { TICKET_CREATE, TICKET_QUERY } from 'connectors/gql/ticket.gql'
import { Files } from 'connectors/query/Files'

import Loader from 'components/Loader'
import TicketMainData from './components/TicketMainData'
import TicketAttachData from './components/TicketAttachData'
import { openErrorNotification, openSuccessNotification } from 'components/Notifications'

const defaultTicket = {
    topic: '',
    external_code: '',
    externalDate: new Date().getTime(),
    shop: null,
    details: '',
    priority: '1', // приоритет - нормальный
    branch: '3', // отрасль - тех. эксплуатация
}

const CreateTicketModal = ({ closeModal, setCreateModalIsEmpty, open }: any) => {
    const [ticket, setTicket] = useState(defaultTicket)
    const [addedFile, setAddedFile] = useState<any>(null)
    const [filesUploading, setFilesUploading] = useState(false)

    useEffect(() => {
        setCreateModalIsEmpty(_isEqual(defaultTicket, ticket))
    }, [JSON.stringify(ticket)]) // eslint-disable-line

    useEffect(() => {
        setTicket(defaultTicket)
    }, [open])

    const [createMutator, { loading }] = useMutation(TICKET_CREATE, {
        refetchQueries: [TICKET_QUERY, 'ticketsPageQuery'],
        onError: () => openErrorNotification('Ошибка создания!'),
        onCompleted: () => openSuccessNotification('Успешно создано!'),
    })

    const uploadFile = (ticketId: string) => {
        const formData = new FormData()
        if (!addedFile) return null

        formData.append(`file`, addedFile, encodeURI(addedFile.name))
        setFilesUploading(true)

        Files.postFile(ticketId, formData).then(() => {
            setAddedFile(null)
            openSuccessNotification('Файлы прикреплены')
            setFilesUploading(false)
        })
    }

    const onCreate = () => {
        createMutator({
            variables: {
                ticket: {
                    topic: ticket.topic || '',
                    details: ticket.details || '',
                    // @ts-ignore
                    shop_id: parseInt(ticket.shop, 10),
                    priority_id: parseInt(ticket.priority, 10),
                    branch_id: parseInt(ticket.branch, 10),
                    external_code: ticket.external_code || '',
                    external_date: ticket.externalDate || null,
                },
            },
        }).then(({ data }: any) => {
            if (data?.ticketCreate?.id) uploadFile(data.ticketCreate.id)
            setTicket(defaultTicket)
        })
    }

    return (
        <Modal width={880} title='Создание заявки' open={open} onCancel={closeModal} footer={null}>
            <div>
                <TicketMainData ticket={ticket} setTicket={setTicket} createTicket={onCreate} />
                <TicketAttachData addedFile={addedFile} setAddedFile={setAddedFile} />

                {(loading || filesUploading) && <Loader />}
            </div>
        </Modal>
    )
}

export default memo(CreateTicketModal)
