import XLSX from 'xlsx'
import _get from 'lodash.get'
import moment from 'moment'
import { openErrorNotification } from 'components/Notifications';

export const setOptionsFormat = (arr) => arr.map(cs => ({ value: cs.id, label: cs.name }))

export const showErrors = (fields, setErrors, selectsFields = []) => {
    const newErrors = []
    Object.keys(fields).forEach((f) => {
        if (selectsFields.includes(f)) {
            if (!fields[f] || !fields[f].value) newErrors.push(f)
        } else {
            if (!fields[f]) newErrors.push(f)
        }
    })

    if (newErrors.length) {
        openErrorNotification('Не все обязательные поля заполнены')
        setErrors(newErrors)
    }
}

export const inputCheckErrors = (setErrors, errors) => (setName, field, fieldName, trim = true) => {
    if (trim) setName(field.trim())
    if (field) return setErrors(errors.filter(e => e !== fieldName))
    return setErrors([...errors, fieldName])
}

export const selectCheckErrors = (setErrors, errors) => (field, fieldName) => {
    if (field && field.value) return setErrors(errors.filter(e => e !== fieldName))
    return setErrors([...errors, fieldName])
}

export const simpleValueSelectCheckErrors = (setErrors, errors) => (field, fieldName) => {
    if (!!field) return setErrors(errors.filter(e => e !== fieldName))
    return setErrors([...errors, fieldName])
}

export const sendXLSX = (data, columns, entityName) => {
    const selects = columns.filter(c => c.type === 'select').map(c => c.path)
    const dates = columns.filter(c => c.type === 'date').map(c => c.path)
    const longPaths = columns.filter(c => c.path.includes('.')).map(c => c.path)
    const selectedColumns = columns.map(c => c.path)
    const selectedTranslateColumns = columns.map(c => c.name)

    const list = data.map(({ __typename, ...fields }) => {
        const updatedFields = {}
        const _fields = {}

        Object.keys(fields).forEach(key => {
            if (selectedColumns.includes(key)) {
                _fields[key] = fields[key]
            }

            if (selects.includes(key)) {
                updatedFields[key] = fields[key].name
            }

            if (dates.includes(key)) {
                updatedFields[key] = fields[key] ? moment(parseInt(fields[key], 10)).format('DD.MM.YYYY') : ''
            }

            longPaths.forEach(lp => {
                _fields[lp] = _get(fields, lp)
            })
        })

        return ({ ..._fields, ...updatedFields })
    })

    const translatedColumnsHeaders = list.map(l => {
        const translatedObj = {}
        Object.keys(l).forEach(key => {
            const translatedField = columns.find(c => c.path === key).name
            translatedObj[translatedField] = l[key]
        })
        return translatedObj
    })

    const filename = `${entityName}.xlsx`
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(translatedColumnsHeaders, { header: selectedTranslateColumns })

    XLSX.utils.book_append_sheet(wb, ws, "Лист 1")
    XLSX.writeFile(wb, filename)
}
