import { gql } from "@apollo/client";

export const CITIES_QUERY = gql`
  query citiesQuery($full: Boolean) {
    cities(full: $full) {
      id
      name
      abbreviation
      region {
        id
        name
      }
      is_center
      has_presence
      buildings {
        id
        name
      }
      color
      count_tickets
    }
  }
`;

export const REGIONS_QUERY = gql`
  query regionsQuery {
    regions {
      id
      name
    }
  }
`;



export const CITY_UPDATE = gql`
  mutation CityUpdate($city: CityUpdate!) {
    cityUpdate(city: $city) {
      name
      abbreviation
      region {
        id
        name
      }
      is_center
      has_presence
      buildings {
        id
      }
      color
      count_tickets
    }
  }
`;


export const CITY_CREATE = gql`
  mutation CityCreate($city: CityCreate!) {
    cityCreate(city: $city) {
      name
      abbreviation
      region {
        id
      }
      is_center
      has_presence
      buildings {
        id
      }
      color
      count_tickets
    }
  }
`;

export const CITY_DELETE = gql`
  mutation CityDelete($id: Int!) {
    cityDelete(id: $id) {
      id
    }
  }
`;
