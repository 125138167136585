import React, { memo, useEffect, useState } from 'react'
import DatePicker from 'react-date-picker'
import clsx from 'clsx'

import Button from 'components/Button'
import Table from 'components/Table'

import { setOptionsFormat, showErrors, inputCheckErrors, selectCheckErrors } from 'pages/EditorPage/helpers'

import _ from '../../CreateModal.module.css'
import { Modal, Select } from 'antd'

const CreateBrandModal = ({ onClose, onCreate, dataList, open }: any) => {
    const [errors, setErrors] = useState<string[]>([])

    const [code, setСode] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [phone2, setPhone2] = useState('')
    const [area, setArea] = useState(0)

    const [opened, setOpened] = useState<Date | null>(null)
    const [serve_start, setServeStart] = useState<Date | null>(null)
    const [serve_end, setServeEnd] = useState<Date | null>(null)
    const [closed, setClosed] = useState<Date | null>(null)

    const [director, setDirector] = useState('')
    const [remark, setRemark] = useState('')
    const [chronology, setСhronology] = useState('')

    const [brand, setBrand] = useState(null)
    const [building, setBuilding] = useState(null)
    const [shopState, setShopState] = useState(null)

    const [brandsOptions, setBrandsOptions] = useState(dataList.brands ? setOptionsFormat(dataList.brands) : [])
    const [buildingsOptions, setBuildingsOptions] = useState(
        dataList.buildings ? setOptionsFormat(dataList.buildings) : [],
    )
    const [shopStatesOptions, setShopStatesOptions] = useState(
        dataList.shopStates ? setOptionsFormat(dataList.shopStates) : [],
    )

    useEffect(() => {
        if (dataList.brands?.length) setBrandsOptions(setOptionsFormat(dataList.brands))
    }, [JSON.stringify(dataList.brands)]) // eslint-disable-line
    useEffect(() => {
        if (dataList.buildings?.length) setBuildingsOptions(setOptionsFormat(dataList.buildings))
    }, [JSON.stringify(dataList.buildings)]) // eslint-disable-line
    useEffect(() => {
        if (dataList.shopStates?.length) setShopStatesOptions(setOptionsFormat(dataList.shopStates))
    }, [JSON.stringify(dataList.shopStates)]) // eslint-disable-line

    const inputCheckField = inputCheckErrors(setErrors, errors)
    const selectCheckField = selectCheckErrors(setErrors, errors)

    return (
        <Modal title='Добавление магазина' onCancel={onClose} open={open} style={{ width: 400 }} footer={null}>
            <Table style={{ width: '100%' }}>
                <tr>
                    <td>Код*</td>
                    <td>
                        <input
                            className={clsx(_.input, errors.includes('code') && _.error)}
                            value={code}
                            onChange={(e) => setСode(e.target.value)}
                            onBlur={() => inputCheckField(setСode, code, 'code')}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Email*</td>
                    <td>
                        <input
                            className={clsx(_.input, errors.includes('email') && _.error)}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={() => inputCheckField(setEmail, email, 'email')}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Телефон*</td>
                    <td>
                        <input
                            className={clsx(_.input, errors.includes('phone') && _.error)}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            onBlur={() => inputCheckField(setPhone, phone, 'phone')}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Телефон2</td>
                    <td>
                        <input className={_.input} value={phone2} onChange={(e) => setPhone2(e.target.value)} />
                    </td>
                </tr>
                <tr>
                    <td>Площадь*</td>
                    <td>
                        <input
                            type='number'
                            className={clsx(_.input, errors.includes('area') && _.error)}
                            value={area}
                            onChange={(e: any) => setArea(e.target.value)}
                            onBlur={() => inputCheckField(setArea, area, 'area', false)}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Открыт</td>
                    <td className={_.date}>
                        <DatePicker
                            onChange={setOpened}
                            value={opened}
                            calendarIcon={null}
                            clearIcon={null}
                            format='dd.MM.y'
                        />
                    </td>
                </tr>
                <tr>
                    <td>Начало обслуживания</td>
                    <td className={_.date}>
                        <DatePicker
                            onChange={setServeStart}
                            value={serve_start}
                            calendarIcon={null}
                            clearIcon={null}
                            format='dd.MM.y'
                        />
                    </td>
                </tr>
                <tr>
                    <td>Конец обслуживания</td>
                    <td className={_.date}>
                        <DatePicker
                            onChange={setServeEnd}
                            value={serve_end}
                            calendarIcon={null}
                            clearIcon={null}
                            format='dd.MM.y'
                        />
                    </td>
                </tr>
                <tr>
                    <td>Закрыт</td>
                    <td className={_.date}>
                        <DatePicker
                            onChange={setClosed}
                            value={closed}
                            calendarIcon={null}
                            clearIcon={null}
                            format='dd.MM.y'
                        />
                    </td>
                </tr>
                <tr>
                    <td>Бренд*</td>
                    <td>
                        <Select
                            style={{ width: '100%' }}
                            value={brand}
                            placeholder='Выберите бренд'
                            options={brandsOptions}
                            onChange={setBrand}
                            className={clsx(errors.includes('brand') && _.error)}
                            onBlur={() => selectCheckField(brand, 'brand')}
                        />
                    </td>
                </tr>
                <tr>
                    <td>ТЦ*</td>
                    <td>
                        <Select
                            style={{ width: '100%' }}
                            value={building}
                            placeholder='Выберите ТЦ'
                            options={buildingsOptions}
                            onChange={setBuilding}
                            className={clsx(errors.includes('building') && _.error)}
                            onBlur={() => selectCheckField(building, 'building')}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Статус*</td>
                    <td>
                        <Select
                            style={{ width: '100%' }}
                            value={shopState}
                            placeholder='Выберите статус'
                            options={shopStatesOptions}
                            onChange={setShopState}
                            className={clsx(errors.includes('shopState') && _.error)}
                            onBlur={() => selectCheckField(shopState, 'shopState')}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Директор</td>
                    <td>
                        <input className={_.input} value={director} onChange={(e) => setDirector(e.target.value)} />
                    </td>
                </tr>
                <tr>
                    <td>Заметка</td>
                    <td>
                        <input className={_.input} value={remark} onChange={(e) => setRemark(e.target.value)} />
                    </td>
                </tr>
                <tr>
                    <td>Хронология</td>
                    <td>
                        <textarea
                            className={_.input}
                            value={chronology}
                            onChange={(e) => setСhronology(e.target.value)}
                        />
                    </td>
                </tr>
            </Table>
            <div className={_.btnContainer}>
                <Button name='Отмена' mods={['blue']} onClick={onClose} />
                <Button
                    name='Сохранить'
                    onClick={() => {
                        if (code && email && phone && area && building && brand && shopState)
                            onCreate({
                                code,
                                email,
                                phone,
                                phone2,
                                area: +area,
                                opened,
                                serve_start,
                                serve_end,
                                closed,
                                director,
                                remark,
                                chronology,
                                brand_id: brand,
                                building_id: building,
                                state_id: shopState,
                            })
                        showErrors({ code, email, phone, area, building, brand, shopState }, setErrors, [
                            'building',
                            'brand',
                            'shopState',
                        ])
                    }}
                />
            </div>
        </Modal>
    )
}

export default memo(CreateBrandModal)
