import React, { memo, useState } from 'react'
import clsx from 'clsx'
import { FaStoreAlt, FaWarehouse, FaWrench, FaPlus, FaMinus } from 'react-icons/fa'
import { Modal } from 'antd'

import { TICKET_EQUPMENT_CREATE, TICKET_QUERY } from 'connectors/gql/ticket.gql'
import { useCreate } from 'helpers/hooks/editor'

import Button from 'components/Button'
import Loader from 'components/Loader'
import UnitsSelect from 'components/EntitySelects/UnitsSelect'
import WaresSelect from 'components/EntitySelects/WaresSelect'

import _ from './styles.module.css'

const Row = ({ label, children }: any) => {
    return (
        <div className='flex align-center'>
            <div className={_.label}>{label}</div>
            {children}
        </div>
    )
}

const CreateEqupmentModal = ({ ticketId, show, onClose }: any) => {
    const [errors, setErrors] = useState<any>([])

    const [unit, setUnit] = useState<any>(null)
    const [ware, setWare] = useState<any>(null)
    const [supplied_by_contractor, setSuppliedByContractor] = useState<any>(0) // Поставлено сервисом
    const [supplied_by_shop, setSuppliedByShop] = useState<any>(0) // Поставлено клиентом
    const [stored_in_shop, setStoredInShop] = useState<any>(0) // Оставлено на складе клиента(не использованно)

    const clearState = () => {
        setErrors([])
        setUnit(null)
        setWare(null)
        setSuppliedByContractor(0)
        setSuppliedByShop(0)
        setStoredInShop(0)
    }
    const closeAction = () => {
        clearState()
        onClose()
    }

    const { onCreate, loading: saveEquipmentLoading } = useCreate(
        TICKET_EQUPMENT_CREATE,
        TICKET_QUERY,
        'ticket',
        'equipment',
        closeAction,
    )

    const createAction = () => {
        const _errors: any = []
        if (!ware || !ware.value) _errors.push('ware')
        if (!unit || !unit.value) _errors.push('unit')

        if (_errors.length) return setErrors(_errors)
        onCreate({
            ticket_id: parseInt(ticketId, 10),
            supplied_by_contractor: parseInt(supplied_by_contractor, 10),
            supplied_by_shop: parseInt(supplied_by_shop, 10),
            stored_in_shop: parseInt(stored_in_shop, 10),
            ware_id: parseInt(ware.value, 10),
            unit_id: parseInt(unit.value, 10),
        })
    }

    const setWareHandler = ({ default_unit, ...e }: any) => {
        setWare(e)
        default_unit && setUnit({ label: default_unit.name, value: default_unit.id })
    }

    if (!show) return null

    return (
        <Modal title='Добавление оборудования в заявку' open={show} onCancel={closeAction} footer={null}>
            <div className={_.createEqupmentModalWrapper}>
                <Row label='Оборудование'>
                    <WaresSelect
                        className={clsx(_.selectWidth, errors.includes('ware') && _.error)}
                        value={ware}
                        onChange={setWareHandler}
                    />
                </Row>
                <Row label='Единица измерения'>
                    <UnitsSelect
                        className={clsx(_.selectWidth, errors.includes('unit') && _.error)}
                        value={unit}
                        onChange={setUnit}
                    />
                </Row>
                <Row label='Поставлено сервисом'>
                    <FaWrench className={_.icon} />
                    <input
                        className={clsx(_.input, _.border, _.inputHeight)}
                        type='text'
                        value={supplied_by_contractor}
                        onChange={(e) => setSuppliedByContractor(e.target.value)}
                    />
                    <div>
                        <FaPlus
                            className={_.addButton}
                            onClick={() => setSuppliedByContractor(supplied_by_contractor + 1)}
                        />
                        <FaMinus
                            className={_.decButton}
                            onClick={() =>
                                supplied_by_contractor > 0 && setSuppliedByContractor(supplied_by_contractor - 1)
                            }
                        />
                    </div>
                </Row>
                <Row label='Поставлено клиентом'>
                    <FaStoreAlt className={_.icon} />
                    <input
                        className={clsx(_.input, _.border, _.inputHeight)}
                        type='text'
                        value={supplied_by_shop}
                        onChange={(e) => setSuppliedByShop(e.target.value)}
                    />
                    <div>
                        <FaPlus className={_.addButton} onClick={() => setSuppliedByShop(supplied_by_shop + 1)} />
                        <FaMinus
                            className={_.decButton}
                            onClick={() => supplied_by_shop > 0 && setSuppliedByShop(supplied_by_shop - 1)}
                        />
                    </div>
                </Row>
                <Row label='Оставлено на складе клиента(не использованно)'>
                    <FaWarehouse className={_.icon} />
                    <input
                        className={clsx(_.input, _.border, _.inputHeight)}
                        type='text'
                        value={stored_in_shop}
                        onChange={(e) => setStoredInShop(e.target.value)}
                    />
                    <div>
                        <FaPlus className={_.addButton} onClick={() => setStoredInShop(stored_in_shop + 1)} />
                        <FaMinus
                            className={_.decButton}
                            onClick={() => stored_in_shop > 0 && setStoredInShop(stored_in_shop - 1)}
                        />
                    </div>
                </Row>

                <div className={_.buttonWrapper}>
                    <Button name='Добавить' onClick={createAction} />
                </div>
            </div>
            {saveEquipmentLoading && <Loader />}
        </Modal>
    )
}

export default memo(CreateEqupmentModal)
