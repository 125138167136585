import { useState, useEffect } from 'react'
import { openErrorNotification, openSuccessNotification } from 'components/Notifications'
import { useQuery, useMutation, DocumentNode, OperationVariables, ApolloQueryResult } from '@apollo/client'
// @ts-ignore
import _get from 'lodash.get'
import moment from 'moment'

export function useEntity<T>(
    query: DocumentNode,
    fieldName: string,
    variables?: any,
): [T[], boolean, (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>] {
    const { data, loading, refetch } = useQuery(query, variables && { variables })
    const [array, setArray] = useState<T[]>((data && data[fieldName]) || [])

    useEffect(() => {
        if (data && data[fieldName]) setArray(data[fieldName])
    }, [JSON.stringify(data)])

    return [array, loading, refetch]
}

// @ts-ignore
export const useSortAndFilter = (filters, entity, columns, entityName, defaultSorts) => {
    const [filterFields, setFilterFields] = useState(filters)
    const [sorts, setSorts] = useState(defaultSorts || [])

    useEffect(() => {
        const filterAndSorts = { filterFields, sorts }
        localStorage.setItem(entityName, JSON.stringify(filterAndSorts))
    }, [JSON.stringify(filterFields), JSON.stringify(sorts)]) // eslint-disable-line

    useEffect(() => {
        ;(async function init() {
            const _filterAndSorts = localStorage.getItem(entityName)
            if (_filterAndSorts) {
                const filterAndSorts = JSON.parse(_filterAndSorts)

                if (filterAndSorts?.sorts?.length) setSorts(filterAndSorts.sorts)
                if (filterAndSorts?.filterFields) setFilterFields(filterAndSorts.filterFields)
            }
        })()
    }, []) // eslint-disable-line

    const resetSort = (force = false) => (force ? setSorts([]) : setSorts(defaultSorts || []))
    const resetFilters = () => setFilterFields(filters)

    // фильтрация столбцов фронтом по данным введённым в "фильтры фраз"
    const [filteredEntity, setFilteredEntity] = useState(entity)
    useEffect(() => {
        // @ts-ignore
        const newEntity = entity.filter((b) => {
            let resp = true
            Object.keys(filterFields).forEach((field) => {
                if (resp) {
                    // @ts-ignore
                    const columnData = columns.find((c) => c.path === field)

                    if (columnData && columnData.type === 'date') {
                        if (!filterFields[field]?.date) return
                        if (filterFields[field]?.date && !b[field]) return (resp = false)
                        if (filterFields[field]?.pos === '>')
                            return (resp = moment(new Date(parseInt(b[field]))).isAfter(
                                moment(filterFields[field].date),
                            ))
                        if (filterFields[field]?.pos === '<')
                            return (resp = moment(new Date(parseInt(b[field]))).isBefore(
                                moment(filterFields[field].date),
                            ))
                        if (filterFields[field]?.pos === '=')
                            return (resp = moment(new Date(parseInt(b[field]))).isSame(
                                moment(filterFields[field].date),
                                'day',
                            ))
                    }

                    if (columnData && columnData.type === 'select') {
                        return filterFields[field]?.length ? (resp = filterFields[field].includes(b[field].id)) : null
                    }

                    if (filterFields[field]) {
                        resp = `${_get(b, field)}`.toLowerCase().includes(`${filterFields[field]}`.toLowerCase())
                    }
                }
            })
            return resp
        })
        setFilteredEntity(newEntity)
    }, [JSON.stringify(filterFields), JSON.stringify(entity)]) // eslint-disable-line

    const [sortedEntity, setSortedEntity] = useState(filteredEntity)
    useEffect(() => {
        if (sorts?.length) {
            const _forSort = [...filteredEntity]
            sorts.forEach((sort: any) => {
                _forSort.sort((a, b) => {
                    if (sort.type === 'text' || sort.type === 'input' || sort.type === 'textarea') {
                        return sort.sort === 'asc'
                            ? (a[sort.path] || '').localeCompare(b[sort.path] || '')
                            : (b[sort.path] || '').localeCompare(a[sort.path] || '')
                    }
                    if (sort.type === 'select') {
                        return sort.sort === 'asc'
                            ? a[sort.path].name.localeCompare(b[sort.path].name)
                            : b[sort.path].name.localeCompare(a[sort.path].name)
                    }
                    if (sort.type === 'id' || sort.type === 'number') {
                        return sort.sort === 'asc' ? a[sort.path] - b[sort.path] : b[sort.path] - a[sort.path]
                    }
                    return -a[sort.path] + b[sort.path]
                })
            })

            setSortedEntity(_forSort)
        } else {
            setSortedEntity(filteredEntity)
        }
    }, [JSON.stringify(filteredEntity), JSON.stringify(sorts)]) // eslint-disable-line

    const headerClick = (column: any) => {
        // @ts-ignore
        const alreadySorted = sorts.find((s) => s.path === column.path)
        let otherSorts = [...sorts]
        // @ts-ignore
        if (alreadySorted) otherSorts = sorts.filter((s) => s.path !== column.path)

        if (alreadySorted?.sort === 'asc') return setSorts([...otherSorts, { ...alreadySorted, sort: 'desc' }])
        if (alreadySorted?.sort === 'desc') return setSorts(otherSorts)
        if (!alreadySorted) return setSorts([...sorts, { ...column, sort: 'asc' }])
    }

    const headerSortsArrows = {}
    // @ts-ignore
    sorts.forEach((s) => {
        // @ts-ignore
        headerSortsArrows[s.path] = s.sort
    })
    return {
        headerSortsArrows,
        filterFields,
        setFilterFields,
        sortedEntity,
        headerClick,
        resetSort,
        resetFilters,
    }
}

// @ts-ignore
export const useSortAndFilterForBack = (filters, entityName, defaultSorts, selectedColumns) => {
    // @ts-ignore
    const selectedColumnsPaths = selectedColumns.map((sc) => sc.path)
    // @ts-ignore
    const preparedSorts = defaultSorts.filter((ds) => selectedColumnsPaths.includes(ds.path))

    const [filterFields, setFilterFields] = useState(filters)
    const [sorts, setSorts] = useState(preparedSorts || [])

    useEffect(() => {
        ;(async function init() {
            const _filterAndSorts = localStorage.getItem(entityName)
            if (_filterAndSorts) {
                const filterAndSorts = JSON.parse(_filterAndSorts)

                if (filterAndSorts?.sorts?.length)
                    // @ts-ignore
                    setSorts(filterAndSorts.sorts.filter((s) => selectedColumnsPaths.includes(s.path)))
                if (filterAndSorts?.filterFields) setFilterFields(filterAndSorts.filterFields)
            }
        })()
    }, []) // eslint-disable-line

    useEffect(() => {
        const filterAndSorts = { filterFields, sorts }
        localStorage.setItem(entityName, JSON.stringify(filterAndSorts))
    }, [JSON.stringify(filterFields), JSON.stringify(sorts)]) // eslint-disable-line

    const resetSort = (force = false) => (force ? setSorts([]) : setSorts(preparedSorts || []))
    const resetFilters = () => setFilterFields(filters)

    // @ts-ignore
    const headerClick = (column) => {
        // @ts-ignore
        const alreadySorted = sorts.find((s) => s.path === column.path)
        let otherSorts = [...sorts]
        // @ts-ignore
        if (alreadySorted) otherSorts = sorts.filter((s) => s.path !== column.path)

        if (alreadySorted?.sort === 'asc') return setSorts([...otherSorts, { ...alreadySorted, sort: 'desc' }])
        if (alreadySorted?.sort === 'desc') return setSorts(otherSorts)
        if (!alreadySorted) return setSorts([...sorts, { ...column, sort: 'asc' }])
    }

    const headerSortsArrows = {}
    // @ts-ignore
    sorts.forEach((s) => {
        // @ts-ignore
        headerSortsArrows[s.path] = s.sort
    })
    return {
        sorts,
        headerSortsArrows,
        filterFields,
        setFilterFields,
        headerClick,
        resetSort,
        resetFilters,
    }
}

export const useCreate = (
    CREATE: DocumentNode,
    QUERY: DocumentNode,
    queryName: string,
    entitySave: string,
    closeModal?: (close: boolean) => void,
) => {
    const [createMutator, { loading }] = useMutation(CREATE, {
        refetchQueries: [QUERY, queryName],
        onError: () => openErrorNotification('Ошибка создания!'),
        onCompleted: () => openSuccessNotification('Успешно создано!'),
    })

    const onCreate = (data: any) => {
        closeModal && closeModal(false)
        return createMutator({ variables: { [entitySave]: data } })
    }

    return { loading, onCreate }
}

export const useUpdate = (UPDATE: DocumentNode, entity: string, QUERY?: DocumentNode, queryName?: string) => {
    const [updateEntityMutator] = useMutation(
        UPDATE,
        (QUERY &&
            queryName && {
                refetchQueries: [QUERY, queryName],
                onError: () => openErrorNotification('Ошибка сохранения!'),
                onCompleted: () => openSuccessNotification('Успешно сохранено!'),
            }) ||
            {},
    )
    const updateEntity = (id: number) => (value: any) =>
        updateEntityMutator({ variables: { [entity]: { id, ...value } } })

    return updateEntity
}

export const useDelete = (DELETE: DocumentNode, QUERY: DocumentNode, queryName: string, queryDelete: string) => {
    const [deleteEntityMutator] = useMutation(DELETE, {
        refetchQueries: [QUERY, queryName],
        onError: () => openErrorNotification('Ошибка удаления!'),
        onCompleted: (data) => {
            if (data[queryDelete] === false) {
                return openErrorNotification('Ошибка удаления!')
            }
            openSuccessNotification('Успешно удалено!')
        },
    })
    const deleteEntity = (id: number) => deleteEntityMutator({ variables: { id } })

    return deleteEntity
}
