import { useEffect } from 'react'

export const useCheckScroll = (ref: any, setData: any, triggers: any = []) => {
    useEffect(() => {
        if (ref && ref.current) {
            setData(ref.current.scrollHeight > ref.current.clientHeight)
        }

        // из-за анимации изменения высоты - мгновенный расчёт иногда неврен
        let timerRefresh = setTimeout(() => {
            if (ref && ref.current) {
                setData(ref.current.scrollHeight > ref.current.clientHeight)
            }
        }, 100)

        return () => {
            clearTimeout(timerRefresh)
        }
        // eslint-disable-next-line
    }, [...triggers])
}
