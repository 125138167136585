import { IEditorColumn } from 'types'

export const defaultSorts = []

export const columns: IEditorColumn[] = [
    {
        name: 'ID',
        path: 'id',
        type: 'id',
        width: '30px',
    },
    {
        name: 'Заголовок',
        path: 'title',
        type: 'input',
        width: '120px',
    },
    {
        name: 'Имя',
        path: 'name',
        type: 'textarea',
        width: '320px',
    },
    {
        name: 'Дата',
        path: 'date',
        type: 'date',
        width: '80px',
    },
    {
        name: 'Окончание',
        path: 'finish',
        type: 'date',
        width: '80px',
    },
    {
        name: 'Ограничение',
        path: 'company_restrictions',
        type: 'number',
        width: '120px',
    },
    {
        name: 'Статус',
        path: 'state',
        type: 'select',
        width: '140px',
        placeholder: 'Выберите статус',
    },
    {
        name: 'Тип',
        path: 'type',
        type: 'select',
        width: '120px',
        placeholder: 'Выберите тип',
    },
    {
        name: 'Замечание',
        path: 'remark',
        type: 'input',
        width: '225px',
    },
    {
        name: 'Описание',
        path: 'description',
        type: 'textarea',
        width: '100%',
        minWidth: '250px',
    },
]
