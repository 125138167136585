import React, { memo, useState } from 'react'
import clsx from 'clsx'

import _ from './styles.module.css'

const Tab = ({ name, spaceAtBottom = false, children, headerAction, isPins, color, level }: any) => {
    const [collapsed, setCollapsed] = useState(false)

    return (
        <div
            className={clsx(_.tab, spaceAtBottom && _.spaceAtBottom)}
            style={{
                background: isPins ? 'rgba(255, 99, 71, 0.15)' : color,
            }}
        >
            <div
                className={clsx(_.header, isPins && _.pins, _[`level-${level}`])}
                style={{ background: isPins ? '#ffd4cd' : '' }}
                onClick={() => (headerAction ? headerAction() : setCollapsed(!collapsed))}
            >
                {!isPins ? (
                    <div>
                        {collapsed ? '˃ ' : '˅ '}
                        {name}
                    </div>
                ) : (
                    <div>{name}</div>
                )}
            </div>
            <div className={clsx(_.body, collapsed && _.collapsed, isPins && _.pinsHeaderPlace)}>{children}</div>
        </div>
    )
}

export default memo(Tab)
