import { gql } from "@apollo/client";

export const ESTIMATES_GROUP_STATES_QUERY = gql`
  query estimateGroupStatesQuery {
    estimateGroupStates {
      id
      name
    }
  }
`;
