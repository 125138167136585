import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'

import Button from 'components/Button'

import _ from './styles.module.css'
import Header from 'components/Header'

const CabinetPage = () => {
    const { keycloak }: any = useKeycloak()
    const { logout } = keycloak

    const family_name = keycloak?.tokenParsed?.family_name || ''
    const given_name = keycloak?.tokenParsed?.given_name || ''
    const email = keycloak?.tokenParsed?.email || ''

    const tableRows = [
        { name: 'Имя', content: given_name },
        { name: 'Фамилия', content: family_name },
        { name: 'E-mail', content: email },
    ]

    const changePassHandler = () => {
        window.location.href = 'https://id.city-gals.com/'
    }

    return (
        <>
            <Header />

            <div className={_.wrapper}>
                <div className={_.title}>Личный кабинет</div>

                <table className={_.table}>
                    <tbody>
                        {tableRows.map(({ name, content }) => (
                            <tr>
                                <td className={_.cell}>{name}</td>
                                <td className={_.cell}>{content}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className={_.btnConatiner}>
                    <Link to={'/editor'} className={_.link}>
                        Редактор
                    </Link>
                </div>
                <div className={_.btnConatiner}>
                    <Button name='Сменить пароль' onClick={changePassHandler} mods={['big']} />
                </div>
                <div className={_.btnConatiner}>
                    <Button name='Выход' onClick={logout} mods={['big', 'red']} />
                </div>
            </div>
        </>
    )
}

export default memo(CabinetPage)
