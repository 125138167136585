import { FaStoreAlt, FaWarehouse, FaWrench } from 'react-icons/fa'


export const tableColumns = [
    {
        name: 'Наименование',
        path: 'ware',
        type: 'select',
    },
    {
        name: 'Ед. изм.',
        path: 'unit',
        width: '150px',
        type: 'select',
    },
    {
        name: <FaWrench />,
        title: 'Поставлено сервисом',
        path: 'supplied_by_contractor',
        type: 'number',
        width: '50px',
        textAlign: 'center',
    },
    {
        name: <FaStoreAlt />,
        title: 'Поставлено клиентом',
        path: 'supplied_by_shop',
        type: 'number',
        width: '50px',
        textAlign: 'center',
    },
    {
        name: <FaWarehouse />,
        title: 'Оставлено на складе клиента(не использованно)',
        path: 'stored_in_shop',
        type: 'number',
        width: '50px',
        textAlign: 'center',
    },
]
