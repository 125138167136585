import { memo, useState } from 'react'

import Button from 'components/Button'

import _ from './shown-columns.module.css'

const ShownColumns = ({ columns, checkColumn, columnsList }: any) => {
    const [showColumn, setShowColumns] = useState(false)

    return (
        <>
            <div className={_.btnContaner}>
                <Button
                    name={showColumn ? 'Скрыть колонки' : 'Показать колонки'}
                    onClick={() => setShowColumns(!showColumn)}
                    mods={['blue']}
                />
            </div>
            <div className={_.wrapper}>
                {showColumn &&
                    columns.map((c: any) => (
                        <div key={c.name} className={_.cell} onClick={checkColumn(c.path)}>
                            <div>{c.name}</div>
                            <input
                                type='checkbox'
                                checked={columnsList.includes(c.path)}
                                onChange={checkColumn(c.path)}
                            />
                        </div>
                    ))}
            </div>
        </>
    )
}

export default memo(ShownColumns)
