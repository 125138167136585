import { memo } from 'react'

import DynamicFieldSelector from '../DynamicFieldSelector'
import _ from './styles.module.css'

const TicketsHeader = ({
    selectedDynamicField1,
    selectedDynamicField2,
    selectDynamicField1,
    selectDynamicField2,
}: any) => {
    return (
        <>
            <div className={_.container}>
                <div className={_.cellsContainer}>
                    <div className={_.infoWidth}>Заявка</div>
                    <div className='details_width'>Описание заявки</div>
                    <div className='complete_jobs_width'>Описание выполнения</div>
                    <div className='technical_remark_width'>Техническая заметка</div>
                    <div className='technical_spend_width'>Технический расход</div>
                    <div className='technical_event_archive_width'>
                        <DynamicFieldSelector value={selectedDynamicField1} handler={selectDynamicField1} />
                    </div>
                    <div className='technical_chat_width'>
                        <DynamicFieldSelector value={selectedDynamicField2} handler={selectDynamicField2} />
                    </div>
                </div>
            </div>
            <div className={_.spacer} />
        </>
    )
}

export default memo(TicketsHeader)
