import React, { memo, useEffect, useState } from 'react'
import clsx from 'clsx'
import { Select } from 'antd'

import { setOptionsFormat } from 'helpers'

import _ from './styles.module.css'

const selectStyles = {
    control: (styles: any) => ({ ...styles, minHeight: 24, height: 24, border: 'none', cursor: 'pointer' }),
    valueContainer: (styles: any) => ({ ...styles, minHeight: 24, height: 24 }),
    indicatorsContainer: (styles: any) => ({ ...styles, opacity: 0, height: 0 }),
    indicatorSeparator: (styles: any) => ({ ...styles, opacity: 0 }),
    multiValue: (styles: any) => ({ ...styles, height: 12 }),
    multiValueLabel: (styles: any) => ({ ...styles, padding: 0 }),
    input: (styles: any) => ({ ...styles, margin: 0 }),
    menu: (styles: any) => ({ ...styles, marginTop: 1 }),
}

const Cell = ({ updateHandler, type, defaultValue, fieldName, list, placeholder }: any) => {
    const [val, setVal] = useState(defaultValue)
    const [options, setOptions] = useState(list ? setOptionsFormat(list) : [])

    useEffect(() => {
        if (list?.length) setOptions(setOptionsFormat(list))
    }, [JSON.stringify(list)]) // eslint-disable-line

    useEffect(() => {
        if (defaultValue && !val) setVal(defaultValue)
    }, [defaultValue]) // eslint-disable-line

    const update = () => {
        if (type === 'number' && defaultValue !== val) {
            return updateHandler({ [fieldName]: parseFloat(val) })
        }
    }

    if (type === 'number') {
        return (
            <td>
                <input
                    className={clsx(_.textarea)}
                    type='number'
                    value={val}
                    onChange={(e) => setVal(e.target.value)}
                    onBlur={update}
                />
            </td>
        )
    }

    if (type === 'select') {
        return (
            <td className={_.selectCell}>
                <Select
                    value={options.find((o: any) => o.value === defaultValue)}
                    placeholder={placeholder}
                    options={options}
                    onChange={(value) => {
                        updateHandler({ [`${fieldName}_id`]: value ? parseInt(value, 10) : null })
                    }}
                    // styles={selectStyles}
                />
            </td>
        )
    }

    return (
        <td>
            <div className={_.textCell}>{defaultValue}</div>
        </td>
    )
}

export default memo(Cell)
