import { gql } from "@apollo/client";

export const BRANDS_QUERY = gql`
  query brandsQuery {
    brands {
      id
      name
      short_name
      company {
        id
        name
        short_name
      }
      color
    }
  }
`;


export const BRAND_UPDATE = gql`
  mutation BrandUpdate($brand: BrandUpdate!) {
    brandUpdate(brand: $brand) {
      id
      name
      short_name
      company {
        id
      }
      color
    }
  }
`;


export const BRAND_CREATE = gql`
  mutation BrandCreate($brand: BrandCreate!) {
    brandCreate(brand: $brand) {
      id
      name
      short_name
      company {
        id
      }
      color
    }
  }
`;

export const BRAND_DELETE = gql`
  mutation BrandDelete($id: Int!) {
    brandDelete(id: $id) {
      id
    }
  }
`;
