import { gql } from "@apollo/client";

export const ESTIMATES_GROUPS_QUERY = gql`
  query estimateGroupsQuery {
    estimateGroups {
      id
      name
      state {
        name
        id
      }
      date
      type {
        name
        id
      }
      title
      description
      finish
      remark
      company_restrictions
    }
  }
`;


export const ESTIMATES_GROUP_UPDATE = gql`
  mutation EstimateGroupUpdate($estimateGroup: EstimateGroupUpdate!) {
    estimateGroupUpdate(estimateGroup: $estimateGroup) {
      id
      name
      state {
        id
      }
      date
      type {
        id
      }
      title
      description
      finish
      remark
      company_restrictions
    }
  }
`;


export const ESTIMATES_GROUP_CREATE = gql`
  mutation EstimateGroupCreate($estimateGroup: EstimateGroupCreate!) {
    estimateGroupCreate(estimateGroup: $estimateGroup) {
      id
      name
      state {
        id
      }
      date
      type {
        id
      }
      title
      description
      finish
      remark
      company_restrictions
    }
  }
`;

export const ESTIMATES_GROUP_DELETE = gql`
  mutation EstimateGroupDelete($id: Int!) {
    estimateGroupDelete(id: $id) {
      id
    }
  }
`;
