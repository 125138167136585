import React, { memo, useState } from 'react'

import { CITIES_QUERY, CITY_CREATE, REGIONS_QUERY, CITY_UPDATE, CITY_DELETE } from 'connectors/gql/cities.gql'
import { useEntity, useSortAndFilter, useCreate, useUpdate, useDelete } from 'pages/EditorPage/hooks'
import { sendXLSX } from 'pages/EditorPage/helpers'

import Button from 'components/Button'
import Loader from 'components/Loader'
import Table from 'components/Table'
import Row from '../../components/Row'
import FilterRow from '../../components/Row/FilterRow'
import CreateCityModal from './CreateModal/CreateModal'

import { columns, defaultSorts } from './columns'

import _ from '../styles.module.css'
import { IRegion } from 'types/Regions'

const filters = {}
// @ts-ignore
columns.forEach((c) => (filters[c.path] = null))

const Cities = () => {
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false)

    const [regions, regionsLoading] = useEntity<IRegion>(REGIONS_QUERY, 'regions')
    const [cities, citiesLoading] = useEntity(CITIES_QUERY, 'cities', { full: true })

    const {
        filterFields,
        setFilterFields,
        sortedEntity: sortedCities,
        headerClick,
        headerSortsArrows,
        resetSort,
        resetFilters,
    } = useSortAndFilter(filters, cities, columns, 'cities', defaultSorts)

    const { onCreate, loading: createCityLoading } = useCreate(
        CITY_CREATE,
        CITIES_QUERY,
        'citiesQuery',
        'city',
        setIsModalCreateOpen,
    )

    const updateAction = useUpdate(CITY_UPDATE, 'city', CITIES_QUERY, 'citiesQuery')
    const deleteAction = useDelete(CITY_DELETE, CITIES_QUERY, 'citiesQuery', 'cityDelete')

    return (
        <div className={_.wrapper}>
            <div className={_.title}>Редактор городов</div>

            <div className={_.addBtn}>
                <Button name='Добавить' onClick={() => setIsModalCreateOpen(true)} />
                <Button
                    name='Выгрузить в XLSX'
                    mods={['blue']}
                    onClick={() => sendXLSX(sortedCities, columns, 'cities')}
                />
            </div>

            <div className={_.tableWrapper}>
                <Table
                    headers={columns}
                    headerClick={headerClick}
                    sorts={headerSortsArrows}
                    resetSort={resetSort}
                    showBorder
                    isSticky
                >
                    <FilterRow
                        columns={columns}
                        filterFields={filterFields}
                        setFilterFields={setFilterFields}
                        dataListForField={{
                            region: regions,
                        }}
                        reset={resetFilters}
                    />
                    {sortedCities.map((c: any) => (
                        <Row
                            key={c.id}
                            {...c}
                            columns={columns}
                            dataListForField={{
                                region: regions,
                            }}
                            updateAction={updateAction}
                            deleteAction={deleteAction}
                            canDelete
                        />
                    ))}
                </Table>
            </div>

            <CreateCityModal
                open={isModalCreateOpen}
                onClose={() => setIsModalCreateOpen(false)}
                onCreate={onCreate}
                dataList={{
                    regions,
                }}
            />

            {(regionsLoading || createCityLoading || citiesLoading) && <Loader />}
        </div>
    )
}

export default memo(Cities)
