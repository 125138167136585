import { useParams } from 'react-router-dom'

import Header from 'components/Header'

import DraftsTable from './page/Drafts'
import RegionsTable from './page/Regions'
import CompaniesTable from './page/companies'
import BrandsTable from './page/brands'
import CitiesTable from './page/cities'
import BuildingsTable from './page/buildings'
import ShopsTable from './page/shops'
import EstimateGroupsTable from './page/estimate-groups'

import './styles.scss'

const EditorPage = ({ match }: any) => {
    const { editorName } = useParams()

    return (
        <div className='editor'>
            <Header />
            {editorName === 'drafts' && <DraftsTable />}
            {editorName === 'regions' && <RegionsTable />}
            {editorName === 'companies' && <CompaniesTable />}
            {editorName === 'brands' && <BrandsTable />}
            {editorName === 'cities' && <CitiesTable />}
            {editorName === 'buildings' && <BuildingsTable />}
            {editorName === 'shops' && <ShopsTable />}
            {editorName === 'estimate-groups' && <EstimateGroupsTable />}
        </div>
    )
}

export default EditorPage
