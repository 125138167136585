import { gql } from "@apollo/client";

export const TICKETS_PAGE_QUERY = gql`
  query ticketsPageQuery(
    $cities: [Int!]
    $buildings: [Int!]
    $brands: [Int!]
    $companies: [Int!]
    $branches: [Int!]
    $priorities: [Int!]
    $ticketStates: [Int!]
    $ticketsPhrase: String
    $ticketsArchive: Int
    $page: Int
    $perPage: Int
  ) {
    tickets(
      cities: $cities
      buildings: $buildings
      brands: $brands
      companies: $companies
      branches: $branches
      priorities: $priorities
      states: $ticketStates
      phrase: $ticketsPhrase
      archive: $ticketsArchive
      page: $page
      perPage: $perPage
    ) {
      id
      topic
      shop {
        brand {
          name
          short_name
          color
        }
        building {
          name
          city {
            name
            abbreviation
            color
          }
          color
        }
        code
      }
      state {
        id
        name
      }
      branch {
        id
        name
      }
      priority {
        id
        name
      }
      estimate_group {
        id
        name
      }
      type {
        code
      }
      equipment {
        id
        supplied_by_contractor
        supplied_by_shop
        stored_in_shop
        ware {
          id
        }
        unit {
          id
        }
      }
      number
      external_code
      created
      milestone
      details
      complete_jobs
      technical_remark
      technical_spend
      supplied_by_client
      supplied_by_service
      left_in_shop
      uncomplete_jobs
      technical_event_archive
      technical_chat
    }
    ticketStates {
      id
      name
    }
    estimateGroups(onlyActual: true) {
      id
      name
    }
  }
`;


export const TICKET_QUERY = gql`
  query Ticket($id: Int!) {
    ticket(id: $id) {
      id
      number
      external_code
      created
      details
      complete_jobs
      uncomplete_jobs
      technical_remark
      remark
      milestone
      topic
      messages {
        id
        text
        level
        is_pinned
        created_at
        user {
          given_name
          family_name
          email
        }
      }
      files {
        id
        name
        mimetype
        size
        created_at
        created_by {
          email
          given_name
          family_name
        }
      }
      priority {
        id
      }
      state {
        id
      }
      type {
        code
      }
      shop {
        id
        code
        email
        phone
        brand {
          name
          short_name
        }
        building {
          name
          address
          city {
            name
          }
        }
      }
      equipment {
        id
        supplied_by_contractor
        supplied_by_shop
        stored_in_shop
        ware {
          id
        }
        unit {
          id
        }
      }
    }
  }
`;


export const TICKETS_COUNT_PAGE_QUERY = gql`
  query countTicketsWithoutPaginationQuery(
    $cities: [Int!]
    $buildings: [Int!]
    $brands: [Int!]
    $companies: [Int!]
    $branches: [Int!]
    $priorities: [Int!]
    $ticketStates: [Int!]
    $ticketsPhrase: String
    $ticketsArchive: Int
  ) {
    countTicketsWithoutPagination(
      cities: $cities
      buildings: $buildings
      brands: $brands
      companies: $companies
      branches: $branches
      priorities: $priorities
      states: $ticketStates
      phrase: $ticketsPhrase
      archive: $ticketsArchive
    )
  }
`;


export const TICKETS_PAGE_DICTIONARIES_QUERY = gql`
  query ticketsPageDictionariesQuery {
    companies {
      id
      name
    }
    cities {
      id
      name
      count_tickets
    }
    buildings {
      id
      name
      city {
        id
        abbreviation
      }
      shops {
        brand {
          id
        }
      }
    }
    brands {
      id
      name
    }
    branches {
      id
      name
    }
    priorities {
      id
      name
    }
    ticketStates {
      id
      name
    }
    ticketTypes {
      id
      name
    }
  }
`;

export const TICKET_UPDATE_STATE = gql`
  mutation TicketUpdateState($id: Int!, $value: Int!) {
    ticketUpdateState(id: $id, value: $value) {
      id
      state {
        id
        name
      }
    }
  }
`;

export const TICKET_UPDATE_PRIORITY = gql`
  mutation TicketUpdatePriority($id: Int!, $value: Int!) {
    ticketUpdatePriority(id: $id, value: $value) {
      id
      priority {
        id
      }
    }
  }
`;

export const TICKET_UPDATE_ESTIMATE_GROUP = gql`
  mutation TicketUpdateEstimateGroup($id: Int!, $value: Int!) {
    ticketUpdateEstimateGroup(id: $id, value: $value) {
      id
      estimate_group {
        id
        name
      }
    }
  }
`;

export const TICKET_UPDATE_MILESTONE = gql`
  mutation TicketUpdateMilestone($id: Int!, $value: DateTime!) {
    ticketUpdateMilestone(id: $id, value: $value) {
      id
      milestone
    }
  }
`;

export const TICKET_UPDATE_DETAILS = gql`
  mutation TicketUpdateDetails($id: Int!, $value: String!) {
    ticketUpdateDetails(id: $id, value: $value) {
      id
      details
    }
  }
`;

export const TICKET_UPDATE_COMPLETE_JOBS = gql`
  mutation TicketUpdateCompleteJobs($id: Int!, $value: String!) {
    ticketUpdateCompleteJobs(id: $id, value: $value) {
      id
      complete_jobs
    }
  }
`;

export const TICKET_UPDATE_TECHNICAL_REMARK = gql`
  mutation TicketUpdateTechnicalRemark($id: Int!, $value: String!) {
    ticketUpdateTechnicalRemark(id: $id, value: $value) {
      id
      technical_remark
    }
  }
`;

export const TICKET_UPDATE_TECHNICAL_SPEND = gql`
  mutation TicketUpdateTechnicalSpend($id: Int!, $value: String!) {
    ticketUpdateTechnicalSpend(id: $id, value: $value) {
      id
      technical_spend
    }
  }
`;

export const TICKET_UPDATE_TECHNICAL_CHAT = gql`
  mutation TicketUpdateTechnicalChat($id: Int!, $value: String!) {
    ticketUpdateTechnicalChat(id: $id, value: $value) {
      id
      technical_chat
    }
  }
`;

export const TICKET_UPDATE_SUPPLIED_BY_SERVICE = gql`
  mutation TicketUpdateSuppliedByService($id: Int!, $value: String!) {
    ticketUpdateSuppliedByService(id: $id, value: $value) {
      id
      supplied_by_service
    }
  }
`;

export const TICKET_UPDATE_SUPPLIED_BY_CLIENT = gql`
  mutation TicketUpdateSuppliedByClient($id: Int!, $value: String!) {
    ticketUpdateSuppliedByClient(id: $id, value: $value) {
      id
      supplied_by_client
    }
  }
`;

export const TICKET_UPDATE_LEFT_IN_SHOP = gql`
  mutation TicketUpdateLeftInShop($id: Int!, $value: String!) {
    ticketUpdateLeftInShop(id: $id, value: $value) {
      id
      left_in_shop
    }
  }
`;

export const TICKET_UPDATE_UNCOMPLETE_JOBS = gql`
  mutation TicketUpdateUncompleteJobs($id: Int!, $value: String!) {
    ticketUpdateUncompleteJobs(id: $id, value: $value) {
      id
      uncomplete_jobs
    }
  }
`;

export const TICKET_UPDATE_TECHNICAL_EVENT_ARCHIVE = gql`
  mutation TicketUpdateTechnicalEventArchive($id: Int!, $value: String!) {
    ticketUpdateTechnicalEventArchive(id: $id, value: $value) {
      id
      technical_event_archive
    }
  }
`;


export const TICKET_UPDATE_TOPIC = gql`
  mutation TicketUpdateTopic($id: Int!, $value: String!) {
    ticketUpdateTopic(id: $id, value: $value) {
      id
      topic
    }
  }
`;

export const TICKET_SET_NEXT_LINEAR_STATE = gql`
  mutation TicketSetNextLinearState($id: Int!) {
    ticketSetNextLinearState(id: $id) {
      id
      state {
        id
        name
      }
    }
  }
`;

export const TICKET_EQUPMENT_CREATE = gql`
  mutation EquipmentCreate($equipment: EquipmentCreate!) {
    equipmentCreate(equipment: $equipment) {
      id
      supplied_by_contractor
      supplied_by_shop
      stored_in_shop
      created_at
      ware {
        id
      }
      unit {
        id
      }
    }
  }
`;

export const TICKET_EQUPMENT_DELETE = gql`
  mutation EquipmentDelete($id: Int!) {
    equipmentDelete(id: $id) {
      id
    }
  }
`;

export const TICKET_EQUIPMENT_UPDATE = gql`
  mutation EquipmentUpdate($equipment: EquipmentUpdate!) {
    equipmentUpdate(equipment: $equipment) {
      id
      supplied_by_contractor
      supplied_by_shop
      stored_in_shop
      created_at
      ware {
        id
      }
      unit {
        id
      }
    }
  }
`;

export const TICKET_CREATE = gql`
  mutation TicketCreate($ticket: TicketCreate!) {
    ticketCreate(ticket: $ticket) {
      id
      topic
      state {
        id
      }
      type {
        id
        code
      }
      priority {
        id
      }
      branch {
        id
      }
      details
      shop {
        id
      }
      created
    }
  }
`;

export const TICKET_MESSAGE_CREATE = gql`
  mutation MessageCreate ($message: MessageCreate!) {
    messageCreate(message: $message) {
      text
      is_pinned
    }
  }
`;

export const TICKET_MESSAGE_UPDATE = gql`
  mutation MessageUpdate ($message: MessageUpdate!) {
    messageUpdate(message: $message) {
      id
      is_pinned
    }
  }
`;