import { notification } from 'antd'

import { BsCheckCircleFill, BsExclamationCircleFill } from 'react-icons/bs'

import './styles.scss'

export const openErrorNotification = (message: string, description?: string) => {
    notification.open({
        message,
        description,
        placement: 'bottomRight',
        icon: (
            <div style={{ width: 100 }}>
                <BsExclamationCircleFill style={{ color: '#EE7674' }} />
            </div>
        ),
    })
}

export const openInfoNotification = (message: string, description?: string) => {
    notification.open({
        message,
        description,
        placement: 'bottomRight',
        icon: (
            <div style={{ width: 100 }}>
                <BsExclamationCircleFill style={{ color: '#75B9BE' }} />
            </div>
        ),
    })
}

export const openSuccessNotification = (message: string, description?: string) => {
    notification.open({
        message,
        description,
        placement: 'bottomRight',
        icon: (
            <div style={{ width: 100 }}>
                <BsCheckCircleFill style={{ color: '#26C485' }} />
            </div>
        ),
    })
}
