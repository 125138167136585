import { gql } from "@apollo/client";

export const BRANCHES_QUERY = gql`
  query branchesQuery {
    branches {
      id
      name
    }
  }
`;
