import React, { memo, useState } from 'react'
import { IRegion } from 'types/Regions'

import { REGIONS_QUERY, REGION_CREATE, REGION_UPDATE, REGION_DELETE } from 'connectors/gql/regions.gql'
import { sendXLSX } from 'helpers'

import Loader from 'components/Loader'
import Button from 'components/Button'
import Table from 'components/Table'
import Row from '../../components/Row'
import FilterRow from '../../components/Row/FilterRow'
import CreateRegionModal from './CreateModal'

import { columns, defaultSorts } from './columns'
import { useEntity, useSortAndFilter, useCreate, useUpdate, useDelete } from 'helpers/hooks/editor'

import _ from '../styles.module.css'

const filters = {}
// @ts-ignore
columns.forEach((c) => (filters[c.path] = null))

const Regions = () => {
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false)
    const [regions, regionsLoading] = useEntity(REGIONS_QUERY, 'regions')

    const {
        filterFields,
        setFilterFields,
        sortedEntity: sortedRegions,
        headerClick,
        headerSortsArrows,
        resetSort,
        resetFilters,
    } = useSortAndFilter(filters, regions, columns, 'regions', defaultSorts)

    const { onCreate, loading: createRegionLoading } = useCreate(
        REGION_CREATE,
        REGIONS_QUERY,
        'regionsQuery',
        'region',
        setIsModalCreateOpen,
    )

    const updateAction = useUpdate(REGION_UPDATE, 'region')
    const deleteAction = useDelete(REGION_DELETE, REGIONS_QUERY, 'regionsQuery', 'regionDelete')

    return (
        <div className={_.wrapper}>
            <div className={_.title}>Редактор регионов</div>

            <div className={_.addBtn}>
                <Button name='Добавить' onClick={() => setIsModalCreateOpen(true)} />
                <Button
                    name='Выгрузить в XLSX'
                    mods={['blue']}
                    onClick={() => sendXLSX(sortedRegions, columns, 'regions')}
                />
            </div>

            <div className={_.tableWrapper}>
                <Table
                    headers={columns}
                    headerClick={headerClick}
                    sorts={headerSortsArrows}
                    resetSort={resetSort}
                    showBorder
                    isSticky
                >
                    <FilterRow
                        columns={columns}
                        filterFields={filterFields}
                        setFilterFields={setFilterFields}
                        reset={resetFilters}
                        dataListForField={{}}
                    />
                    {sortedRegions.map((b: IRegion) => (
                        <Row
                            key={b.id}
                            {...b}
                            columns={columns}
                            dataListForField={{}}
                            updateAction={updateAction}
                            deleteAction={deleteAction}
                            canDelete
                        />
                    ))}
                </Table>
            </div>

            <CreateRegionModal
                open={isModalCreateOpen}
                onClose={() => setIsModalCreateOpen(false)}
                onCreate={onCreate}
            />

            {(createRegionLoading || regionsLoading) && <Loader />}
        </div>
    )
}

export default memo(Regions)
