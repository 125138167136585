import { useEffect } from "react";

// фильтрация брендов по выбранным ТЦ
export const useBrandFiltering = (
  dictionaries,
  buildingFilterValues,
  setBrandsFromBuildingsIds
) => {
  useEffect(() => {
    const _brandsFromBuildingsIds = [];
    // вычленяем полный набор данных по выбранным ТЦ
    const selectedBuildings =
      (dictionaries &&
        dictionaries.buildings.filter((building) => {
          return buildingFilterValues.includes(building.id);
        })) ||
      [];

    // выдёргиваем ID брендов
    selectedBuildings.forEach((building) => {
      building.shops.forEach((shop) => {
        _brandsFromBuildingsIds.push(shop.brand.id);
      });
    });

    setBrandsFromBuildingsIds(_brandsFromBuildingsIds);
    // eslint-disable-next-line
  }, [dictionaries && dictionaries.buildings, buildingFilterValues]);
};
