import { IEditorColumn } from 'types'

export const defaultSorts = [
    { path: 'name', type: 'input', sort: 'asc' },
    { path: 'company', type: 'select', sort: 'asc' },
]

export const columns: IEditorColumn[] = [
    {
        name: 'ID',
        path: 'id',
        type: 'id',
        width: '30px',
    },
    {
        name: 'Имя',
        path: 'name',
        type: 'input',
        width: '125px',
    },
    {
        name: 'Сокр.',
        path: 'short_name',
        type: 'input',
        width: '55px',
    },
    {
        name: 'Компания',
        path: 'company',
        type: 'select',
        width: '275px',
        placeholder: 'Выберите компанию',
    },
    {
        name: 'Цвет',
        path: 'color',
        type: 'color',
        width: '70px',
    },
]
