import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import clsx from 'clsx'
import DatePicker from 'react-date-picker'

import { AiOutlineFieldTime } from 'react-icons/ai'
import { VscRocket } from 'react-icons/vsc'
import { TiPin, TiPinOutline } from 'react-icons/ti'
import { BiRuble } from 'react-icons/bi'
import { IoInformationCircleOutline } from 'react-icons/io5'

import { addDay, subDay, dateFmtFromStr } from 'tools'
import { TICKET_SET_NEXT_LINEAR_STATE, TICKET_UPDATE_MILESTONE, TICKET_UPDATE_STATE } from 'connectors/gql/ticket.gql'

import { ClickableTicketCode } from 'components/ClickableTicketCode'

import _ from './styles.module.css'

const Icon = memo(({ icon, onClick, disabled = false }: any) => (
    <div className={clsx(_.icon, disabled && _.disabled)} onClick={onClick}>
        {icon}
    </div>
))

// export const CLickableTicketCode = memo(({ code, divider = false }: any) => {
//     let href

//     if (code.substr(0, 2) === 'SU') href = `https://support.city-gals.com/requests/${code.substring(2, code.length)}`

//     if (code.substr(0, 3) === 'SES') {
//         const _code = parseInt(code.slice(3), 10) + 150
//         href = `https://platforma.ses-support.com/platform.php/failureNotifications/show/id/${_code}`
//     }

//     return (
//         <span>
//             {divider && ` • `}
//             <a href={href} rel='noopener noreferrer' target='_blank'>
//                 {code}
//             </a>
//         </span>
//     )
// })

const InfoCell = ({ ticket, states, setPin, pinnedList, buildingColor }: any) => {
    const [milestoneMutator] = useMutation(TICKET_UPDATE_MILESTONE)
    const setMilestone = (value: any) => {
        milestoneMutator({ variables: { id: ticket.id, value } })
    }
    const setMilestoneByDatePicker = (value: any) => {
        milestoneMutator({ variables: { id: ticket.id, value: value.getTime() } })
    }

    const [stateMutator] = useMutation(TICKET_UPDATE_STATE)
    const setState = (event: any) =>
        stateMutator({ variables: { id: ticket.id, value: parseInt(event.target.value, 10) } })

    const [linearStateMutator] = useMutation(TICKET_SET_NEXT_LINEAR_STATE)
    const setNextLinearState = () => linearStateMutator({ variables: { id: ticket.id } })

    const handleClock = (event: any) => {
        const { shiftKey: shift, ctrlKey: ctrl } = event

        if (!shift && !ctrl) setMilestone(subDay(ticket.milestone)) // -1 day
        if (shift && !ctrl) setMilestone(addDay(ticket.milestone)) // +1 day
        if (!shift && ctrl) setMilestone(Date.now()) // today
        if (shift && ctrl) setMilestone(ticket.created) // created
    }

    const placeInfo = (ticket && `${ticket.shop.building.name} - ${ticket.shop.building.city.abbreviation}`) || ''

    return (
        <td className={clsx(_.wrap, _.grayInfoCell, 'infoWidth')}>
            <table className={_.table}>
                <tbody>
                    <tr>
                        <td colSpan={4}>
                            <div className={clsx(_.mainInfo, _.firstBlock)} style={{ background: buildingColor }}>
                                {ticket.shop.brand.name}
                            </div>
                            <div className={clsx(_.mainInfo, _.firstBlock)} style={{ background: buildingColor }}>
                                {placeInfo}
                            </div>
                            <div className={clsx(_.mainInfo, _.secondBlock)}>
                                {ticket.number && (
                                    <Link to={`ticket/${ticket.id}`}>
                                        {ticket.type.code}
                                        {ticket.number}
                                    </Link>
                                )}
                                {ticket.external_code ? (
                                    <ClickableTicketCode code={ticket.external_code} divider />
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className={clsx(_.mainInfo, _.secondBlock)}>от {dateFmtFromStr(ticket.created)}</div>
                        </td>
                    </tr>

                    <tr>
                        <td className={clsx(_.textCenter, _.thirdBlock)} colSpan={3}>
                            Срок:{' '}
                            <DatePicker
                                onChange={setMilestoneByDatePicker}
                                value={new Date(ticket.milestone)}
                                calendarIcon={null}
                                clearIcon={null}
                                minDate={new Date(ticket.created)}
                                format='dd.MM.y'
                            />
                        </td>
                        <td>
                            <Icon icon={<AiOutlineFieldTime />} onClick={handleClock} />
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={3}>
                            <select className={_.statesSelect} value={ticket.state.id} onChange={setState}>
                                {states.map((state: any) => (
                                    <option key={state.id} value={state.id}>
                                        {state.name}
                                    </option>
                                ))}
                            </select>
                        </td>
                        <td>
                            <Icon icon={<VscRocket />} onClick={setNextLinearState} />
                        </td>
                    </tr>

                    <tr className={_.iconsContainer}>
                        <td colSpan={4}>
                            <div className='flex'>
                                <div
                                    // @ts-ignore
                                    colSpan={2}
                                    className={clsx(
                                        _.lowIcon,
                                        pinnedList.map((p: any) => p.id).includes(ticket.id) && _.enable,
                                    )}
                                >
                                    <Icon
                                        icon={
                                            pinnedList.map((p: any) => p.id).includes(ticket.id) ? (
                                                <TiPin />
                                            ) : (
                                                <TiPinOutline />
                                            )
                                        }
                                        onClick={() => setPin(ticket)}
                                    />
                                </div>
                                <div className={_.lowIcon}>
                                    <Icon icon={<IoInformationCircleOutline />} disabled />
                                </div>
                                <div className={_.lowIcon}>
                                    <Icon icon={<BiRuble />} disabled />
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>
    )
}

export default memo(InfoCell)
