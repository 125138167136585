import React, { memo, useState } from 'react'
import clsx from 'clsx'

import _ from './styles.module.css'

const Cell = ({ children, updateHandler, fieldName, important, defaultValue }: any) => {
    const [val, setVal] = useState(defaultValue || '')

    const update = (event: any) => {
        const trimmedValue = event.target.value.trim()

        if (val !== trimmedValue) {
            updateHandler(trimmedValue)
            setVal(trimmedValue)
        }
    }

    return (
        <td>
            <div className={_.cellContainer}>
                <textarea
                    className={clsx(_.textarea, `${fieldName}_width`, important && _.important)}
                    rows={children ? 9 : 10}
                    defaultValue={defaultValue}
                    onBlur={update}
                />
                {children}
            </div>
        </td>
    )
}

export default memo(Cell)
