import { memo, useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'antd'

import { BRANCHES_QUERY } from 'connectors/gql/branches.gql'
import { setOptionsFormat } from 'helpers'
import { IBranch, ISelectOption } from 'types'

interface IBranchesSelect {
    value: number
    onChange: (data: number) => void
    className?: string
}

const BranchesSelect = ({ value, onChange, className, ...props }: IBranchesSelect) => {
    const { data } = useQuery(BRANCHES_QUERY)

    const [branches, setBranches] = useState<IBranch[]>(data?.ticket || [])
    const [options, setOptions] = useState<ISelectOption[]>(setOptionsFormat(branches) || [])

    useEffect(() => {
        if (data) {
            setBranches(data.branches)
            setOptions(setOptionsFormat(data.branches))
        }
    }, [JSON.stringify(data?.branches || {})]) // eslint-disable-line

    return (
        <Select
            className={className}
            value={options.find((o: any) => o.value === value)?.value}
            placeholder={'Отрасль не выбрана'}
            options={options}
            onChange={onChange}
            {...props}
        />
    )
}

export default memo(BranchesSelect)
