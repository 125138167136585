import React, { memo, useState, useEffect } from 'react'

import { IBrand, IBuilding, IShop, IShopState } from 'types'
import { SHOPS_QUERY, SHOP_CREATE, SHOP_STATES_QUERY, SHOP_UPDATE, SHOP_DELETE } from 'connectors/gql/shops.gql'
import { BRANDS_QUERY } from 'connectors/gql/brands.gql'
import { BUILDINGS_QUERY } from 'connectors/gql/buildings.gql'

import { useEntity, useSortAndFilterForBack, useCreate, useUpdate, useDelete } from 'pages/EditorPage/hooks'
import { sendXLSX } from 'pages/EditorPage/helpers'

import Pagination from 'components/Pagination'
import Button from 'components/Button'
import Loader from 'components/Loader'
import Table from 'components/Table'
import Row from '../../components/Row'
import FilterRow from '../../components/Row/FilterRow'
import CreateShopModal from './CreateModal'
import ShownColumns from '../../components/ShownColumns'

import { columns, defaultSorts, backendFilterFieldsNames, backendSortFieldsNames } from './columns'

import _ from '../styles.module.css'

const filters = {}
// @ts-ignore
columns.forEach((c) => (filters[c.path] = null))

const defaultColumnsList = ['id', 'code', 'email', 'phone', 'area', 'director', 'remark', 'chronology', 'building']
const dateFields = ['opened', 'closed', 'serve_start', 'serve_end', 'lastEvent']
const selectFields = ['brand', 'building', 'state']

const upgradeShops = (shops: IShop[]) => {
    return shops.map((s: IShop) => {
        const dates: string[] = []
        // @ts-ignore
        dateFields.forEach((df) => (s[df] as string | undefined) && dates.push(s[df]))
        // TODO: доделать поиск последней даты
        // @ts-ignore
        const lastDate = dates.length ? Math.max(...dates) || undefined : undefined
        return {
            ...s,
            store: `${s.brand.name} ${s.code} ТЦ ${s.building.name} - ${s.building.city.name}`,
            storeShort: `${s.brand.short_name} ${s.building.name} - ${s.building.city.abbreviation}`,
            lastEvent: lastDate,
        }
    })
}

const Buildings = () => {
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false)

    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(100)
    const [variables, setVariables] = useState({})

    useEffect(() => {
        ;(async function init() {
            const _columnsList = localStorage.getItem('columnsListShops')
            const _page = localStorage.getItem('pageShops')
            const _perPage = localStorage.getItem('perPageShops')
            if (_page) setPage(parseInt(_page))
            if (_perPage) setPerPage(parseInt(_perPage))
            if (_columnsList) setColumnsList(JSON.parse(_columnsList))
        })()
    }, [])

    const [buildings, buildingsLoading] = useEntity<IBuilding>(BUILDINGS_QUERY, 'buildings')
    const [shops, shopsLoading] = useEntity<IShop>(SHOPS_QUERY, 'shops', variables)
    const [shopStates, shopStatesLoading] = useEntity<IShopState>(SHOP_STATES_QUERY, 'shopStates')
    const [brands, brandsLoading] = useEntity<IBrand>(BRANDS_QUERY, 'brands')
    const [, xlsxShopsLoading, xlsxShopsRefetch] = useEntity(SHOPS_QUERY, 'shops', {
        ...variables,
        fetchPolicy: 'standby',
        page: 1,
        perPage: 10000,
    })

    // columnsList - список paths
    const [columnsList, setColumnsList] = useState(
        // @ts-ignore
        JSON.parse(localStorage.getItem('columnsListShops')) || defaultColumnsList,
    )
    const checkColumn = (field: any) => () => {
        let newColumnsList = [...columnsList]
        if (columnsList.includes(field)) {
            newColumnsList = columnsList.filter((c: any) => c !== field)
        } else {
            newColumnsList = [...columnsList, field]
        }
        setColumnsList(newColumnsList)
        localStorage.setItem('columnsListShops', JSON.stringify(newColumnsList))
    }

    // columnsList - список объектов из columns
    const [selectedColumns, setSelectedColumns] = useState(columns.filter((c) => columnsList.includes(c.path)))
    useEffect(() => {
        setSelectedColumns(columns.filter((c) => columnsList.includes(c.path)))
    }, [columnsList])

    // дорисовка доп полей
    const [upgradedShops, setUpgradedShops] = useState<IShop[]>([])
    useEffect(() => {
        setUpgradedShops(upgradeShops(shops))
    }, [JSON.stringify(shops)]) // eslint-disable-line

    const { filterFields, setFilterFields, headerClick, headerSortsArrows, sorts, resetSort, resetFilters } =
        useSortAndFilterForBack(filters, 'shops', defaultSorts, selectedColumns)

    // отслеживание сортировки
    useEffect(() => {
        // @ts-ignore
        const sorting = sorts.map((s: any) => ({ field: backendSortFieldsNames[s.path], direction: s.sort }))
        setVariables((data) => ({ ...data, sorting }))
    }, [JSON.stringify(sorts)]) // eslint-disable-line

    // отслеживание фильтрации
    useEffect(() => {
        const filters: any = []
        Object.keys(filterFields).forEach((key) => {
            if (filterFields[key]) {
                if (dateFields.includes(key)) {
                    if (filterFields[key].date)
                        filters.push({
                            // @ts-ignore
                            field: backendFilterFieldsNames[key],
                            value: filterFields[key].date,
                            operator: filterFields[key].pos,
                        })
                } else if (selectFields.includes(key)) {
                    if (filterFields[key].length)
                        // @ts-ignore
                        filters.push({ field: backendFilterFieldsNames[key], values: filterFields[key] })
                } else {
                    // @ts-ignore
                    filters.push({ field: backendFilterFieldsNames[key], value: `${filterFields[key]}` })
                }
            }
        })
        setVariables((data) => ({ ...data, filters }))
    }, [JSON.stringify(filterFields)]) // eslint-disable-line

    const { onCreate, loading: createShopLoading } = useCreate(
        SHOP_CREATE,
        SHOPS_QUERY,
        'shopsQuery',
        'shop',
        setIsModalCreateOpen,
    )

    const updateAction = useUpdate(SHOP_UPDATE, 'shop')
    const deleteAction = useDelete(SHOP_DELETE, SHOPS_QUERY, 'shopsQuery', 'shopDelete')

    // пагинация
    useEffect(() => {
        // @ts-ignore
        localStorage.setItem('pageShops', page)
        setVariables((data) => ({
            ...data,
            page,
        }))
    }, [page]) // eslint-disable-line

    useEffect(() => {
        localStorage.setItem('perPageShops', `${perPage}`)
        localStorage.setItem('pageShops', '1')
        setPage(1)
        setVariables((data) => ({
            ...data,
            page: 1,
            perPage,
        }))
    }, [perPage]) // eslint-disable-line

    return (
        <div className={_.wrapper}>
            <div className={_.title}>Редактор магазинов</div>

            <div className='flex'>
                <div className={_.addBtn}>
                    <Button name='Добавить' onClick={() => setIsModalCreateOpen(true)} />
                    <Button
                        name='Выгрузить в XLSX'
                        mods={['blue']}
                        onClick={() =>
                            // @ts-ignore
                            xlsxShopsRefetch().then(({ data }) => {
                                if (data && data.shops) sendXLSX(upgradeShops(data.shops), selectedColumns, 'shops')
                            })
                        }
                    />
                </div>
                <ShownColumns
                    columns={columns}
                    checkColumn={checkColumn}
                    columnsList={selectedColumns.map((c) => c.path)}
                />
            </div>

            <div className={_.tableWrapper}>
                <Table
                    headers={selectedColumns}
                    headerClick={headerClick}
                    sorts={headerSortsArrows}
                    resetSort={resetSort}
                    showBorder
                    isSticky
                >
                    <FilterRow
                        columns={selectedColumns}
                        filterFields={filterFields}
                        setFilterFields={setFilterFields}
                        dataListForField={{
                            brand: brands,
                            building: buildings.map((b: IBuilding) => ({
                                ...b,
                                name: `${b?.city?.name || ''} - ${b.name}`,
                            })),
                            state: shopStates,
                        }}
                        reset={resetFilters}
                    />
                    {upgradedShops.map((s: IShop) => (
                        <Row
                            key={s.id}
                            {...s}
                            columns={selectedColumns}
                            dataListForField={{
                                brand: brands,
                                building: buildings,
                                state: shopStates,
                            }}
                            updateAction={updateAction}
                            deleteAction={deleteAction}
                            colorRows
                            canDelete
                        />
                    ))}
                </Table>
                <Pagination page={page} perPage={perPage} setPage={setPage} setPerPage={setPerPage} />
            </div>

            <CreateShopModal
                open={isModalCreateOpen}
                onClose={() => setIsModalCreateOpen(false)}
                onCreate={onCreate}
                dataList={{
                    brands,
                    buildings,
                    shopStates,
                }}
            />

            {(shopsLoading ||
                createShopLoading ||
                brandsLoading ||
                buildingsLoading ||
                shopStatesLoading ||
                xlsxShopsLoading) && <Loader />}
        </div>
    )
}

export default memo(Buildings)
