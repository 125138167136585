import React, { memo } from 'react'
import { BsFiles } from 'react-icons/bs'
import { openSuccessNotification } from 'components/Notifications'

import _ from './styles.module.css'

const ShopData = ({ shop }: any) => {
    const mainDataRow = `${shop.brand.name} ${shop.code} ТЦ ${shop.building.name} - ${shop.building.city.name}`

    const copyHandler = () => {
        navigator.clipboard.writeText(mainDataRow).then(() => {
            openSuccessNotification('Скопировано в буфер')
        })
    }

    return (
        <div className={_.wrapper}>
            <BsFiles className={_.copyIcon} onClick={copyHandler} />

            <div>{mainDataRow}</div>
            <div>Адрес: {shop.building.address}</div>
            <div>Телефон: {shop.phone}</div>
            <div>E-mail: {shop.email}</div>
        </div>
    )
}

export default memo(ShopData)
