import React, { memo } from 'react'
import clsx from 'clsx'
import { Select } from 'antd'

import _ from './styles.module.css'

const pages = [
    { label: 25, value: 25 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 1000, value: 1000 },
]

interface IPagingation {
    page: number
    perPage: number
    setPage: (page: number) => void
    setPerPage: (page: number) => void
    ticketsCount?: number
    noSpacer?: boolean
}

const Pagination = ({ page, perPage, setPage, setPerPage, ticketsCount, noSpacer = false }: IPagingation) => {
    return (
        <div className={_.paginationWrapper}>
            {!noSpacer && <div className={_.spacer} />}
            <div className={_.pagesWrapper}>
                {page > 2 && (
                    <div className={clsx(_.link, _.page)} onClick={() => setPage(page - 2)}>
                        {page - 2}
                    </div>
                )}
                {page > 1 && (
                    <div className={clsx(_.link, _.page)} onClick={() => setPage(page - 1)}>
                        {page - 1}
                    </div>
                )}
                <div className={_.page}>{page}</div>
                {ticketsCount && ~~(ticketsCount / perPage) > page - 1 && (
                    <div className={clsx(_.link, _.page)} onClick={() => setPage(page + 1)}>
                        {page + 1}
                    </div>
                )}
                {ticketsCount && ~~(ticketsCount / perPage) > page && (
                    <div className={clsx(_.link, _.page)} onClick={() => setPage(page + 2)}>
                        {page + 2}
                    </div>
                )}
            </div>
            <div className={_.perPageWrapper}>
                <Select
                    style={{ minWidth: 75 }}
                    value={perPage}
                    onChange={setPerPage}
                    options={pages}
                    // closeMenuOnSelect
                />
            </div>
            {ticketsCount && (
                <div className={_.allTicketsWrapper}>
                    <div>из</div>
                    <div>{ticketsCount}</div>
                </div>
            )}
        </div>
    )
}

export default memo(Pagination)
