import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'

import logo from './logo.svg'
import './App.css'
import TicketsPage from 'pages/TicketsPage'
import Header from 'components/Header'
import { SiderProvider } from 'components/Sider/sider.context'
import CabinetPage from 'pages/CabinetPage'
import TicketPage from 'pages/TicketPage'
import EditorPage from 'pages/EditorPage'
import TicketsPageNew from 'pages/TicketsPageNew'

function App() {
    const { keycloak } = useKeycloak()

    return (
        <SiderProvider>
            <BrowserRouter>
                <Routes>
                    <Route index path='/' element={<TicketsPage />} />

                    <Route index path='/cabinet' element={<CabinetPage />} />

                    <Route index path='/tickets' element={<TicketsPage light />} />
                    <Route index path='/ticketsNew' element={<TicketsPageNew />} />
                    <Route index path='/ticket/:id' element={<TicketPage />} />
                    <Route index path='/editor/:editorName' element={<EditorPage />} />
                </Routes>
            </BrowserRouter>
        </SiderProvider>
    )
}

export default App
