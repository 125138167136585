import { memo, useEffect, useRef, useState } from 'react'
import { Table, Collapse, Checkbox, Input, Flex, InputRef } from 'antd'
import { useQuery } from '@apollo/client'
import localforage from 'localforage'
import clsx from 'clsx'

import { isEmpty } from 'tools'
import { ITicket } from 'types'
import { TICKETS_PAGE_QUERY, TICKETS_COUNT_PAGE_QUERY } from 'connectors/gql/ticket.gql'
import TicketMainData from 'pages/TicketPage/components/ticket-main-data'
import TicketSecondData from 'pages/TicketPage/components/ticket-second-data'

import Loader from 'components/Loader'
import Pagination from 'components/Pagination'

import { columnsList, columns } from './columns'

import _ from './tickets.page.module.css'
import './antdStyles.scss'

const { Panel } = Collapse

const TicketsTable = ({ variables, dictsLoading, dictsError, page, perPage, setPage, setPerPage }: any) => {
    const filterRef = useRef<InputRef>(null)
    useEffect(() => {
        setPerPage && setPerPage(100)
    }, [setPerPage])

    const [showColumns, setShowColumns] = useState(columnsList)
    const [filter, setFilter] = useState('')
    const [cols, setCols] = useState(columns)

    const { loading, error, data } = useQuery(TICKETS_PAGE_QUERY, { variables })
    const { data: count } = useQuery(TICKETS_COUNT_PAGE_QUERY, { variables })
    const ticketsCount = count?.countTicketsWithoutPagination || 0

    const showColumn = (value: any) => () => {
        const _showColumns = showColumns.map((c) => (c.value === value ? { ...c, show: !c.show } : c))
        setShowColumns(_showColumns)
        localforage.setItem('tableFields', JSON.stringify(_showColumns))
    }

    useEffect(() => {
        ;(async function init() {
            // установка полей из localStorage
            const ticketsFields: any = await localforage.getItem('tableFields')

            if (ticketsFields && JSON.parse(ticketsFields)) {
                const parsedTicketsFields = JSON.parse(ticketsFields)

                if (parsedTicketsFields.length < columnsList.length) {
                    setShowColumns(columnsList)
                } else {
                    setShowColumns(JSON.parse(ticketsFields))
                }
            }
        })()
    }, [])

    // по ctrl + f - ставим фокус на инпут фильтрации
    useEffect(() => {
        const handler = (e: any) => {
            if ((e.ctrlKey || e.metaKey) && e.keyCode === 70) {
                e.preventDefault()
                filterRef.current?.focus()
            }
            return
        }
        window.addEventListener('keydown', handler)

        return () => window.removeEventListener('keydown', handler)
    }, [filterRef])

    useEffect(() => {
        const _cols = columns.filter((c) => {
            const currentColumns: any = showColumns.find((s) => s.value === c.key)
            // console.log('currentColumns', showColumns, currentColumns, c)
            if (!showColumns) return localforage.setItem('tableFields', null)

            return currentColumns?.show
        })
        // console.log('_cols', _cols)

        setCols(_cols)
    }, [JSON.stringify(showColumns)])

    const filteredData =
        data &&
        data?.tickets &&
        data?.tickets
            .map((t: ITicket) => ({ ...t, key: t.id }))
            .filter((row: ITicket) => {
                let hasVal = false
                const _row = {
                    ...row,
                    key: row.id,
                    branch: row.branch?.name,
                    priority: row.priority?.name,
                    state: row.state?.name,
                    brand: row.shop?.brand?.name,
                    brand_short_name: row.shop?.brand?.short_name,
                    building: row.shop?.building?.name,
                    city: row.shop?.building?.city?.name,
                    city_abbr: row.shop?.building?.city?.abbreviation,
                    shop_code: row.shop?.code,
                }

                Object.values(_row).forEach((val) => {
                    if (val && val.toString().toLowerCase().includes(filter.toLowerCase())) hasVal = true
                })
                return hasVal
            })

    if (loading || dictsLoading) return <Loader />
    if (error || dictsError) return <div>не удалось загрузить данные!</div>

    return (
        <div className={clsx(_.mainWrapper)}>
            <Collapse>
                <Input
                    ref={filterRef}
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    placeholder='Фильтр по тексту'
                    className={_.filter_search}
                    allowClear
                />
                <Panel header='Поля' key='1' className='panel'>
                    <div className={_.columnsChecks}>
                        {showColumns.map((c) => (
                            <Checkbox
                                className={_.checkbox}
                                checked={c.show}
                                onChange={showColumn(c.value)}
                                key={c.title}
                            >
                                {c.title}
                                {c.Data && (
                                    <span style={{ marginLeft: 10 }}>
                                        <c.Data />
                                    </span>
                                )}
                            </Checkbox>
                        ))}
                    </div>
                </Panel>
            </Collapse>

            {(!data?.tickets || isEmpty(data?.tickets)) && (
                <div className={_.empty}>Согласно установленным фильтрам заявок не найдено</div>
            )}

            <div className={_.paginatorWrapper}>
                <Pagination
                    page={page}
                    perPage={perPage}
                    setPage={setPage}
                    setPerPage={setPerPage}
                    ticketsCount={ticketsCount}
                    noSpacer
                />
            </div>
            <div
                className={`${_.listWrapper} ticketsLightList customScroll`}
                style={{ maxHeight: 'calc(100vh - 170px)' }}
            >
                <Table
                    className={_.customTable}
                    dataSource={filteredData}
                    columns={cols}
                    size='small'
                    pagination={false}
                    rowClassName={(record) => {
                        if (record.state.name === 'Отклонена') {
                            return 'row-gray row-font-color-red'
                        }
                        if (record.state.name === 'Закрыта') {
                            return 'row-gray row-font-color-gray'
                        }
                        return ''
                    }}
                    expandable={{
                        expandedRowRender: (record) => {
                            console.log('record', record)

                            return (
                                <Flex align='center' justify='center'>
                                    <TicketMainData {...record} />
                                    <TicketSecondData {...record} ticketRefetch={() => {}} />
                                </Flex>
                            )
                        },
                        showExpandColumn: false,
                        expandRowByClick: true,
                        // если потребуется возможность скрывать лишние сроки
                        // expandedRowKeys: [],
                    }}
                    sticky
                />
            </div>
        </div>
    )
}

export default memo(TicketsTable)
