import { IEditorColumn } from 'types'

export const defaultSorts = [
    { path: 'short_name', type: 'input', sort: 'asc' },
    { path: 'state', type: 'select', sort: 'asc' },
]

export const columns: IEditorColumn[] = [
    {
        name: 'ID',
        path: 'id',
        type: 'id',
        width: '30px',
    },
    {
        name: 'Имя',
        path: 'name',
        type: 'input',
        width: '275px',
    },
    {
        name: 'Сокращение',
        path: 'short_name',
        type: 'input',
        width: '225px',
    },
    {
        name: 'Статус',
        path: 'state',
        type: 'select',
        width: '230px',
        placeholder: 'Выберите статус',
    },
    {
        name: 'Хронология',
        path: 'chronology',
        type: 'textarea',
        width: '100%',
        minWidth: '250px',
    },
]
