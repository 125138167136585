import React, { memo, useEffect, useState } from 'react'
// @ts-ignore
import _keyBy from 'lodash.keyby'
import { BsClock } from 'react-icons/bs'
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai'
import { useQuery } from '@apollo/client'
// @ts-ignore
import DatePicker from 'react-date-picker'
// import Select from 'react-select'
import clsx from 'clsx'
import { Select } from 'antd'

import { addDay, subDay } from 'tools'
import { showErrors, inputCheckErrors, simpleValueSelectCheckErrors } from 'helpers'

import { SHOPS_QUERY } from 'connectors/gql/shops.gql'

import Button from 'components/Button'
import ShopsSelect from 'components/EntitySelects/ShopsSelect'
import BranchesSelect from 'components/EntitySelects/BranchesSelect'
import PrioritiesSelect from 'components/EntitySelects/PrioritiesSelect'

import _ from './styles.module.css'

const defaultExecutor = { value: 0, label: 'ООО "Сити-Галс Самара"' }

const TicketMainData = ({ ticket, setTicket, createTicket }: any) => {
    const { data: shopsData } = useQuery(SHOPS_QUERY, { variables: { page: 1, perPage: 9999 } })
    const [selectedStoreId, setSelectedStoreId] = useState<number | null>(null)
    const [errors, setErrors] = useState<string[]>([])
    const [oldDate, setOldDate] = useState<string | number | null>(null) // дата для быстрой установки в создаваемые заявки

    // вспоминаем сохранённую дату
    useEffect(() => {
        const _oldDate = localStorage.getItem('oldDate')
        _oldDate ? setOldDate(_oldDate) : setOldDate(new Date().getTime())
    }, [])

    useEffect(() => {
        if (oldDate) localStorage.setItem('oldDate', `${oldDate}`)
    }, [oldDate])

    const updateDate = (newDate: any) => {
        ticketUpdater('externalDate', newDate)
        if (newDate !== oldDate) setOldDate(newDate)
    }

    const ticketUpdater = (field: string, newValue: number | string) => {
        setTicket({ ...ticket, [field]: newValue })
        if (newValue) setErrors([...errors.filter((e) => e !== field)])
    }

    const inputCheckField = inputCheckErrors(setErrors, errors)
    const selectCheckField = simpleValueSelectCheckErrors(setErrors, errors)

    const shopsById = _keyBy(shopsData?.shops, 'id')
    const selectedShopData = shopsById[selectedStoreId]

    return (
        <div className={_.wrapper}>
            <div className={clsx(_.row)}>
                <div className={_.rowLabel}>Тема</div>
                <div className={_.longBlock}>
                    <input
                        type='text'
                        maxLength={100}
                        className={clsx(_.textInput, _.longInput)}
                        value={ticket?.topic}
                        onChange={(e) => ticketUpdater('topic', e.target.value)}
                    />
                </div>
            </div>

            <div className={clsx(_.row)}>
                <div className={_.rowLabel}>Содержание</div>
                <div className={_.longBlock}>
                    <textarea
                        className={clsx(_.textarea, errors.includes('details') && _.error)}
                        value={ticket?.details}
                        onChange={(e) => ticketUpdater('details', e.target.value)}
                        onBlur={() => inputCheckField(undefined, ticket?.details, 'details', false)}
                    />
                </div>
            </div>

            <div className={clsx(_.row)} style={{ paddingBottom: 50 }}>
                <div className={_.rowLabel}>Объект</div>
                <div className={_.longBlock} style={{ position: 'relative' }}>
                    <ShopsSelect
                        value={ticket.shop}
                        style={{ width: 730 }}
                        className={(errors.includes('shop') && _.error) || undefined}
                        onChange={(value: number) => {
                            setSelectedStoreId(Number(value))
                            ticketUpdater('shop', value)
                        }}
                        // @ts-ignore
                        onBlur={() => selectCheckField(ticket?.shop, 'shop')}
                        cityFirst
                        sortByState
                        decorateDisabled
                    />
                    <div className={_.underTextContainer} style={{ width: '-webkit-fill-available' }}>
                        <div>Адрес: {selectedShopData?.building?.address}</div>
                        <div>
                            Телефон: {selectedShopData?.phone}{' '}
                            {selectedShopData?.phone2 ? `/ ${selectedShopData?.phone2}` : ''}
                        </div>
                        <div>E-mail: {selectedShopData?.email}</div>
                    </div>
                </div>
            </div>

            <div className={clsx(_.firstRow, _.row)}>
                <div className={_.selectContainer} style={{ width: 255 }}>
                    <div className={_.selectLabel}>Внешний № заявки</div>
                    <div className={_.state}>
                        <input
                            type='text'
                            maxLength={20}
                            className={_.textInput}
                            value={ticket?.external_code}
                            onChange={(e) => ticketUpdater('external_code', e.target.value)}
                        />
                    </div>
                </div>

                <div className={_.selectContainer}>
                    <div className={_.selectLabel}>Дата</div>
                    <div className={_.dateWrapper}>
                        <DatePicker
                            value={ticket.externalDate && new Date(parseInt(ticket.externalDate))}
                            onChange={(e: Date) => updateDate(e.getTime())}
                            calendarIcon={null}
                            clearIcon={null}
                            format='dd.MM.y'
                        />
                    </div>
                    <div className={_.buttonView} onClick={() => updateDate(parseInt(`${oldDate || ''}`))}>
                        <BsClock />
                    </div>
                    <div className={_.buttonView} onClick={() => updateDate(addDay(ticket.externalDate))}>
                        <AiOutlineArrowUp />
                    </div>
                    <div className={_.buttonView} onClick={() => updateDate(subDay(ticket.externalDate))}>
                        <AiOutlineArrowDown />
                    </div>
                </div>

                <div className={_.selectContainer}>
                    <div className={_.selectLabel}>Исполнитель</div>
                    <div className={_.priority}>
                        <Select
                            className={clsx(_.width_200, _.disableSelect)}
                            options={[defaultExecutor]}
                            value={defaultExecutor}
                            disabled
                        />
                        <div className={_.underTextContainer} style={{ width: 190 }}>
                            <div>Телефон: +7-927-000-6090</div>
                            <div>E-mail: requests@city-gals.com</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={clsx(_.firstRow, _.row)}>
                <div className={_.selectContainer}>
                    <div className={_.selectLabel} style={{ marginLeft: 8 }}>
                        Приоритет
                    </div>
                    <div className={_.priority}>
                        <PrioritiesSelect
                            value={ticket.priority}
                            className={_.width_180}
                            onChange={(e: number) => ticketUpdater('priority', e)}
                        />
                    </div>
                </div>

                <div className={_.selectContainer}>
                    <div className={_.selectLabel}>Отрасль</div>
                    <div className={_.priority}>
                        <BranchesSelect
                            value={ticket.branch}
                            className={_.width_185}
                            onChange={(e: number) => ticketUpdater('branch', e)}
                        />
                    </div>
                </div>

                <div style={{ width: 295 }} />
            </div>

            <div className={_.buttonWrapper}>
                <Button
                    name='Создать'
                    onClick={() => {
                        if (ticket?.details && ticket?.shop && ticket?.priority && ticket?.branch) createTicket()
                        showErrors({ details: ticket?.details, shop: ticket?.shop }, setErrors)
                    }}
                />
            </div>
        </div>
    )
}

export default memo(TicketMainData)
