import localforage from 'localforage'
import {createContext, useContext, useEffect, useState} from 'react'

const initialState = true

const SiderContext = createContext(null)

const useSider = () => {
    const [closed, setClosed] = useContext(SiderContext)

    const updateState = (_state) => {
        setClosed(_state);
        localforage.setItem('filterClosed', _state)
    }

    return {
        closed,
        close: () => updateState(true),
        open: () => updateState(false),
    }
}

const SiderProvider = ({children}) => {
    const state = useState(initialState)

    const [, setState] = state

    useEffect(() => {
        (async function init() {
            // установка полей из localStorage
            const filterClosed = await localforage.getItem('filterClosed')
            if (filterClosed !== undefined) {
                setState(filterClosed)
            }
        })()
    }, [])

    return <SiderContext.Provider value={state}>
        {children}
    </SiderContext.Provider>
}

export {SiderProvider, useSider}
