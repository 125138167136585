import { gql } from "@apollo/client";

export const REGIONS_QUERY = gql`
  query regionsQuery {
    regions {
      id
      name
    }
  }
`;


export const REGION_UPDATE = gql`
  mutation RegionUpdate($region: RegionUpdate!) {
    regionUpdate(region: $region) {
      id
      name
    }
  }
`;


export const REGION_CREATE = gql`
  mutation RegionCreate($region: RegionCreate!) {
    regionCreate(region: $region) {
      id
      name
    }
  }
`;

export const REGION_DELETE = gql`
  mutation RegionDelete($id: Int!) {
    regionDelete(id: $id) {
      id
    }
  }
`;
