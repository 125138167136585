import { useEffect, memo } from 'react'
import clsx from 'clsx'
import { Select } from 'antd'

import { archiveFilterOptions } from './helper'

import Sider from 'components/Sider'
import Button from 'components/Button'
import TextInput from 'components/TextInput'

import _ from './sider.module.css'

export interface IOption {
    value: string
    label: string
}

const SiderFilters = ({
    dictionaries,
    textValue,
    cityFilterValues,
    buildingFilterValues,
    brandFilterValues,
    companiesFilterValues,
    branchFilterValues,
    brandsFromBuildingsIds,
    prioritiesFilterValues,
    statesFilterValues,
    archiveFilterValue,

    setTextValue,
    setCityFilterValues,
    setBuildingFilterValues,
    setBrandFilterValues,
    setCompaniesFilterValues,
    setBranchFilterValues,
    setPrioritiesFilterValues,
    setStatesFilterValues,
    setArchiveFilterValue,
    resetFilters,
    applyFilters,
    closeSider,
}: any) => {
    // отслеживание клика по энтер
    useEffect(() => {
        const enterKey = (e: any) => {
            if (e.keyCode === 13) {
                e.preventDefault()
                applyFilters(e)
            }
            return
        }
        window.addEventListener('keydown', enterKey)

        return () => window.removeEventListener('keydown', enterKey)
    }, [applyFilters])

    const citiesOptions: IOption[] =
        (dictionaries &&
            dictionaries.cities.map((city: any) => ({
                value: city.id,
                label: `${city.name} - ${city.count_tickets}`,
            }))) ||
        []
    const buildingsOptions: IOption[] =
        (dictionaries &&
            dictionaries.buildings
                .filter((building: any) => {
                    // фильтрация ТЦ по выбранным городам, если они есть
                    if (!cityFilterValues.length) return true
                    return cityFilterValues.includes(building.city.id)
                })
                .map((building: any) => ({
                    value: building.id,
                    label: `${building.name} - ${building.city.abbreviation}`,
                }))) ||
        []
    const brandsOptions: IOption[] =
        (dictionaries &&
            dictionaries.brands
                .filter((brand: any) => {
                    // фильтрация брендов по выбранным ТЦ, если они есть
                    if (!brandsFromBuildingsIds.length) return true
                    return brandsFromBuildingsIds.includes(brand.id)
                })
                .map((brand: any) => ({ value: brand.id, label: brand.name }))) ||
        []
    const companiesOptions: IOption[] =
        (dictionaries &&
            dictionaries.companies.map((company: any) => ({
                value: company.id,
                label: company.name,
            }))) ||
        []
    const branchesOptions: IOption[] =
        (dictionaries &&
            dictionaries.branches.map((branch: any) => ({
                value: branch.id,
                label: branch.name,
            }))) ||
        []
    const prioritiesOptions: IOption[] =
        (dictionaries &&
            dictionaries.priorities.map((priority: any) => ({
                value: priority.id,
                label: priority.name,
            }))) ||
        []
    const statesOptions: IOption[] =
        (dictionaries &&
            dictionaries.ticketStates.map((state: any) => ({
                value: state.id,
                label: state.name,
            }))) ||
        []

    return (
        <Sider>
            <div style={{ width: 350 }}>
                <TextInput value={textValue} onChange={setTextValue} placeholder='Поиск по тексту*' />
                {/* <Input value={textValue} onChange={setTextValue} placeholder='Поиск по тексту*' allowClear /> */}

                <div className={_.helpWrapper}>"_" Любой символ, "%" Любое количество символов</div>

                <Select
                    style={{ width: 350 }}
                    value={cityFilterValues}
                    placeholder='Города'
                    options={citiesOptions}
                    onChange={setCityFilterValues}
                    defaultValue={cityFilterValues}
                    onBlur={applyFilters}
                    // isDisabled={buildingFilterValues.length || brandFilterValues.length}
                    mode='multiple'
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    allowClear
                />

                <Select
                    style={{ width: 350 }}
                    value={buildingFilterValues}
                    placeholder='ТЦ'
                    options={buildingsOptions}
                    onChange={setBuildingFilterValues}
                    defaultValue={buildingFilterValues}
                    onBlur={applyFilters}
                    // isDisabled={brandFilterValues.length}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    mode='multiple'
                    allowClear
                />
            </div>

            <div style={{ width: 350, marginLeft: 15 }}>
                <Select
                    style={{ width: 350 }}
                    value={brandFilterValues}
                    placeholder='Бренды'
                    options={brandsOptions}
                    onChange={setBrandFilterValues}
                    defaultValue={brandFilterValues}
                    onBlur={applyFilters}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    mode='multiple'
                    allowClear
                />

                <Select
                    style={{ width: 350 }}
                    value={companiesFilterValues}
                    placeholder='Компании'
                    options={companiesOptions}
                    onChange={setCompaniesFilterValues}
                    defaultValue={companiesFilterValues}
                    onBlur={applyFilters}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    mode='multiple'
                    allowClear
                />

                <Select
                    style={{ width: 350 }}
                    value={branchFilterValues}
                    placeholder='Отрасли'
                    options={branchesOptions}
                    onChange={setBranchFilterValues}
                    defaultValue={branchFilterValues}
                    onBlur={applyFilters}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    mode='multiple'
                    allowClear
                />

                <Select
                    style={{ width: 350 }}
                    value={prioritiesFilterValues}
                    placeholder='Приоритет'
                    options={prioritiesOptions}
                    onChange={setPrioritiesFilterValues}
                    defaultValue={prioritiesFilterValues}
                    onBlur={applyFilters}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    mode='multiple'
                    allowClear
                />
            </div>
            <div style={{ width: 350, marginLeft: 15 }}>
                <Select
                    style={{ width: 350 }}
                    value={statesFilterValues}
                    placeholder='Статус'
                    options={statesOptions}
                    onChange={(data) => {
                        setStatesFilterValues(data)
                    }}
                    defaultValue={statesFilterValues}
                    onBlur={applyFilters}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    mode='multiple'
                    allowClear
                />

                <Select
                    style={{ width: 350 }}
                    value={archiveFilterValue}
                    placeholder='Архивные'
                    options={archiveFilterOptions}
                    onChange={setArchiveFilterValue}
                    defaultValue={archiveFilterValue}
                    onBlur={applyFilters}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                />
                <div className={clsx('flex', 'justify-space-between', _.buttonsContainer)}>
                    <Button name='Применить' onClick={applyFilters} />
                    <Button name='Сброс' onClick={resetFilters} mods={['red']} />
                    <Button name='Закрыть' onClick={closeSider} mods={['blue']} />
                </div>
            </div>
        </Sider>
    )
}

export default memo(SiderFilters)
