import React, { memo, useState, useEffect, useCallback } from 'react'
import localforage from 'localforage'
import { useQuery } from '@apollo/client'

import { useBrandFiltering } from './tickets.hooks'
import { TICKETS_PAGE_DICTIONARIES_QUERY } from 'connectors/gql/ticket.gql'

import { useSider } from 'components/Sider/sider.context'
import SiderFitlers from 'components/Sider/sider-filters'
import { archiveFilterOptions } from 'components/Sider/helper'
import Header from 'components/Header'

import TicketsHeader from './components/TicketsHeader'
import TicketsPage from './TicketsPage'
import TicketsLightPage from './components/ListLight'

const TicketsWrapper = ({ light = false }) => {
    const { data: dictionaries, loading: dictsLoading, error: dictsError } = useQuery(TICKETS_PAGE_DICTIONARIES_QUERY)
    const { close } = useSider()

    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(25)

    // выбираемые динамические textArea
    const [selectedDynamicField1, selectDynamicField1] = useState('supplied_by_client')
    const [selectedDynamicField2, selectDynamicField2] = useState('supplied_by_service')

    const [textValue, setTextValue] = useState('')
    const [cityFilterValues, setCityFilterValues] = useState([])
    const [buildingFilterValues, setBuildingFilterValues] = useState([])
    const [brandFilterValues, setBrandFilterValues] = useState([])
    const [brandsFromBuildingsIds, setBrandsFromBuildingsIds] = useState([])
    const [companiesFilterValues, setCompaniesFilterValues] = useState([])
    const [branchFilterValues, setBranchFilterValues] = useState([])
    const [prioritiesFilterValues, setPrioritiesFilterValues] = useState([])
    const [statesFilterValues, setStatesFilterValues] = useState([])
    const [archiveFilterValue, setArchiveFilterValue] = useState([archiveFilterOptions[0]])
    const [variables, setVariables] = useState({})

    const allFiltersByText = [
        JSON.stringify(cityFilterValues),
        JSON.stringify(buildingFilterValues),
        JSON.stringify(brandFilterValues),
        JSON.stringify(companiesFilterValues),
        JSON.stringify(branchFilterValues),
        JSON.stringify(prioritiesFilterValues),
        JSON.stringify(statesFilterValues),
        JSON.stringify(archiveFilterValue),
        textValue,
    ]

    useEffect(() => {
        ;(async function init() {
            // установка фильтров из localStorage
            const ticketsFilters: any = await localforage.getItem('ticketsFilters')
            const _page: any = await localforage.getItem('page')
            const _perPage: any = await localforage.getItem('perPage')
            if (_page) setPage(_page)
            if (_perPage) setPerPage(_perPage)
            if (ticketsFilters) {
                const parsedTicketsFilters = JSON.parse(ticketsFilters)
                setCityFilterValues(parsedTicketsFilters.cityFilterValues || [])
                setBuildingFilterValues(parsedTicketsFilters.buildingFilterValues || [])
                setBrandFilterValues(parsedTicketsFilters.brandFilterValues || [])
                setCompaniesFilterValues(parsedTicketsFilters.companiesFilterValues || [])
                setBranchFilterValues(parsedTicketsFilters.branchFilterValues || [])
                setPrioritiesFilterValues(parsedTicketsFilters.prioritiesFilterValues || [])
                setStatesFilterValues(parsedTicketsFilters.statesFilterValues || [])
                setArchiveFilterValue(parsedTicketsFilters.archiveFilterValue || archiveFilterOptions[0])
                setTextValue(parsedTicketsFilters.textValue || '')

                setVariables({
                    cities: parsedTicketsFilters.cityFilterValues || [],
                    buildings: parsedTicketsFilters.buildingFilterValues || [],
                    brands: parsedTicketsFilters.brandFilterValues || [],
                    companies: parsedTicketsFilters.companiesFilterValues || [],
                    branches: parsedTicketsFilters.branchFilterValues || [],
                    priorities: parsedTicketsFilters.prioritiesFilterValues || [],
                    ticketStates: parsedTicketsFilters.statesFilterValues || [],
                    ticketsArchive: parsedTicketsFilters.archiveFilterValue?.value || 0,
                    ticketsPhrase: parsedTicketsFilters.textValue || '',
                    page: _page || 1,
                    perPage: _perPage || 25,
                })
            } else {
                setVariables({
                    page: _page || 1,
                    perPage: _perPage || 25,
                })
            }
        })()
    }, [])

    useBrandFiltering(dictionaries, buildingFilterValues, setBrandsFromBuildingsIds)

    useEffect(() => {
        localforage.setItem('page', page)
        setVariables({
            ...variables,
            page,
        })
    }, [page]) // eslint-disable-line

    useEffect(() => {
        localforage.setItem('perPage', perPage)
        localforage.setItem('page', 1)
        setPage(1)
        setVariables({
            ...variables,
            page: 1,
            perPage,
        })
    }, [perPage]) // eslint-disable-line

    const resetFilters = useCallback(
        (event: any) => {
            event.stopPropagation()
            setVariables({
                cities: [],
                buildings: [],
                brands: [],
                companies: [],
                branches: [],
                priorities: [],
                ticketStates: [],
                ticketsPhrase: '',
                page,
                perPage,
            })

            setTextValue('')
            setCityFilterValues([])
            setBuildingFilterValues([])
            setBrandFilterValues([])
            setBrandsFromBuildingsIds([])
            setCompaniesFilterValues([])
            setBranchFilterValues([])
            setPrioritiesFilterValues([])
            setStatesFilterValues([])

            const filterValues = {
                cityFilterValues: [],
                buildingFilterValues: [],
                brandFilterValues: [],
                companiesFilterValues: [],
                branchFilterValues: [],
                prioritiesFilterValues: [],
                statesFilterValues: [],
                textValue: '',
                archiveFilterValue,
            }

            localforage.setItem('ticketsFilters', JSON.stringify(filterValues))
            // eslint-disable-next-line
        },
        [JSON.stringify(archiveFilterValue)],
    )

    const applyFilters = useCallback(
        (event: any) => {
            event.stopPropagation()

            setVariables({
                cities: cityFilterValues,
                buildings: buildingFilterValues,
                brands: brandFilterValues,
                companies: companiesFilterValues,
                branches: branchFilterValues,
                priorities: prioritiesFilterValues,
                ticketStates: statesFilterValues,
                // @ts-ignore
                ticketsArchive: archiveFilterValue?.value || 0,
                ticketsPhrase: textValue,
                page,
                perPage,
            })

            const filterValues = {
                cityFilterValues,
                buildingFilterValues,
                brandFilterValues,
                companiesFilterValues,
                branchFilterValues,
                prioritiesFilterValues,
                statesFilterValues,
                archiveFilterValue,
                textValue,
            }

            localforage.setItem('ticketsFilters', JSON.stringify(filterValues))
            // close()
            // eslint-disable-next-line
        },
        [...allFiltersByText, close],
    )

    const closeSider = useCallback(
        (event: any) => {
            event.stopPropagation()
            close()
        },
        [close],
    )

    return (
        <>
            <Header />
            {!light && (
                <TicketsHeader
                    selectedDynamicField1={selectedDynamicField1}
                    selectedDynamicField2={selectedDynamicField2}
                    selectDynamicField1={selectDynamicField1}
                    selectDynamicField2={selectDynamicField2}
                />
            )}
            {light ? (
                <TicketsLightPage
                    variables={variables}
                    dictsLoading={dictsLoading}
                    dictsError={dictsError}
                    selectedDynamicField1={selectedDynamicField1}
                    selectedDynamicField2={selectedDynamicField2}
                    page={page}
                    perPage={perPage}
                    setPage={setPage}
                    setPerPage={setPerPage}
                />
            ) : (
                <TicketsPage
                    variables={variables}
                    dictsLoading={dictsLoading}
                    dictsError={dictsError}
                    selectedDynamicField1={selectedDynamicField1}
                    selectedDynamicField2={selectedDynamicField2}
                    page={page}
                    perPage={perPage}
                    setPage={setPage}
                    setPerPage={setPerPage}
                />
            )}
            <SiderFitlers
                dictionaries={dictionaries}
                textValue={textValue}
                cityFilterValues={cityFilterValues}
                buildingFilterValues={buildingFilterValues}
                brandFilterValues={brandFilterValues}
                companiesFilterValues={companiesFilterValues}
                branchFilterValues={branchFilterValues}
                brandsFromBuildingsIds={brandsFromBuildingsIds}
                prioritiesFilterValues={prioritiesFilterValues}
                statesFilterValues={statesFilterValues}
                archiveFilterValue={archiveFilterValue}
                setTextValue={setTextValue}
                setCityFilterValues={setCityFilterValues}
                setBuildingFilterValues={setBuildingFilterValues}
                setBrandFilterValues={setBrandFilterValues}
                setCompaniesFilterValues={setCompaniesFilterValues}
                setBranchFilterValues={setBranchFilterValues}
                setPrioritiesFilterValues={setPrioritiesFilterValues}
                setStatesFilterValues={setStatesFilterValues}
                setArchiveFilterValue={setArchiveFilterValue}
                resetFilters={resetFilters}
                applyFilters={applyFilters}
                closeSider={closeSider}
            />
        </>
    )
}

export default memo(TicketsWrapper)
