import React, { memo, useEffect, useState } from 'react'
import clsx from 'clsx'
import { ColorPicker, Modal, Select } from 'antd'
import { Color } from 'antd/es/color-picker'

import Button from 'components/Button'
import Table from 'components/Table'

import { setOptionsFormat, showErrors, inputCheckErrors, selectCheckErrors } from 'pages/EditorPage/helpers'

import _ from '../../CreateModal.module.css'

const CreateCityModal = ({ onClose, onCreate, dataList, open }: any) => {
    const [errors, setErrors] = useState<string[]>([])

    const [name, setName] = useState('')
    const [abbreviation, setAbbreviation] = useState('')
    const [color, setColor] = useState('#ffffff')
    const [region, setRegion] = useState(null)
    const [center, setCenter] = useState(false)
    const [presence, setPresence] = useState(false)

    const [optionsRegions, setOptionsRegions] = useState(dataList.regions ? setOptionsFormat(dataList.regions) : [])

    useEffect(() => {
        if (dataList.regions?.length) setOptionsRegions(setOptionsFormat(dataList.regions))
    }, [JSON.stringify(dataList.regions)]) // eslint-disable-line

    const inputCheckField = inputCheckErrors(setErrors, errors)
    const selectCheckField = selectCheckErrors(setErrors, errors)

    return (
        <Modal title='Добавление города' open={open} onCancel={onClose} footer={null} style={{ width: 400 }}>
            <Table style={{ width: '100%' }}>
                <tr>
                    <td>Имя*</td>
                    <td>
                        <input
                            className={clsx(_.input, errors.includes('name') && _.error)}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onBlur={() => inputCheckField(setName, name, 'name')}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Аббревиатура*</td>
                    <td>
                        <input
                            className={clsx(_.input, errors.includes('abbreviation') && _.error)}
                            value={abbreviation}
                            onChange={(e) => setAbbreviation(e.target.value)}
                            onBlur={() => inputCheckField(setAbbreviation, abbreviation, 'abbreviation')}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Регион*</td>
                    <td>
                        <Select
                            style={{ width: '100%' }}
                            value={region}
                            placeholder='Регион'
                            options={optionsRegions}
                            onChange={setRegion}
                            className={clsx(errors.includes('region') && _.error)}
                            onBlur={() => selectCheckField(region, 'region')}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Присутствие</td>
                    <td>
                        <input type='checkbox' checked={presence} onChange={() => setPresence(!presence)} />
                    </td>
                </tr>
                <tr>
                    <td>Центр</td>
                    <td>
                        <input type='checkbox' checked={center} onChange={() => setCenter(!center)} />
                    </td>
                </tr>
                <tr>
                    <td>Цвет*</td>
                    <td>
                        <ColorPicker value={color} onChangeComplete={(color: Color) => setColor(`#${color.toHex()}`)} />
                    </td>
                </tr>
            </Table>
            <div className={_.btnContainer}>
                <Button name='Отмена' mods={['blue']} onClick={onClose} />
                <Button
                    name='Сохранить'
                    onClick={() => {
                        if (name && abbreviation && color && region)
                            onCreate({
                                name,
                                abbreviation,
                                color,
                                region_id: region,
                                is_center: center,
                                has_presence: presence,
                            })
                        showErrors({ name, abbreviation, region }, setErrors, ['region'])
                    }}
                />
            </div>
        </Modal>
    )
}

export default memo(CreateCityModal)
