import { memo } from 'react'
import clsx from 'clsx'
import { AiOutlineClear } from 'react-icons/ai'
// @ts-ignore
import _get from 'lodash.get'

import FilterCell from '../Cell/FilterCell'
import _ from './row.page.module.css'
import { IEditorColumn } from 'types'

interface IFilterRow {
    columns: IEditorColumn[]
    dataListForField: any
    filterFields: any
    setFilterFields: (data: any) => void
    reset: () => void
}

const FilterRow = ({ columns, dataListForField, filterFields, setFilterFields, reset }: IFilterRow) => {
    return (
        <tr className={clsx(_.filterRow, 'filterRow')}>
            {columns.map((fn: any) => (
                <FilterCell
                    key={`building-${fn.path}`}
                    type={fn.type}
                    placeholder={fn.placeholder}
                    defaultValue={_get(filterFields, `${fn.path}`)}
                    updateHandler={(value: any) => {
                        setFilterFields({ ...filterFields, ...value })
                    }}
                    fieldName={fn.path}
                    list={dataListForField[fn.path] || []}
                />
            ))}
            <td className={_.iconCell}>
                <AiOutlineClear title='Сброс фильтров' className={_.icon} onClick={reset} />
            </td>
        </tr>
    )
}

export default memo(FilterRow)
