import { useMutation } from '@apollo/client'
import { openErrorNotification, openSuccessNotification } from 'components/Notifications'

export const ticketMutationProps = {
    onError: () => openErrorNotification('Ошибка обновления!'),
    onCompleted: () => openSuccessNotification('Успешно обновлено!'),
}

export const useTextMutation = (id: any, query: any, mutationProps: any, val: any, setVal: any) => {
    const [updateMutator] = useMutation(query, mutationProps)
    const update = (event: any) => {
        const trimmedValue = event.target.value.trim()

        if (val !== trimmedValue) {
            updateMutator({ variables: { id, value: trimmedValue } })
            setVal(trimmedValue)
        }
    }

    return update
}
