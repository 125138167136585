import React, { memo, useState, useEffect } from 'react'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import { IoCheckmarkDoneOutline, IoSearchOutline } from 'react-icons/io5'
import { BsClipboard } from 'react-icons/bs'
import { VscPerson } from 'react-icons/vsc'
import { GrUserWorker } from 'react-icons/gr'
import { Select } from 'antd'
import clsx from 'clsx'

import { useQuery } from '@apollo/client'

import { TICKET_STATES_QUERY } from 'connectors/gql/ticket-states.gql'
import { setOptionsFormat } from 'helpers'
import { ISelectOption, ITicketState } from 'types'

import _ from './styles.module.css'

const icons = [
    { id: 1, icon: <BsClipboard /> },
    { id: 2, icon: <VscPerson /> },
    { id: 3, icon: <IoSearchOutline /> },
    { id: 4, icon: '' },
    { id: 5, icon: '' },
    { id: 6, icon: '' },
    { id: 7, icon: '' },
    { id: 8, icon: '' },
    { id: 9, icon: '' },
    { id: 10, icon: '' },
    { id: 11, icon: <GrUserWorker /> },
    { id: 12, icon: <AiOutlineCheck /> },
    { id: 13, icon: '' },
    { id: 14, icon: <IoCheckmarkDoneOutline /> },
    { id: 15, icon: <AiOutlineClose /> },
]

interface ITicketStatesSelect {
    value: number
    onChange: (data: number) => void
    isSmall?: boolean
}

const TicketStatesSelect = ({ value, onChange, isSmall }: ITicketStatesSelect) => {
    const { data } = useQuery(TICKET_STATES_QUERY)

    const [ticketStates, setTicketStates] = useState<ITicketState[]>(data?.ticket || [])
    const [options, setOptions] = useState<ISelectOption[]>(setOptionsFormat(ticketStates) || [])

    useEffect(() => {
        if (data) {
            setTicketStates(data.ticketStates)
            setOptions(setOptionsFormat(data.ticketStates))
        }
    }, [JSON.stringify(data?.ticketStates || {})]) // eslint-disable-line

    return (
        <Select
            className={clsx(_.ticketSelect, isSmall && 'isSmallSelect')}
            value={options.find((o: any) => o.value === value)?.value}
            placeholder={'Статус не выбран'}
            options={options}
            onChange={onChange}
            optionRender={(option) => (
                <div className={_.stateRowWrapper}>
                    <span className={_.selectIcon}>{icons.find((i) => i.id === option.data.value)?.icon}</span>
                    <span className={_.selectLabel}>{option.data.label}</span>
                </div>
            )}
        />
    )
}

export default memo(TicketStatesSelect)
