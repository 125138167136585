import React, { memo } from 'react'
import { useMutation } from '@apollo/client'

import {
    TICKET_UPDATE_ESTIMATE_GROUP,
    TICKET_UPDATE_COMPLETE_JOBS,
    TICKET_UPDATE_DETAILS,
    TICKET_UPDATE_LEFT_IN_SHOP,
    TICKET_UPDATE_SUPPLIED_BY_CLIENT,
    TICKET_UPDATE_SUPPLIED_BY_SERVICE,
    TICKET_UPDATE_TECHNICAL_CHAT,
    TICKET_UPDATE_TECHNICAL_EVENT_ARCHIVE,
    TICKET_UPDATE_TECHNICAL_REMARK,
    TICKET_UPDATE_TECHNICAL_SPEND,
    TICKET_UPDATE_UNCOMPLETE_JOBS,
} from 'connectors/gql/ticket.gql'

import _ from './styles.module.css'
import Cell from '../Cell'
import InfoCell from '../InfoCell'

const Row = ({
    ticket,
    states,
    estimateGroups,
    selectedDynamicField1,
    selectedDynamicField2,
    delimiter,
    setPin,
    pinnedList,
    brandColor,
    cityColor,
    buildingColor,
}: any) => {
    const [estimateMutator] = useMutation(TICKET_UPDATE_ESTIMATE_GROUP)
    const setEstimate = (event: any) =>
        estimateMutator({
            variables: { id: ticket.id, value: parseInt(event.target.value, 10) },
        })

    const [updateDetailsMutator] = useMutation(TICKET_UPDATE_DETAILS)
    const updateDetails = (id: number) => (value: string) => updateDetailsMutator({ variables: { id, value } })

    const [updateCompleteJobsMutator] = useMutation(TICKET_UPDATE_COMPLETE_JOBS)
    const updateCompleteJobs = (id: number) => (value: string) =>
        updateCompleteJobsMutator({ variables: { id, value } })

    const [updateTechnicalRemarkMutator] = useMutation(TICKET_UPDATE_TECHNICAL_REMARK)
    const updateTechnicalRemark = (id: number) => (value: string) =>
        updateTechnicalRemarkMutator({ variables: { id, value } })

    const [updateTechnicalSpendMutator] = useMutation(TICKET_UPDATE_TECHNICAL_SPEND)
    const updateTechnicalSpend = (id: number) => (value: string) =>
        updateTechnicalSpendMutator({ variables: { id, value } })

    const [updateTechnicalChatMutator] = useMutation(TICKET_UPDATE_TECHNICAL_CHAT)
    const updateTechnicalChat = (id: number) => (value: string) =>
        updateTechnicalChatMutator({ variables: { id, value } })

    const [updateSuppliedByServiceMutator] = useMutation(TICKET_UPDATE_SUPPLIED_BY_SERVICE)
    const updateSuppliedByService = (id: number) => (value: string) =>
        updateSuppliedByServiceMutator({ variables: { id, value } })

    const [updateSuppliedByClientMutator] = useMutation(TICKET_UPDATE_SUPPLIED_BY_CLIENT)
    const updateSuppliedByClient = (id: number) => (value: string) =>
        updateSuppliedByClientMutator({ variables: { id, value } })

    const [updateLeftInShopMutator] = useMutation(TICKET_UPDATE_LEFT_IN_SHOP)
    const updateLeftInShop = (id: number) => (value: string) => updateLeftInShopMutator({ variables: { id, value } })

    const [updateUncompletejobsMutator] = useMutation(TICKET_UPDATE_UNCOMPLETE_JOBS)
    const updateUncompletejobs = (id: number) => (value: string) =>
        updateUncompletejobsMutator({ variables: { id, value } })

    const [updateTechnicalEventArchiveMutator] = useMutation(TICKET_UPDATE_TECHNICAL_EVENT_ARCHIVE)
    const updateTechnicalEventArchive = (id: number) => (value: string) =>
        updateTechnicalEventArchiveMutator({ variables: { id, value } })

    const BrandCell = ({ name, color }: any) => {
        return (
            <td style={{ background: color }} className={_.brandCell}>
                <div className={_.brandCellName}>{name}</div>
            </td>
        )
    }

    return (
        <>
            <tr key={ticket.id} style={{ background: cityColor }}>
                <InfoCell
                    states={states}
                    ticket={ticket}
                    setPin={setPin}
                    pinnedList={pinnedList}
                    buildingColor={buildingColor}
                />

                <BrandCell name={ticket.shop.brand.name} color={brandColor} />

                <Cell fieldName='details' updateHandler={updateDetails(ticket.id)} defaultValue={ticket.details}>
                    <select className={_.estimateGroupSelect} value={ticket.estimate_group?.id} onChange={setEstimate}>
                        {estimateGroups.map((group: any) => (
                            <option key={group.id} value={group.id}>
                                {group.name}
                            </option>
                        ))}
                    </select>
                </Cell>

                <Cell
                    fieldName='complete_jobs'
                    updateHandler={updateCompleteJobs(ticket.id)}
                    defaultValue={ticket.complete_jobs}
                />

                <BrandCell name={ticket.shop.brand.name} color={brandColor} />

                <Cell
                    fieldName='technical_remark'
                    updateHandler={updateTechnicalRemark(ticket.id)}
                    defaultValue={ticket.technical_remark}
                    important
                />

                <Cell
                    fieldName='technical_spend'
                    updateHandler={updateTechnicalSpend(ticket.id)}
                    defaultValue={ticket.technical_spend}
                    important
                />

                <BrandCell name={ticket.shop.brand.name} color={brandColor} />

                {/*/!* dynamic field 1 *!/*/}
                {selectedDynamicField1 === 'supplied_by_client' && (
                    <Cell
                        fieldName='supplied_by_client'
                        updateHandler={updateSuppliedByClient(ticket.id)}
                        defaultValue={ticket.supplied_by_client}
                    />
                )}

                {selectedDynamicField1 === 'supplied_by_service' && (
                    <Cell
                        fieldName='supplied_by_service'
                        updateHandler={updateSuppliedByService(ticket.id)}
                        defaultValue={ticket.supplied_by_service}
                    />
                )}

                {selectedDynamicField1 === 'left_in_shop' && (
                    <Cell
                        fieldName='left_in_shop'
                        updateHandler={updateLeftInShop(ticket.id)}
                        defaultValue={ticket.left_in_shop}
                    />
                )}

                {selectedDynamicField1 === 'uncomplete_jobs' && (
                    <Cell
                        fieldName='uncomplete_jobs'
                        updateHandler={updateUncompletejobs(ticket.id)}
                        defaultValue={ticket.uncomplete_jobs}
                    />
                )}

                {selectedDynamicField1 === 'technical_event_archive' && (
                    <Cell
                        fieldName='technical_event_archive'
                        updateHandler={updateTechnicalEventArchive(ticket.id)}
                        defaultValue={ticket.technical_event_archive}
                    />
                )}

                {selectedDynamicField1 === 'technical_chat' && (
                    <Cell
                        fieldName='technical_chat'
                        updateHandler={updateTechnicalChat(ticket.id)}
                        defaultValue={ticket.technical_chat}
                    />
                )}
                {/*/!* dynamic field 1 end *!/*/}

                {/*/!* dynamic field 2 *!/*/}
                {selectedDynamicField2 === 'supplied_by_client' && (
                    <Cell
                        fieldName='supplied_by_client'
                        updateHandler={updateSuppliedByClient(ticket.id)}
                        defaultValue={ticket.supplied_by_client}
                    />
                )}

                {selectedDynamicField2 === 'supplied_by_service' && (
                    <Cell
                        fieldName='supplied_by_service'
                        updateHandler={updateSuppliedByService(ticket.id)}
                        defaultValue={ticket.supplied_by_service}
                    />
                )}

                {selectedDynamicField2 === 'left_in_shop' && (
                    <Cell
                        fieldName='left_in_shop'
                        updateHandler={updateLeftInShop(ticket.id)}
                        defaultValue={ticket.left_in_shop}
                    />
                )}

                {selectedDynamicField2 === 'uncomplete_jobs' && (
                    <Cell
                        fieldName='uncomplete_jobs'
                        updateHandler={updateUncompletejobs(ticket.id)}
                        defaultValue={ticket.uncomplete_jobs}
                    />
                )}

                {selectedDynamicField2 === 'technical_event_archive' && (
                    <Cell
                        fieldName='technical_event_archive'
                        updateHandler={updateTechnicalEventArchive(ticket.id)}
                        defaultValue={ticket.technical_event_archive}
                    />
                )}

                {selectedDynamicField2 === 'technical_chat' && (
                    <Cell
                        fieldName='technical_chat'
                        updateHandler={updateTechnicalChat(ticket.id)}
                        defaultValue={ticket.technical_chat}
                    />
                )}
                {/* dynamic field 2 end */}
            </tr>
            {delimiter && (
                <tr>
                    <td colSpan={7}>
                        <hr className={_.delimiter} />
                    </td>
                </tr>
            )}
        </>
    )
}

export default memo(Row)
