import { memo } from 'react'
import { useLocation, Link } from 'react-router-dom'
import clsx from 'clsx'

import _ from './styles.module.css'

const groupList = [
    { name: 'Реестр-про', path: '/' },
    { name: 'Реестр-про_V2', path: '/ticketsNew' },
    { name: 'Реестр', path: '/tickets' },
    { name: 'Черновики', path: '/editor/drafts' },
    { name: 'Регионы', path: '/editor/regions' },
    { name: 'Компании', path: '/editor/companies' },
    { name: 'Бренды', path: '/editor/brands' },
    { name: 'Города', path: '/editor/cities' },
    { name: 'ТЦ', path: '/editor/buildings' },
    { name: 'Магазины', path: '/editor/shops' },
    { name: 'Сметные группы', path: '/editor/estimate-groups' },
]

const MenuGroup = () => {
    const { pathname } = useLocation()

    return (
        <div className={_.wrapper}>
            {groupList.map((gr) => (
                <Link key={gr.path} to={gr.path} className={clsx(_.editorBtn, pathname === gr.path && _.active)}>
                    {gr.name}
                </Link>
            ))}
        </div>
    )
}

export default memo(MenuGroup)
