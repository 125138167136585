import React, { memo, useState } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { Link } from 'react-router-dom'
// import Modal from 'app/component/modal/modal'
import clsx from 'clsx'
import { Modal } from 'antd'

import Button from 'components/Button'
import MenuGroup from 'components/MenuGroup'
// import CreateTicketModal from 'app/page/tickets/create-ticket/create-ticket.page'
import CreateTicketModal from 'pages/TicketsPage/components/CreateModal'

import _ from './styles.module.css'

const Header = () => {
    const { keycloak } = useKeycloak()
    const family_name = keycloak?.tokenParsed?.family_name || ''
    const given_name = keycloak?.tokenParsed?.given_name || ''
    const [showCreateModal, setShowCreateModal] = useState(false) // отображение модалки создания заявки
    const [showMessageModal, setShowMessageModal] = useState(false) // отображение модалки предупреждения о закрытии
    const [createModalIsEmpty, setCreateModalIsEmpty] = useState(true) // флаг наличия изменений в модалке создания

    return (
        <>
            <div className={_.container}>
                <div className={clsx(_.text, _.title)}>
                    <Link to={'/'} className={_.link}>
                        CITY-GALS: Башня координации
                    </Link>
                </div>
                <div className='flex align-center'>
                    <MenuGroup />
                    <Button name='Создать заявку' onClick={() => setShowCreateModal(!showCreateModal)} />
                </div>
                <div className={clsx(_.text, _.horizontalSpacer)} />
                <div className={_.text} style={{ marginRight: '0.5em' }}>
                    <Link to={'/cabinet'} className={_.link}>
                        {given_name} {family_name}
                    </Link>
                </div>
            </div>
            <div className={_.spacer} />

            <CreateTicketModal
                open={showCreateModal}
                closeModal={() => {
                    createModalIsEmpty ? setShowCreateModal(false) : setShowMessageModal(true)
                }}
                setCreateModalIsEmpty={setCreateModalIsEmpty}
            />

            <Modal
                width={300}
                title='Данные будут утеряны. Продолжить?'
                open={showMessageModal}
                onCancel={() => setShowMessageModal(false)}
                onOk={() => {
                    setShowCreateModal(false)
                    setShowMessageModal(false)
                }}
                footer={
                    <div>
                        <div className='flex justify-space-between'>
                            <Button
                                name='Закрыть'
                                onClick={() => {
                                    setShowCreateModal(false)
                                    setShowMessageModal(false)
                                }}
                                mods={['red']}
                            />
                            <Button name='Отмена' onClick={() => setShowMessageModal(false)} mods={['blue']} />
                        </div>
                    </div>
                }
            />
        </>
    )
}

export default memo(Header)
