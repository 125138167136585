import { gql } from "@apollo/client";

export const WARES_QUERY = gql`
  query Ware {
    wares {
      id
      name
      default_unit {
        id
        name
      }
    }
  }
`;
