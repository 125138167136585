import React, { memo, useState } from 'react'

import { BRANDS_QUERY, BRAND_CREATE, BRAND_UPDATE, BRAND_DELETE } from 'connectors/gql/brands.gql'
import { COMPANIES_QUERY } from 'connectors/gql/companies.gql'

import Button from 'components/Button'
import Loader from 'components/Loader'
import Table from 'components/Table'
import Row from '../../components/Row'
import FilterRow from '../../components/Row/FilterRow'
import CreateBrandModal from './CreateModal'

import { columns, defaultSorts } from './columns'
import { sendXLSX } from 'pages/EditorPage/helpers'
import { useEntity, useSortAndFilter, useCreate, useUpdate, useDelete } from 'pages/EditorPage/hooks'

import _ from '../styles.module.css'

const filters = {}
// @ts-ignore
columns.forEach((c) => (filters[c.path] = null))

const Brands = () => {
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false)

    const [companies, companiesLoading] = useEntity(COMPANIES_QUERY, 'companies')
    const [brands, brandsLoading, refetchBrands] = useEntity(BRANDS_QUERY, 'brands')

    const {
        filterFields,
        setFilterFields,
        sortedEntity: sortedBrands,
        headerClick,
        headerSortsArrows,
        resetSort,
        resetFilters,
    } = useSortAndFilter(filters, brands, columns, 'brands', defaultSorts)

    const { onCreate, loading: createBrandLoading } = useCreate(
        BRAND_CREATE,
        BRANDS_QUERY,
        'brandsQuery',
        'brand',
        setIsModalCreateOpen,
    )

    const updateAction = useUpdate(BRAND_UPDATE, 'brand')
    const deleteAction = useDelete(BRAND_DELETE, BRANDS_QUERY, 'brandsQuery', 'brandDelete')

    const _updateAction = (id: number) => async (value: number) => {
        await updateAction(id)(value)
        await refetchBrands()
    }

    return (
        <div className={_.wrapper}>
            <div className={_.title}>Редактор брендов</div>

            <div className={_.addBtn}>
                <Button name='Добавить' onClick={() => setIsModalCreateOpen(true)} />
                <Button
                    name='Выгрузить в XLSX'
                    mods={['blue']}
                    onClick={() => sendXLSX(sortedBrands, columns, 'brands')}
                />
            </div>

            <div className={_.tableWrapper}>
                <Table
                    headers={columns}
                    headerClick={headerClick}
                    sorts={headerSortsArrows}
                    resetSort={resetSort}
                    showBorder
                    isSticky
                >
                    <FilterRow
                        columns={columns}
                        filterFields={filterFields}
                        setFilterFields={setFilterFields}
                        dataListForField={{
                            company: companies,
                        }}
                        reset={resetFilters}
                    />
                    {sortedBrands.map((b: any) => (
                        <Row
                            key={b.id}
                            {...b}
                            columns={columns}
                            dataListForField={{
                                company: companies,
                            }}
                            updateAction={_updateAction}
                            deleteAction={deleteAction}
                            canDelete
                        />
                    ))}
                </Table>
            </div>

            <CreateBrandModal
                open={isModalCreateOpen}
                onClose={() => setIsModalCreateOpen(false)}
                onCreate={onCreate}
                dataList={{
                    companies,
                }}
            />

            {(companiesLoading || createBrandLoading || brandsLoading) && <Loader />}
        </div>
    )
}

export default memo(Brands)
