import { IEditorColumn } from 'types'

export const defaultSorts = [
    { path: 'name', type: 'input', sort: 'asc' },
    { path: 'city', type: 'select', sort: 'asc' },
]

export const columns: IEditorColumn[] = [
    {
        name: 'ID',
        path: 'id',
        type: 'id',
        width: '80px',
    },
    {
        name: 'Магазин',
        path: 'shop',
        type: 'select',
        placeholder: 'Выберите магазин',
        width: '275px',
    },
    {
        name: 'Тема',
        path: 'topic',
        type: 'input',
        width: '275px',
    },
    {
        name: 'Содержание',
        path: 'details',
        width: '100%',
        minWidth: '250px',
        type: 'textarea',
    },
    {
        name: 'Дата создания',
        path: 'created',
        type: 'textDate',
        width: '80px',
    },
    {
        name: 'Дата изменения',
        path: 'updated',
        type: 'textDate',
        width: '80px',
    },
    {
        name: 'Приоритет',
        path: 'priority',
        type: 'select',
        width: '180px',
        placeholder: 'Установите приоритет',
    },
]
