import React, { memo } from 'react'

import _ from './styles.module.css'

const variants = {
    supplied_by_client: 'Поставлено клиентом',
    supplied_by_service: 'Поставлено сервисом',
    left_in_shop: 'Оставлено на объекте',
    uncomplete_jobs: 'Невыполненные работы',
    technical_event_archive: 'Технический архив событий',
    technical_chat: 'Технический чат',
}

const DynamicFieldSelector = ({ value, handler }: any) => (
    <select className={_.dynamicSelector} value={value} onChange={(e) => handler(e.target.value)}>
        {Object.entries(variants).map(([k, v]) => (
            <option key={k} value={k}>
                {v}
            </option>
        ))}
    </select>
)

export default memo(DynamicFieldSelector)
