import { memo } from 'react'

import FileUploader from 'components/FileUploader'

import _ from './ticket-attach-data.module.css'

interface ITicketAttachDataProps {
    addedFile: any
    setAddedFile: any
}

const TicketAttachData = ({ addedFile, setAddedFile }: ITicketAttachDataProps) => {
    return (
        <div className={_.wrapper}>
            <div className={_.header}>Вложение</div>

            <FileUploader addFile={setAddedFile} addedFile={addedFile} setAddedFile={setAddedFile} fullWidth />
        </div>
    )
}

export default memo(TicketAttachData)
