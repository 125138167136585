import React, { memo } from 'react'

import _ from './styles.module.css'

interface ILoaderProps {
    isSmall?: boolean
}

const Loader = ({ isSmall }: ILoaderProps) => {
    if (isSmall)
        return (
            <div className={_.small}>
                <div className={_.ldsDualRing} />
            </div>
        )

    return (
        <div className={_.loaderWrapper}>
            <div className={_.ldsDualRing} />
        </div>
    )
}

export default memo(Loader)
