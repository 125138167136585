import axios from 'axios';
import 'whatwg-fetch';
import qs from 'qs';
import { openErrorNotification } from 'components/Notifications';

let instance = axios.create({
  baseURL: '/',
});

function setCookie(name, value, options = {}) {

  options = {
    path: '/',
    // при необходимости добавьте другие значения по умолчанию
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

// Пример использования:

export const setAxiosHeader = (token) => {
  if (token) {
    setCookie('auth', token);

    instance = axios.create({
      baseURL: '/',
      headers: {
        'Authorization': token,
      }
    });
  }
}


const axiosApi = async () => {
  return instance;
};

export const APIService = {
  async checkStatus401(status) {
    if (status === 401) {
      console.error('401');
    }
  },

  async checkStatus403(status) {
    if (status === 403) {
      console.error('403');
    }
  },

  checkStatus500(status) {
    if (status === 500) {
      console.error('500');
    }
  },

  async GET(route, params, excel = false, withHeaders = false) {
    try {
      const API = await axiosApi(excel);
      const response = await API.get(route, {
        params,
        //  TODO: метод делает из массива строку
        paramsSerializer: function (params) {
          const test = qs.stringify(params, { arrayFormat: 'comma' });
          return test;
        },
      });
      const { data, headers } = response;

      if (withHeaders) return response;
      return excel
        ? {
            fileData: data,
            nameFromHeader: headers['content-disposition'],
            mimeFromHeader: headers['content-type'],
          }
        : data;
    } catch ({ response: { status } }) {
      this.checkStatus401(status);
      this.checkStatus403(status);
      this.checkStatus500(status);
    }
  },

  async PUT(route, params) {
    try {
      const API = await axiosApi();
      const { data } = await API.put(route, params);
      return data;
    } catch ({ response: { status, data } }) {
      openErrorNotification('Ошибка!')
      this.checkStatus401(status);
      this.checkStatus403(status);
      this.checkStatus500(status);
      throw { data, status }; // eslint-disable-line
    }
  },

  async POST(route, params) {
    try {
      const API = await axiosApi();
      const { data } = await API.post(route, params);
      return data;
    } catch ({ response: { status, data } }) {
      openErrorNotification('Ошибка!')
      this.checkStatus401(status);
      this.checkStatus403(status);
      this.checkStatus500(status);
      throw { data, status }; // eslint-disable-line
    }
  },
};
