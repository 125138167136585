import { gql } from "@apollo/client";

export const UNITS_QUERY = gql`
  query Units {
    units {
      id
      name
    }
  }
`;
