import { IEditorColumn } from 'types'

export const defaultSorts = [
    { path: 'brand', type: 'select', sort: 'asc' },
    { path: 'building', type: 'select', sort: 'asc' },
    { path: 'building.city.name', type: 'input', sort: 'asc' },
    { path: 'state', type: 'select', sort: 'asc' },
]

export const backendFilterFieldsNames = {
    id: 'shop.id',
    code: 'shop.code',
    email: 'shop.email',
    phone: 'shop.phone',
    phone2: 'shop.phone2',
    area: 'shop.area',
    opened: 'shop.opened',
    serve_start: 'shop.serve_start',
    serve_end: 'shop.serve_end',
    closed: 'shop.closed',
    lastEvent: 'lastDate',
    director: 'shop.director',
    remark: 'shop.remark',
    'building.city.name': 'city.name',
    'brand.company.name': 'company.name',
    brand: 'brand.id',
    building: 'building.id',
    state: 'shop.state_id',
    chronology: 'shop.chronology',
    store: 'store',
    storeShort: 'storeShort',
}

export const backendSortFieldsNames = {
    ...backendFilterFieldsNames,
    brand: 'brand.name',
    building: 'building.name',
}

export const columns: IEditorColumn[] = [
    {
        name: 'ID',
        path: 'id',
        type: 'id',
        width: '30px',
    },
    {
        name: 'Город',
        path: 'building.city.name',
        type: 'text',
        width: '200px',
    },
    {
        name: 'ТЦ',
        path: 'building',
        type: 'select',
        width: '275px',
        placeholder: 'Выберите ТЦ',
    },
    {
        name: 'Бренд',
        path: 'brand',
        type: 'select',
        width: '275px',
        placeholder: 'Выберите бренд',
    },
    {
        name: 'Код',
        path: 'code',
        type: 'input',
        width: '100px',
    },
    {
        name: 'Компания',
        path: 'brand.company.name',
        type: 'text',
        width: '150px',
    },
    {
        name: 'Объект',
        path: 'store',
        type: 'text',
        width: '270px',
    },
    {
        name: 'Объект сокр.',
        path: 'storeShort',
        type: 'text',
        width: '200px',
    },
    {
        name: 'Адрес',
        path: 'building.address',
        type: 'text',
        width: '200px',
    },
    {
        name: 'Статус',
        path: 'state',
        type: 'select',
        width: '275px',
        placeholder: 'Выберите статус',
    },
    {
        name: 'Площ.',
        path: 'area',
        type: 'number',
        width: '55px',
    },
    {
        name: 'Открыт',
        path: 'opened',
        type: 'date',
        width: '80px',
    },
    {
        name: 'Начало обсл.',
        path: 'serve_start',
        type: 'date',
        width: '80px',
    },
    {
        name: 'Конец обсл.',
        path: 'serve_end',
        type: 'date',
        width: '80px',
    },
    {
        name: 'Закрыт',
        path: 'closed',
        type: 'date',
        width: '80px',
    },
    {
        name: 'Посл. соб.',
        path: 'lastEvent',
        type: 'date',
        width: '100px',
        mods: ['readOnly'],
    },
    {
        name: 'Email',
        path: 'email',
        type: 'input',
        width: '275px',
    },
    {
        name: 'Телефон 1',
        path: 'phone',
        type: 'input',
        width: '200px',
    },
    {
        name: 'Телефон 2',
        path: 'phone2',
        type: 'input',
        width: '200px',
    },
    {
        name: 'Директор',
        path: 'director',
        type: 'input',
        width: '275px',
    },
    {
        name: 'Заметка',
        path: 'remark',
        type: 'textarea',
        width: '275px',
    },
    {
        name: 'Хронология',
        path: 'chronology',
        type: 'textarea',
        width: '275px',
    },
]
