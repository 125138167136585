import React, { memo, useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'antd'

import { SHOPS_QUERY } from 'connectors/gql/shops.gql'
import { ISelectOption, IShop } from 'types'

const setShopOptionsFormat = (list: any, cityFirst: any) => {
    if (!list.length) return []
    const _list = list.map((l: any) => {
        const label = cityFirst
            ? `${l.building.city.name} ТЦ ${l.building.name} - ${l.brand.name} ${l.code}`
            : `${l.brand.name} ${l.code} ТЦ ${l.building.name} - ${l.building.city.name}`
        return {
            value: l.id,
            label,
            disabled: l.state.id === '4' || l.state.id === '3',
        }
    })

    return _list
}

const stateSorter = [{ field: 'shop.state_id', direction: 'asc' }]
const cityFirstSorter = [
    { field: 'city.name', direction: 'asc' },
    { field: 'building.name', direction: 'asc' },
    { field: 'brand.name', direction: 'asc' },
]

interface IShopsSelect {
    value: number
    onChange: (data: number) => void
    style?: React.CSSProperties
    className?: string
    cityFirst?: boolean
    sortByState?: boolean
    decorateDisabled?: boolean
}

const ShopsSelect = ({ value, onChange, cityFirst, sortByState, decorateDisabled, ...props }: IShopsSelect) => {
    const [sorting, setSorting] = useState([])
    const { data } = useQuery(SHOPS_QUERY, { variables: { page: 1, perPage: 9999, sorting, filters: [] } })

    const [shops, setShops] = useState<IShop[]>(data?.ticket || [])
    const [options, setOptions] = useState<ISelectOption[]>(setShopOptionsFormat(shops, cityFirst) || [])

    useEffect(() => {
        let _sorting: any = []
        if (sortByState) _sorting = [...stateSorter]
        if (cityFirst) _sorting = [..._sorting, ...cityFirstSorter]
        setSorting(_sorting)
    }, [cityFirst, sortByState])

    useEffect(() => {
        if (data) {
            setShops(data.shops)
            setOptions(setShopOptionsFormat(data.shops, cityFirst))
        }
    }, [JSON.stringify(data?.shops || {}), cityFirst]) // eslint-disable-line

    return (
        <Select
            {...props}
            value={options.find((o: any) => o.value === value)?.value || null}
            placeholder={'Объект не выбран'}
            options={options}
            onChange={onChange}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
            showSearch
        />
    )
}

export default memo(ShopsSelect)
