import { memo, useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'antd'

import { UNITS_QUERY } from 'connectors/gql/units.gql'
import { setOptionsFormat } from 'helpers'
import { ISelectOption, IUnit } from 'types'

interface IUnitsSelect {
    value: number
    onChange: (data: number) => void
    className?: string
}

const UnitsSelect = ({ value, onChange, ...props }: IUnitsSelect) => {
    const { data } = useQuery(UNITS_QUERY)

    const [units, setUnits] = useState<IUnit[]>(data?.ticket || [])
    const [options, setOptions] = useState<ISelectOption[]>(setOptionsFormat(units) || [])

    useEffect(() => {
        if (data) {
            setUnits(data.units)
            setOptions(setOptionsFormat(data.units))
        }
    }, [JSON.stringify(data?.units || {})]) // eslint-disable-line

    return <Select value={value} placeholder={'Единица не выбрана'} options={options} onChange={onChange} {...props} />
}

export default memo(UnitsSelect)
