import FileSaver from 'file-saver'
import { APIService } from '../api/rest';
import { openErrorNotification } from 'components/Notifications';

// export const preparedSRC = ({ blob }) => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     let urlBase64 = null;

//     reader.readAsDataURL(blob);
//     reader.onloadend = function () {
//       urlBase64 = reader.result;
//       resolve(urlBase64);
//     };
//   });
// };


export const Files = {
  async getPing() {
    try {
      return await APIService.GET(`/ping`, null, true);
    } catch (e) {
      return e.response.data;
    }
  },

  async getFile(fileId) {
    try {
      const response = await APIService.GET(`/rest/file/${fileId}`, null, true);
      const filename = response.nameFromHeader.match(/filename="(.+)"/)[1];

      // TODO: fileSaver нормально сохраняет только txt
      const { fileData, mimeFromHeader } = response;
      const blob = await new Blob([fileData], { type: mimeFromHeader });
      FileSaver.saveAs(blob, filename)

    } catch (e) {
      openErrorNotification('Ошибка загрузки файла')
      throw e
    }
  },

  async postFile(ticketId, formData) {
    try {
      return await APIService.POST(`/rest/file?ticketId=${ticketId}`, formData);
    } catch (e) {
      openErrorNotification('Ошибка загрузки файла')
      throw e
    }
  },
};
