import React, { memo } from 'react'
import clsx from 'clsx'

import _ from './styles.module.css'

// interface ITextInput {}

const TextInput = ({ value, onChange, placeholder = '' }: any) => (
    <div className={clsx('flex', _.container)}>
        <input
            type='text'
            className={clsx(_.input)}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
        />
        <div className={clsx(_.remove)} onClick={() => onChange('')}>
            X
        </div>
    </div>
)

export default memo(TextInput)
