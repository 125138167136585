import React, { memo, useState } from 'react'
import { IoIosRemoveCircleOutline } from 'react-icons/io'
import { Modal } from 'antd'
// @ts-ignore
import _get from 'lodash.get'

import { TICKET_EQUPMENT_DELETE, TICKET_QUERY, TICKET_EQUIPMENT_UPDATE } from 'connectors/gql/ticket.gql'

import { useDelete, useUpdate } from 'helpers/hooks/editor'
import Button from 'components/Button'
import Cell from './Cell'

import _ from './table.module.css'

const TicketTable = ({ tableColumns, tableData, dataListForSelects, ticketRefetch }: any) => {
    const [showRemoveRowModal, setShowRemoveRowModal] = useState<any>(null)

    const updateAction = useUpdate(TICKET_EQUIPMENT_UPDATE, 'equipment')

    const deleteAction = useDelete(TICKET_EQUPMENT_DELETE, TICKET_QUERY, 'ticket', 'equipmentDelete')
    const deleteHandler = (id: any) => {
        deleteAction(id).then(() => setShowRemoveRowModal(null))
        ticketRefetch()
    }
    return (
        <table className={_.table}>
            <thead className={_.thead}>
                <tr>
                    {tableColumns.map((column: any) => (
                        <td title={column.title || ''} style={{ width: column.width, textAlign: column.textAlign }}>
                            {column.name}
                        </td>
                    ))}
                </tr>
            </thead>
            <tbody className={_.tbody}>
                {tableData.map((row: any) => (
                    <tr>
                        {tableColumns.map((column: any) => (
                            <Cell
                                key={`cell-${column.path}-${row.id}`}
                                type={column.type}
                                placeholder={column.placeholder || ''}
                                defaultValue={_get(
                                    row,
                                    column.type === 'select' ? `${column.path}.id` : `${column.path}`,
                                )}
                                updateHandler={updateAction(row.id)}
                                fieldName={column.path}
                                list={dataListForSelects[column.path] || []}
                            />
                        ))}
                        <IoIosRemoveCircleOutline
                            className={_.removeRow}
                            onClick={() => setShowRemoveRowModal(row.id)}
                        />
                        <Modal
                            title='Точно удалить?'
                            open={showRemoveRowModal && row.id === showRemoveRowModal}
                            style={{ width: 220 }}
                            footer={null}
                        >
                            <div className={_.buttonsWrapper}>
                                <Button name='Да' onClick={() => deleteHandler(row.id)} mods={['red']} />
                                <Button name='Нет' onClick={() => setShowRemoveRowModal(false)} mods={['blue']} />
                            </div>
                        </Modal>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default memo(TicketTable)
