import { IEditorColumn } from 'types'

export const defaultSorts = [
    { path: 'name', type: 'input', sort: 'asc' },
    { path: 'city', type: 'select', sort: 'asc' },
]

export const columns: IEditorColumn[] = [
    {
        name: 'ID',
        path: 'id',
        type: 'id',
        width: '20px',
    },
    {
        name: 'Имя',
        path: 'name',
        type: 'input',
        width: '175px',
    },
    {
        name: 'Адрес',
        path: 'address',
        type: 'input',
        width: '275px',
    },
    {
        name: 'Город',
        path: 'city',
        type: 'select',
        width: '185px',
        placeholder: 'Выберите город',
    },
    {
        name: 'Цвет',
        path: 'color',
        type: 'color',
        width: '70px',
    },
]
