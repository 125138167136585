import { memo } from 'react'
import clsx from 'clsx'

import _ from './styles.module.css'

interface IButtonProps {
    name: string
    onClick: () => void
    mods?: string[]
}

const Button = ({ name, onClick, mods = [] }: IButtonProps) => {
    return (
        <button
            className={clsx(
                _.button,
                mods.map((m) => _[m]),
            )}
            onClick={onClick}
        >
            <div className={clsx(_.buttonText)}>{name}</div>
        </button>
    )
}

export default memo(Button)
