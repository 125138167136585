import React, { memo } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { AiOutlineClose } from 'react-icons/ai'
import clsx from 'clsx'

import _ from './styles.module.css'

interface IFileUploadChildren {
    addedFile: any
    setAddedFile: any
    fullWidth: any
    ticketPage: any
}

const FileUploadChildren = ({ addedFile, setAddedFile, fullWidth, ticketPage }: IFileUploadChildren) => {
    return (
        <div
            className={clsx(_.childrenWrapper, fullWidth && _.fullWidthChildren, ticketPage && _.ticketPageChildrenMod)}
        >
            {addedFile ? (
                <div
                    className={_.fileWrapper}
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                    }}
                >
                    <div key={addedFile.name} className={_.fileRow}>
                        <div>{addedFile.name}</div>
                        <div className={_.removeIcon} onClick={(e) => setAddedFile(null)}>
                            <AiOutlineClose />
                        </div>
                    </div>
                </div>
            ) : (
                <div className={_.label}>Кликните или перенесите файл для загрузки</div>
            )}
        </div>
    )
}

const FileUploadComponent = ({ addFile, addedFile, setAddedFile, fullWidth, ticketPage }: any) => (
    <div className={clsx(_.mainWrapper, fullWidth && _.fullWidthWrapper, ticketPage && _.ticketPageMod)}>
        <FileUploader
            handleChange={addFile}
            name='file'
            children={
                <FileUploadChildren
                    addedFile={addedFile}
                    setAddedFile={setAddedFile}
                    fullWidth={fullWidth}
                    ticketPage={ticketPage}
                />
            }
            disabled={!!addedFile}
        />
    </div>
)

export default memo(FileUploadComponent)
