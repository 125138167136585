import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout, Menu, theme } from 'antd'
import localforage from 'localforage'
import { useQuery } from '@apollo/client'

import { TICKETS_PAGE_DICTIONARIES_QUERY } from 'connectors/gql/ticket.gql'
import { archiveFilterOptions } from 'components/Sider/helper'
import { useBrandFiltering } from 'pages/TicketsPage/tickets.hooks'

import TicketsTable from './components/Table'
import SiderFilters from './components/SiderFilters'

import _ from './styles.module.css'

const { Header, Content, Sider } = Layout

const groupList = [
    { name: 'Реестр-про', path: '/' },
    { name: 'Реестр-про_V2', path: '/ticketsNew' },
    { name: 'Реестр', path: '/tickets' },
    { name: 'Черновики', path: '/editor/drafts' },
    { name: 'Регионы', path: '/editor/regions' },
    { name: 'Компании', path: '/editor/companies' },
    { name: 'Бренды', path: '/editor/brands' },
    { name: 'Города', path: '/editor/cities' },
    { name: 'ТЦ', path: '/editor/buildings' },
    { name: 'Магазины', path: '/editor/shops' },
    { name: 'Сметные группы', path: '/editor/estimate-groups' },
]

const TicketsPageNew: React.FC = () => {
    const navigate = useNavigate()

    const { data: dictionaries, loading: dictsLoading, error: dictsError } = useQuery(TICKETS_PAGE_DICTIONARIES_QUERY)

    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(25)
    const [collapsed, setCollapsed] = useState(true)

    const [textValue, setTextValue] = useState('')
    const [cityFilterValues, setCityFilterValues] = useState([])
    const [buildingFilterValues, setBuildingFilterValues] = useState([])
    const [brandFilterValues, setBrandFilterValues] = useState([])
    const [brandsFromBuildingsIds, setBrandsFromBuildingsIds] = useState([])
    const [companiesFilterValues, setCompaniesFilterValues] = useState([])
    const [branchFilterValues, setBranchFilterValues] = useState([])
    const [prioritiesFilterValues, setPrioritiesFilterValues] = useState([])
    const [statesFilterValues, setStatesFilterValues] = useState([])
    const [archiveFilterValue, setArchiveFilterValue] = useState([archiveFilterOptions[0]])
    const [variables, setVariables] = useState({})

    const allFiltersByText = [
        JSON.stringify(cityFilterValues),
        JSON.stringify(buildingFilterValues),
        JSON.stringify(brandFilterValues),
        JSON.stringify(companiesFilterValues),
        JSON.stringify(branchFilterValues),
        JSON.stringify(prioritiesFilterValues),
        JSON.stringify(statesFilterValues),
        JSON.stringify(archiveFilterValue),
        textValue,
    ]

    useEffect(() => {
        ;(async function init() {
            // установка фильтров из localStorage
            const ticketsFilters: any = await localforage.getItem('ticketsFilters')
            const _page: any = await localforage.getItem('page')
            const _perPage: any = await localforage.getItem('perPage')
            if (_page) setPage(_page)
            if (_perPage) setPerPage(_perPage)
            if (ticketsFilters) {
                const parsedTicketsFilters = JSON.parse(ticketsFilters)
                setCityFilterValues(parsedTicketsFilters.cityFilterValues || [])
                setBuildingFilterValues(parsedTicketsFilters.buildingFilterValues || [])
                setBrandFilterValues(parsedTicketsFilters.brandFilterValues || [])
                setCompaniesFilterValues(parsedTicketsFilters.companiesFilterValues || [])
                setBranchFilterValues(parsedTicketsFilters.branchFilterValues || [])
                setPrioritiesFilterValues(parsedTicketsFilters.prioritiesFilterValues || [])
                setStatesFilterValues(parsedTicketsFilters.statesFilterValues || [])
                setArchiveFilterValue(parsedTicketsFilters.archiveFilterValue || archiveFilterOptions[0])
                setTextValue(parsedTicketsFilters.textValue || '')

                setVariables({
                    cities: parsedTicketsFilters.cityFilterValues || [],
                    buildings: parsedTicketsFilters.buildingFilterValues || [],
                    brands: parsedTicketsFilters.brandFilterValues || [],
                    companies: parsedTicketsFilters.companiesFilterValues || [],
                    branches: parsedTicketsFilters.branchFilterValues || [],
                    priorities: parsedTicketsFilters.prioritiesFilterValues || [],
                    ticketStates: parsedTicketsFilters.statesFilterValues || [],
                    ticketsArchive: parsedTicketsFilters.archiveFilterValue?.value || 0,
                    ticketsPhrase: parsedTicketsFilters.textValue || '',
                    page: _page || 1,
                    perPage: _perPage || 25,
                })
            } else {
                setVariables({
                    page: _page || 1,
                    perPage: _perPage || 25,
                })
            }
        })()
    }, [])

    useBrandFiltering(dictionaries, buildingFilterValues, setBrandsFromBuildingsIds)

    useEffect(() => {
        localforage.setItem('page', page)
        setVariables({
            ...variables,
            page,
        })
    }, [page]) // eslint-disable-line

    useEffect(() => {
        localforage.setItem('perPage', perPage)
        localforage.setItem('page', 1)
        setPage(1)
        setVariables({
            ...variables,
            page: 1,
            perPage,
        })
    }, [perPage]) // eslint-disable-line

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken()

    const resetFilters = useCallback(
        (event: any) => {
            event.stopPropagation()
            setVariables({
                cities: [],
                buildings: [],
                brands: [],
                companies: [],
                branches: [],
                priorities: [],
                ticketStates: [],
                ticketsPhrase: '',
                page,
                perPage,
            })

            setTextValue('')
            setCityFilterValues([])
            setBuildingFilterValues([])
            setBrandFilterValues([])
            setBrandsFromBuildingsIds([])
            setCompaniesFilterValues([])
            setBranchFilterValues([])
            setPrioritiesFilterValues([])
            setStatesFilterValues([])

            const filterValues = {
                cityFilterValues: [],
                buildingFilterValues: [],
                brandFilterValues: [],
                companiesFilterValues: [],
                branchFilterValues: [],
                prioritiesFilterValues: [],
                statesFilterValues: [],
                textValue: '',
                archiveFilterValue,
            }

            localforage.setItem('ticketsFilters', JSON.stringify(filterValues))
            // eslint-disable-next-line
        },
        [JSON.stringify(archiveFilterValue)],
    )

    const applyFilters = useCallback(
        (event: any) => {
            event.stopPropagation()

            setVariables({
                cities: cityFilterValues,
                buildings: buildingFilterValues,
                brands: brandFilterValues,
                companies: companiesFilterValues,
                branches: branchFilterValues,
                priorities: prioritiesFilterValues,
                ticketStates: statesFilterValues,
                // @ts-ignore
                ticketsArchive: archiveFilterValue?.value || 0,
                ticketsPhrase: textValue,
                page,
                perPage,
            })

            const filterValues = {
                cityFilterValues,
                buildingFilterValues,
                brandFilterValues,
                companiesFilterValues,
                branchFilterValues,
                prioritiesFilterValues,
                statesFilterValues,
                archiveFilterValue,
                textValue,
            }

            localforage.setItem('ticketsFilters', JSON.stringify(filterValues))
            // close()
            // eslint-disable-next-line
        },
        [...allFiltersByText],
    )

    return (
        <Layout style={{ height: '100vh' }}>
            <Header style={{ display: 'flex', alignItems: 'center' }}>
                <Menu
                    theme='dark'
                    mode='horizontal'
                    defaultSelectedKeys={['/ticketsNew']}
                    items={groupList.map((g) => ({ key: g.path, label: g.name }))}
                    style={{ flex: 1, minWidth: 0 }}
                    onSelect={({ key }) => navigate(key)}
                />
            </Header>
            <Layout>
                <Sider
                    width={250}
                    collapsedWidth={34}
                    collapsible
                    collapsed={collapsed}
                    onCollapse={(value) => setCollapsed(value)}
                    theme='light'
                >
                    <Menu
                        mode='inline'
                        defaultSelectedKeys={['1']}
                        defaultOpenKeys={['sub1']}
                        style={{ height: '100%', borderRight: 0 }}
                        // items={items2}
                    >
                        {!collapsed ? (
                            <SiderFilters
                                dictionaries={dictionaries}
                                textValue={textValue}
                                cityFilterValues={cityFilterValues}
                                buildingFilterValues={buildingFilterValues}
                                brandFilterValues={brandFilterValues}
                                companiesFilterValues={companiesFilterValues}
                                branchFilterValues={branchFilterValues}
                                brandsFromBuildingsIds={brandsFromBuildingsIds}
                                prioritiesFilterValues={prioritiesFilterValues}
                                statesFilterValues={statesFilterValues}
                                archiveFilterValue={archiveFilterValue}
                                setTextValue={setTextValue}
                                setCityFilterValues={setCityFilterValues}
                                setBuildingFilterValues={setBuildingFilterValues}
                                setBrandFilterValues={setBrandFilterValues}
                                setCompaniesFilterValues={setCompaniesFilterValues}
                                setBranchFilterValues={setBranchFilterValues}
                                setPrioritiesFilterValues={setPrioritiesFilterValues}
                                setStatesFilterValues={setStatesFilterValues}
                                setArchiveFilterValue={setArchiveFilterValue}
                                resetFilters={resetFilters}
                                applyFilters={applyFilters}
                            />
                        ) : (
                            <>
                                <div className={_.siderClosedTitle} onClick={() => setCollapsed(false)}>
                                    Панель фильтров
                                </div>
                                <div className={_.siderClosed} onClick={() => setCollapsed(false)} />
                            </>
                        )}
                    </Menu>
                </Sider>
                <Layout style={{ padding: '16px 24px 24px' }}>
                    <Content
                        style={{
                            padding: 8,
                            margin: 0,
                            minHeight: 280,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                        className='contentWrapper'
                    >
                        <TicketsTable
                            variables={variables}
                            dictsLoading={dictsLoading}
                            dictsError={dictsError}
                            page={page}
                            perPage={perPage}
                            setPage={setPage}
                            setPerPage={setPerPage}
                        />
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}

export default TicketsPageNew
