import { useState, memo, useEffect } from 'react'
import clsx from 'clsx'
import { AiOutlineClose, AiOutlineFileText } from 'react-icons/ai'
import { Modal } from 'antd'

import { Files } from 'connectors/query/Files'
import { FILE_DELETE } from 'connectors/gql/file.gql'
import { TICKET_QUERY } from 'connectors/gql/ticket.gql'

import { dateFmtFromStrTime } from 'tools'
import { useDelete } from 'helpers/hooks/editor'

import Button from 'components/Button'
import ButtonSize from 'components/ButtonSize'
import FileUploader from 'components/FileUploader'

import _ from './styles.module.css'

// import { tableColumns, tableData } from './mock'

const TicketAttachData = ({ ticketId, files, ticketRefetch }: any) => {
    const [addedFile, setAddedFile] = useState<any>(null)
    const [showBigPicId, setShowBigPicId] = useState(null)
    const [showMessageModal, setShowMessageModal] = useState<any>(null) // отображение модалки предупреждения о удалении
    const [isLongList, setIsLongList] = useState(false)

    const deleteAction = useDelete(FILE_DELETE, TICKET_QUERY, 'ticket', 'fileDelete')
    const deleteHandler = (e: any, file: any) => {
        e.preventDefault()
        e.stopPropagation()
        setShowMessageModal(file)
    }

    const upload = () => {
        const formData = new FormData()

        if (addedFile) {
            formData.append(`file`, addedFile, encodeURI(addedFile.name))
        }

        Files.postFile(ticketId, formData).then(() => {
            setAddedFile(null)
            ticketRefetch()
        })
    }

    useEffect(() => {
        if (!!addedFile) upload()
    }, [addedFile]) // eslint-disable-line

    const fileData = showBigPicId ? files.find((f: any) => f.id === showBigPicId) : null

    return (
        <div className={_.wrapper}>
            <div className={_.header}>Вложения</div>

            <FileUploader
                upload={upload}
                addFile={setAddedFile}
                addedFile={addedFile}
                setAddedFile={setAddedFile}
                ticketPage
            />

            <div className={_.fileBlock}>
                <div className={_.fileList}>
                    {files && files.length > 0 ? (
                        <div className={clsx(_.imageWrapper, isLongList && _.longList)}>
                            {files
                                .filter((f: any) => !f.mimetype.includes('image'))
                                .map((f: any) => (
                                    <div key={f.id} className={_.imagePreview}>
                                        <span className={_.iconRemove} onClick={(e) => deleteHandler(e, f)}>
                                            <AiOutlineClose />
                                        </span>

                                        <AiOutlineFileText className={_.iconPreview} />
                                        <div
                                            className={_.fileName}
                                            onClick={() => {
                                                Files.getFile(f.id)
                                            }}
                                        >
                                            <div className={_.fileNameText} title={f.name}>
                                                {f.name}
                                            </div>
                                            {f.created_by && (
                                                <div
                                                    className={clsx(_.fileUploader, 'italic')}
                                                    title={f.created_by.email}
                                                >
                                                    {f.created_by.family_name} {f.created_by.given_name}
                                                </div>
                                            )}
                                            <div className={_.fileNameInfo}>{dateFmtFromStrTime(f.created_at)}</div>
                                        </div>
                                    </div>
                                ))}
                            {files
                                .filter((f: any) => f.mimetype.includes('image'))
                                .map((f: any) => (
                                    <div key={f.id} className={_.imagePreview} onClick={() => setShowBigPicId(f.id)}>
                                        <span className={_.iconRemove} onClick={(e) => deleteHandler(e, f)}>
                                            <AiOutlineClose />
                                        </span>

                                        <img src={`/rest/file/${f.id}`} alt='pic' />
                                        <div
                                            key={f.id}
                                            className={_.fileName}
                                            onClick={() => {
                                                Files.getFile(f.id)
                                            }}
                                        >
                                            <div className={_.fileNameText} title={f.name}>
                                                {f.name}
                                            </div>
                                            {f.created_by && (
                                                <div
                                                    className={clsx(_.fileUploader, 'italic')}
                                                    title={f.created_by.email}
                                                >
                                                    {f.created_by.family_name} {f.created_by.given_name}
                                                </div>
                                            )}
                                            <div className={_.fileNameInfo}>{dateFmtFromStrTime(f.created_at)}</div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ) : (
                        <div className='italic'>Нет сохранённых файлов</div>
                    )}
                </div>
                {files && files.length > 5 && (
                    <div className={_.buttonSizeWrap} onClick={() => setIsLongList(!isLongList)}>
                        <ButtonSize bigSize={isLongList} />
                    </div>
                )}
            </div>

            <Modal width='73vw' open={!!showBigPicId} footer={null} onCancel={() => setShowBigPicId(null)}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img style={{ maxWidth: '70vw', maxHeight: '70vh' }} src={`/rest/file/${showBigPicId}`} alt='pic' />
                    {fileData && (
                        <>
                            <div>{fileData.name}</div>
                            {fileData.created_by && (
                                <div className='italic' title={fileData.created_by.email}>
                                    {fileData.created_by.family_name} {fileData.created_by.given_name}
                                </div>
                            )}
                            <div className={_.fileNameInfo}>{dateFmtFromStrTime(fileData.created_at)}</div>
                            <div>Размер: {Math.round(fileData.size / 1000)} Kb</div>
                        </>
                    )}
                </div>
            </Modal>

            <Modal width={430} open={showMessageModal} footer={null} onCancel={() => setShowMessageModal(null)}>
                <div>
                    <div className={_.messageModalText}>
                        Точно удалить файл <span className={_.fileNameInfo}>{showMessageModal?.name}</span>?
                    </div>
                    <div className='flex justify-space-between'>
                        <Button
                            name='Да'
                            onClick={() => {
                                deleteAction(showMessageModal?.id)
                                setShowMessageModal(null)
                            }}
                            mods={['red']}
                        />
                        <Button name='Отмена' onClick={() => setShowMessageModal(null)} mods={['blue']} />
                    </div>
                </div>
            </Modal>

            {/* <div className={_.row}>
                <Table
                    tableColumns={tableColumns}
                    tableData={tableData}
                    dataListForSelects={{ name: [] }}
                />
            </div> */}
        </div>
    )
}

export default memo(TicketAttachData)
