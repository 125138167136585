import { gql } from "@apollo/client";

export const TICKET_STATES_QUERY = gql`
  query ticketStatesQuery {
    ticketStates {
      id
      name
    }
  }
`;
