import { gql } from "@apollo/client";

export const COMPANIES_QUERY = gql`
  query companiesQuery {
    companies {
      id
      name
      short_name
      state {
        id
        name
      }
      chronology
    }
  }
`;


export const COMPANY_UPDATE = gql`
  mutation CompanyUpdate($company: CompanyUpdate!) {
    companyUpdate(company: $company) {
      id
      name
      state {
        id
        name
      }
      chronology
    }
  }
`;


export const COMPANY_CREATE = gql`
  mutation CompanyCreate($company: CompanyCreate!) {
    companyCreate(company: $company) {
      id
      name
      state {
        id
        name
      }
      chronology
    }
  }
`;

export const COMPANY_DELETE = gql`
  mutation CompanyDelete($id: Int!) {
    companyDelete(id: $id) {
      id
    }
  }
`;

export const COMPANY_STATES_QUERY = gql`
  query CompanyStates {
    companyStates {
      id
      name
    }
  }
`;