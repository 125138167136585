import React, { memo, useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'antd'

import { WARES_QUERY } from 'connectors/gql/wares.gql'
import { setOptionsFormat } from 'helpers'
import { ISelectOption, IWare } from 'types'

interface IWaresSelect {
    value: number
    onChange: (data: any) => void
    className?: string
}

const WaresSelect = ({ value, onChange, ...props }: IWaresSelect) => {
    const { data } = useQuery(WARES_QUERY)

    const [wares, setWares] = useState<IWare[]>(data?.ticket || [])
    const [options, setOptions] = useState<ISelectOption[]>(setOptionsFormat(wares) || [])

    const onChangeHandler = (value: number) => {
        const currentWare = wares.find((w: any) => w.id === value)

        onChange({ ...currentWare, value: currentWare?.id })
    }

    useEffect(() => {
        if (data) {
            setWares(data.wares)
            setOptions(setOptionsFormat(data.wares))
        }
    }, [JSON.stringify(data?.wares || {})]) // eslint-disable-line

    return (
        <Select
            value={options.find((o: any) => o.value === value)?.value}
            placeholder={'Оборудование не выбрано'}
            options={options}
            onChange={onChangeHandler}
            {...props}
        />
    )
}

export default memo(WaresSelect)
