import React, { useState } from 'react'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import ReactDOM from 'react-dom/client'
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, from, HttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

import { setAxiosHeader } from 'connectors/api/rest'

import App from './App'
import reportWebVitals from './reportWebVitals'
import keycloak from './Keycloak'

import './index.css'
import './AntdFixes.scss'

const httpLink = new HttpLink({ uri: '/graphql' })

const MainApp = () => {
    const [token, setToken] = useState<string | null>(null)

    const healthLink = onError(({ networkError }) => {
        console.log('networkError', networkError)
        // @ts-ignore
        if (!networkError || !networkError.result) return console.error('networkError')
        const isJWTExpirationError =
            // @ts-ignore
            networkError.result.errors
                ?.map((error: any) => error.message)
                ?.join(' ')
                ?.indexOf('jwt expired') > -1

        if (isJWTExpirationError) keycloak.logout()
    })

    setAxiosHeader(token)

    const tokenLink = new ApolloLink((operation, forward) => {
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                authorization: token,
            },
        }))

        return forward(operation)
    })

    const client = new ApolloClient({
        cache: new InMemoryCache(),
        link: from([healthLink, tokenLink, httpLink]),
    })

    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            initOptions={{
                onLoad: 'login-required',
            }}
            onTokens={({ token }) => {
                setToken(token ? `Bearer ${token}` : null)
            }}
        >
            <ApolloProvider client={client}>
                <App />
            </ApolloProvider>
        </ReactKeycloakProvider>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<MainApp />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
