import { gql } from "@apollo/client";

export const ESTIMATES_GROUP_TYPES_QUERY = gql`
  query estimateGroupTypesQuery {
    estimateGroupTypes {
      id
      name
    }
  }
`;
