import React, { memo, useEffect, useState } from 'react'
import { IoIosAddCircleOutline } from 'react-icons/io'
import clsx from 'clsx'

import {
    TICKET_UPDATE_COMPLETE_JOBS,
    TICKET_UPDATE_UNCOMPLETE_JOBS,
    TICKET_UPDATE_TECHNICAL_REMARK,
} from 'connectors/gql/ticket.gql'
import { WARES_QUERY } from 'connectors/gql/wares.gql'
import { UNITS_QUERY } from 'connectors/gql/units.gql'
import { useEntity } from 'helpers/hooks/editor'
import { ticketMutationProps, useTextMutation } from '../../helper'
import { tableColumns } from './mock'

import CreateEqupmentModal from './components/CreateEqupmentModal'
import Table from '../Table'
import Loader from 'components/Loader'
import TextAreaWithButtonSize from 'components/TextAreaWithButtonSize'

import _ from './styles.module.css'

const TicketSecondData = ({ id, complete_jobs, uncomplete_jobs, technical_remark, equipment, ticketRefetch }: any) => {
    const [comJob, setCompJob] = useState(complete_jobs || '')
    const [uncomJob, setUncompJob] = useState(uncomplete_jobs || '')
    const [techRem, setTechRem] = useState(technical_remark || '')
    const [showAddEqupment, setShowAddEqupment] = useState(false)

    const [wares, waresLoading] = useEntity(WARES_QUERY, 'wares')
    const [units, unitsLoading] = useEntity(UNITS_QUERY, 'units')

    // скрываем textArea, если они пустые
    const [isEmptyUncompleteJobs, setIsEmptyUncompleteJobs] = useState(!uncomplete_jobs)
    const [isEmptyЕechnicalRemark, setIsEmptyЕechnicalRemark] = useState(!technical_remark)

    useEffect(() => {
        setIsEmptyUncompleteJobs(!uncomplete_jobs)
    }, [uncomplete_jobs])
    useEffect(() => {
        setIsEmptyЕechnicalRemark(!technical_remark)
    }, [technical_remark])

    // обновления данных textArea
    const updateCompleteJobs = useTextMutation(id, TICKET_UPDATE_COMPLETE_JOBS, ticketMutationProps, comJob, setCompJob)
    const updateUncompleteJobs = useTextMutation(
        id,
        TICKET_UPDATE_UNCOMPLETE_JOBS,
        ticketMutationProps,
        uncomJob,
        setUncompJob,
    )
    const updateTechnicalRemark = useTextMutation(
        id,
        TICKET_UPDATE_TECHNICAL_REMARK,
        ticketMutationProps,
        techRem,
        setTechRem,
    )

    const everyThingIsHide = isEmptyUncompleteJobs && isEmptyЕechnicalRemark && (!equipment || !equipment.length)

    return (
        <div className={_.wrapper}>
            <div className={_.row}>
                <div className={_.rowLabel}>Выполнено</div>
                <div className={_.longBlock}>
                    <TextAreaWithButtonSize
                        triggers={[
                            comJob,
                            complete_jobs,
                            everyThingIsHide,
                            isEmptyUncompleteJobs,
                            isEmptyЕechnicalRemark,
                        ]}
                        className={clsx(_.textarea, everyThingIsHide ? _.nineRowsArea : _.fourRowsArea)}
                        defaultValue={complete_jobs}
                        onBlur={updateCompleteJobs}
                    />
                </div>
            </div>

            <div className={clsx(_.row, equipment && equipment.length && _.mBottom25)}>
                <div className={_.rowLabel}>Поставлено</div>
                {(!equipment || !equipment.length) && (
                    <div className={_.addDataBtn} onClick={() => setShowAddEqupment(true)}>
                        <IoIosAddCircleOutline />
                    </div>
                )}
                <div className={_.longBlock}>
                    {equipment && equipment.length > 0 && (
                        <Table
                            tableColumns={tableColumns}
                            tableData={equipment}
                            dataListForSelects={{ ware: wares, unit: units }}
                            ticketRefetch={ticketRefetch}
                        />
                    )}
                </div>

                {equipment && equipment.length > 0 && (
                    <div className={clsx(_.addDataBtn, _.addDataBtnUnderTable)}>
                        <IoIosAddCircleOutline onClick={() => setShowAddEqupment(true)} />
                    </div>
                )}
            </div>

            <div className={_.row}>
                <div className={_.rowLabel}>Замечания Рекомендации</div>
                <div className={_.longBlock}>
                    {!isEmptyЕechnicalRemark ? (
                        <TextAreaWithButtonSize
                            triggers={[uncomJob, uncomplete_jobs]}
                            className={clsx(_.textarea, _.fourRowsArea)}
                            defaultValue={technical_remark}
                            onBlur={updateTechnicalRemark}
                        />
                    ) : (
                        <div className={_.addDataBtn} onClick={() => setIsEmptyЕechnicalRemark(false)}>
                            <IoIosAddCircleOutline />
                        </div>
                    )}
                </div>
            </div>

            <div className={_.row}>
                <div className={_.rowLabel}>Не&nbsp;выполнено</div>
                <div className={_.longBlock}>
                    {!isEmptyUncompleteJobs ? (
                        <TextAreaWithButtonSize
                            triggers={[uncomJob, uncomplete_jobs]}
                            className={clsx(_.textarea, _.fourRowsArea)}
                            defaultValue={uncomplete_jobs}
                            onBlur={updateUncompleteJobs}
                        />
                    ) : (
                        <div className={_.addDataBtn} onClick={() => setIsEmptyUncompleteJobs(false)}>
                            <IoIosAddCircleOutline />
                        </div>
                    )}
                </div>
            </div>

            <CreateEqupmentModal ticketId={id} show={showAddEqupment} onClose={() => setShowAddEqupment(false)} />

            {(waresLoading || unitsLoading) && <Loader />}
        </div>
    )
}

export default memo(TicketSecondData)
