import { gql } from "@apollo/client";

export const SHOPS_QUERY = gql`
  query shopsQuery($sorting: [ShopSorting!]!, $filters: [ShopFilter!]!, $page: Int, $perPage: Int) {
    shops(sorting: $sorting, filters: $filters, page: $page, perPage: $perPage) {
      id
      code
      email
      phone
      phone2
      area
      opened
      serve_start
      serve_end
      closed
      brand {
        id
        name
        short_name
        company {
          id
          name
        }
      }
      building {
        id
        name
        address
        city {
          id
          name
          abbreviation
        }
      }
      state {
        id
        name
      }
      director
      remark
      chronology
    }
  }
`;

export const SHOP_STATES_QUERY = gql`
  query shopStatesQuery {
    shopStates {
      id
      name
    }
  }
`;


export const SHOP_UPDATE = gql`
  mutation Shop($shop: ShopUpdate!) {
    shopUpdate(shop: $shop) {
      id
      code
      email
      phone
      phone2
      area
      opened
      serve_start
      serve_end
      closed
      brand {
        id
        name
      }
      building {
        id
        name
      }
      state {
        id
        name
      }
      director
      remark
      chronology
    }
  }
`;


export const SHOP_CREATE = gql`
  mutation ShopMutation($shop: ShopCreate!) {
    shopCreate(shop: $shop) {
      code
      email
      phone
      phone2
      area
      opened
      serve_start
      serve_end
      closed
      brand {
        id
        name
      }
      building {
        id
        name
      }
      state {
        id
        name
      }
      director
      remark
      chronology
    }
  }
`;

export const SHOP_DELETE = gql`
  mutation ShopDelete($id: Int!) {
    shopDelete(id: $id) {
      id
    }
  }
`;
