import React, { memo, useState } from 'react'

import {
    ESTIMATES_GROUPS_QUERY,
    ESTIMATES_GROUP_CREATE,
    ESTIMATES_GROUP_UPDATE,
    ESTIMATES_GROUP_DELETE,
} from 'connectors/gql/estimate-groups.gql'
import { COMPANIES_QUERY } from 'connectors/gql/companies.gql'
import { ESTIMATES_GROUP_STATES_QUERY } from 'connectors/gql/estimate-group-states.gql'
import { ESTIMATES_GROUP_TYPES_QUERY } from 'connectors/gql/estimate-group-types.gql'

import { useEntity, useSortAndFilter, useCreate, useUpdate, useDelete } from 'pages/EditorPage/hooks'
import { sendXLSX } from 'pages/EditorPage/helpers'

import Button from 'components/Button'
import Loader from 'components/Loader'
import Table from 'components/Table'
import Row from '../../components/Row'
import FilterRow from '../../components/Row/FilterRow'
import CreateEstimateGroupModal from './CreateModal'

import { columns, defaultSorts } from './columns'

import _ from '../styles.module.css'

const filters = {}
// @ts-ignore
columns.forEach((c) => (filters[c.path] = null))

const EstimateGroups = () => {
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false)

    const [, companiesLoading] = useEntity(COMPANIES_QUERY, 'companies')
    const [estimateGroups, estimateGroupsLoading, refetchEstimateGroups] = useEntity(
        ESTIMATES_GROUPS_QUERY,
        'estimateGroups',
    )

    const [estimateGroupStates, estimateGroupStatesLoading] = useEntity(
        ESTIMATES_GROUP_STATES_QUERY,
        'estimateGroupStates',
    )
    const [estimateGroupTypes, estimateGroupTypesLoading] = useEntity(ESTIMATES_GROUP_TYPES_QUERY, 'estimateGroupTypes')

    const {
        filterFields,
        setFilterFields,
        sortedEntity: sortedEstimateGroups,
        headerClick,
        headerSortsArrows,
        resetSort,
        resetFilters,
    } = useSortAndFilter(filters, estimateGroups, columns, 'estimateGroups', defaultSorts)

    const { onCreate, loading: createEstimateGroupLoading } = useCreate(
        ESTIMATES_GROUP_CREATE,
        ESTIMATES_GROUPS_QUERY,
        'estimateGroupsQuery',
        'estimateGroup',
        setIsModalCreateOpen,
    )

    const updateAction = useUpdate(
        ESTIMATES_GROUP_UPDATE,
        'estimateGroup',
        ESTIMATES_GROUPS_QUERY,
        'estimateGroupsQuery',
    )
    const deleteAction = useDelete(
        ESTIMATES_GROUP_DELETE,
        ESTIMATES_GROUPS_QUERY,
        'estimateGroupsQuery',
        'estimateGroupDelete',
    )

    const _updateAction = (id: number) => (value: any) => {
        updateAction(id)(value)
        refetchEstimateGroups()
    }

    return (
        <div className={_.wrapper}>
            <div className={_.title}>Редактор сметных групп</div>

            <div className={_.addBtn}>
                <Button name='Добавить' onClick={() => setIsModalCreateOpen(true)} />
                <Button
                    name='Выгрузить в XLSX'
                    mods={['blue']}
                    onClick={() => sendXLSX(sortedEstimateGroups, columns, 'estimateGroups')}
                />
            </div>

            <div className={_.tableWrapper}>
                <Table
                    headers={columns}
                    headerClick={headerClick}
                    sorts={headerSortsArrows}
                    resetSort={resetSort}
                    showBorder
                    isSticky
                >
                    <FilterRow
                        columns={columns}
                        filterFields={filterFields}
                        setFilterFields={setFilterFields}
                        dataListForField={{
                            state: estimateGroupStates,
                            type: estimateGroupTypes,
                        }}
                        reset={resetFilters}
                    />
                    {sortedEstimateGroups.map((b: any) => (
                        <Row
                            key={b.id}
                            {...b}
                            columns={columns}
                            dataListForField={{
                                state: estimateGroupStates,
                                type: estimateGroupTypes,
                            }}
                            updateAction={_updateAction}
                            deleteAction={deleteAction}
                            canDelete
                        />
                    ))}
                </Table>
            </div>

            <CreateEstimateGroupModal
                open={isModalCreateOpen}
                onClose={() => setIsModalCreateOpen(false)}
                onCreate={onCreate}
                dataList={{
                    states: estimateGroupStates,
                    types: estimateGroupTypes,
                }}
            />

            {(companiesLoading ||
                createEstimateGroupLoading ||
                estimateGroupsLoading ||
                estimateGroupStatesLoading ||
                estimateGroupTypesLoading) && <Loader />}
        </div>
    )
}

export default memo(EstimateGroups)
