import { gql } from "@apollo/client";

export const BUILDINGS_QUERY = gql`
  query buildingsQuery {
    buildings {
      id
      name
      address
      city {
        id
        name
      }
      shops {
        id
        code
      }
      color
    }
  }
`;


export const BUILDING_UPDATE = gql`
  mutation Building($building: BuildingUpdate!) {
    buildingUpdate(building: $building) {
      id
      name
      address
      shops {
        id
      }
      city {
        id
      }
      color
    }
  }
`;


export const BUILDING_CREATE = gql`
  mutation BuildingCreate($building: BuildingCreate!) {
    buildingCreate(building: $building) {
      name
      address
      city {
        id
      }
      color
    }
  }
`;

export const BUILDING_DELETE = gql`
  mutation BuildingDelete($id: Int!) {
    buildingDelete(id: $id) {
      id
    }
  }
`;
