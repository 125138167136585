import React, { memo, useState } from 'react'

import {
    COMPANIES_QUERY,
    COMPANY_CREATE,
    COMPANY_STATES_QUERY,
    COMPANY_UPDATE,
    COMPANY_DELETE,
} from 'connectors/gql/companies.gql'
import { sendXLSX } from 'pages/EditorPage/helpers'

import Button from 'components/Button'
import Loader from 'components/Loader'
import Table from 'components/Table'
import Row from '../../components/Row'
import FilterRow from '../../components/Row/FilterRow'
import CreateBrandModal from './CreateModal'

import { columns, defaultSorts } from './columns'
import { useEntity, useSortAndFilter, useCreate, useUpdate, useDelete } from 'pages/EditorPage/hooks'

import _ from '../styles.module.css'

const filters = {}
// @ts-ignore
columns.forEach((c) => (filters[c.path] = null))

const Companies = () => {
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false)

    const [companyStates, companyStatesLoading] = useEntity(COMPANY_STATES_QUERY, 'companyStates')
    const [companies, companiesLoading] = useEntity(COMPANIES_QUERY, 'companies')

    const {
        filterFields,
        setFilterFields,
        sortedEntity: sortedCompanies,
        headerClick,
        headerSortsArrows,
        resetSort,
        resetFilters,
    } = useSortAndFilter(filters, companies, columns, 'companies', defaultSorts)

    const { onCreate, loading: createBuildingLoading } = useCreate(
        COMPANY_CREATE,
        COMPANIES_QUERY,
        'companiesQuery',
        'company',
        setIsModalCreateOpen,
    )

    const updateAction = useUpdate(COMPANY_UPDATE, 'company')
    const deleteAction = useDelete(COMPANY_DELETE, COMPANIES_QUERY, 'companiesQuery', 'companyDelete')

    return (
        <div className={_.wrapper}>
            <div className={_.title}>Редактор компаний</div>

            <div className={_.addBtn}>
                <Button name='Добавить' onClick={() => setIsModalCreateOpen(true)} />
                <Button
                    name='Выгрузить в XLSX'
                    mods={['blue']}
                    onClick={() => sendXLSX(sortedCompanies, columns, 'companies')}
                />
            </div>

            <div className={_.tableWrapper}>
                <Table
                    headers={columns}
                    headerClick={headerClick}
                    sorts={headerSortsArrows}
                    resetSort={resetSort}
                    showBorder
                    isSticky
                >
                    <FilterRow
                        columns={columns}
                        filterFields={filterFields}
                        setFilterFields={setFilterFields}
                        dataListForField={{
                            state: companyStates,
                        }}
                        reset={resetFilters}
                    />
                    {sortedCompanies.map((c: any) => (
                        <Row
                            key={c.id}
                            {...c}
                            columns={columns}
                            dataListForField={{
                                state: companyStates,
                            }}
                            updateAction={updateAction}
                            deleteAction={deleteAction}
                            canDelete
                        />
                    ))}
                </Table>
            </div>

            <CreateBrandModal
                open={isModalCreateOpen}
                onClose={() => setIsModalCreateOpen(false)}
                onCreate={onCreate}
                dataList={{
                    companyStates,
                }}
            />

            {(companyStatesLoading || createBuildingLoading || companiesLoading) && <Loader />}
        </div>
    )
}

export default memo(Companies)
