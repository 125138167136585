import React, { memo, useRef, useState } from 'react'
import { Modal } from 'antd'
import { useParams } from 'react-router-dom'

import { TICKET_QUERY } from 'connectors/gql/ticket.gql'
import { useEntity } from 'helpers/hooks/editor'
import pic from 'assets/plan_1.jpg'

import Loader from 'components/Loader'
import Button from 'components/Button'
import Header from 'components/Header'

import TicketMainData from './components/ticket-main-data'
import Chat from './components/Chat'
import TicketAttachData from './components/TicketAttachData'
import TicketSecondData from './components/ticket-second-data'

import _ from './styles.module.css'

const TicketPage = () => {
    const { id } = useParams()
    const picRef = useRef<HTMLImageElement>(null)

    const [coords, setCoords] = useState<any>([])
    const [zoom, setZoom] = useState(10)
    // TODO: карта магазина работает нормально. требуется поправить стили высоты страницы фона и разобраться какая кнопка открывает эту карту
    const showMap = false

    const [ticket, ticketLoading, ticketRefetch] = useEntity(TICKET_QUERY, 'ticket', {
        id: id ? parseInt(id, 10) : null,
    })

    const setPoint = (evt: any) => {
        if (picRef) {
            const pb = picRef?.current?.getBoundingClientRect()
            if (pb) {
                const newCoords = [
                    Math.round(-pb.left + (evt.clientX * 10) / zoom),
                    Math.round(-pb.top + (evt.clientY * 10) / zoom),
                    'red',
                ]
                setCoords([...coords, newCoords])
            }
        }
    }

    return (
        <>
            <Header />
            <div className={_.ticketWrapper}>
                <TicketMainData {...ticket} />
                <TicketSecondData {...ticket} ticketRefetch={ticketRefetch} />
                <TicketAttachData {...ticket} ticketId={id} ticketRefetch={ticketRefetch} />
                <Chat messages={ticket.messages} ticketId={id} />

                {ticketLoading && <Loader />}

                <Modal width={1400} title='Карта магазина' open={showMap}>
                    <div>Карта магазина</div>
                    <div style={{ overflow: 'scroll', maxHeight: 700, position: 'relative' }}>
                        {coords.map((c: any) => (
                            <div
                                style={{
                                    position: 'absolute',
                                    left: (c[0] * zoom) / 10 - 15,
                                    top: (c[1] * zoom) / 10 - 15,
                                    width: 30,
                                    height: 30,
                                    borderRadius: '50%',
                                    background: c[2] || 'red',
                                }}
                            />
                        ))}
                        <img
                            ref={picRef}
                            src={pic}
                            onClick={setPoint}
                            style={{ zoom: `${10 * +zoom}%` }}
                            alt='Схема магазина'
                        />
                    </div>
                    <div className={_.btnContainer}>
                        <input
                            type='range'
                            min={1}
                            max={10}
                            value={zoom}
                            onChange={(e) => {
                                setZoom(+e.target.value)
                            }}
                        />
                        <Button name='Отмена' mods={['blue']} onClick={() => setCoords([])} />
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default memo(TicketPage)
