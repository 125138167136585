import React, { useRef, useState } from 'react'
import clsx from 'clsx'

import { useCheckScroll } from 'helpers/hooks/useCheckScroll'
import ButtonSize from 'components/ButtonSize'

import _ from './styles.module.css'

const TextAreaWithButtonSize = ({ triggers = [], className, ...props }: any) => {
    // кнопки для расширения textArea
    const textAreaUncompleteJobs = useRef<any>(null)
    const [uncompleteJobsBigSize, setUncompleteJobsBigSize] = useState(false)
    const [uncompleteJobsValueTrigger, setUncompleteJobsValueTrigger] = useState('')

    const contentHeight = textAreaUncompleteJobs?.current?.scrollHeight || 0
    const styles =
        contentHeight && uncompleteJobsBigSize ? { height: `${contentHeight < 275 ? contentHeight : 275}px` } : {}

    // проверка наличия скролла
    const [uncompleteJobsHasScroll, setUncompleteJobsHasScroll] = useState(false)
    useCheckScroll(textAreaUncompleteJobs, setUncompleteJobsHasScroll, [
        textAreaUncompleteJobs,
        uncompleteJobsValueTrigger,
        contentHeight,
        ...triggers,
    ])

    return (
        <div className={_.wrapper}>
            <textarea
                style={styles}
                ref={textAreaUncompleteJobs}
                className={clsx(className, uncompleteJobsBigSize && _.bigTextArea)}
                onChange={(e) => setUncompleteJobsValueTrigger(e.target.value)}
                {...props}
            />

            <div
                className={_.buttonSizeWrap}
                style={uncompleteJobsHasScroll || uncompleteJobsBigSize ? { opacity: 1 } : {}}
                onClick={() => setUncompleteJobsBigSize(!uncompleteJobsBigSize)}
            >
                <ButtonSize bigSize={uncompleteJobsBigSize} />
            </div>
        </div>
    )
}

export default TextAreaWithButtonSize
