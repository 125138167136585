import React, { memo, useEffect, useState } from 'react'
import DatePicker from 'react-date-picker'
// import Select from 'react-select'
import { ColorPicker, Select, Switch } from 'antd'
import { Color } from 'antd/es/color-picker'
import clsx from 'clsx'

import { dateFmt } from 'tools'
import { ISelectOption } from 'types'
import { setOptionsFormat } from '../../helpers'
// import ColorPicker from 'app/component/color-picker/color-picker'
// import Switch from 'app/component/switch/switch'
import _ from './cell.module.css'

const defaultVal = {
    checkbox: false,
    date: undefined,
}

const Cell = ({ updateHandler, type, defaultValue, fieldName, list, placeholder, mods }: any) => {
    // @ts-ignore
    const [val, setVal] = useState(defaultValue || defaultVal[type] || '')
    const [options, setOptions] = useState<ISelectOption[]>(list ? setOptionsFormat(list) : [])

    useEffect(() => {
        if (list?.length) setOptions(setOptionsFormat(list))
    }, [JSON.stringify(list)]) // eslint-disable-line

    useEffect(() => {
        if (defaultValue && !val) setVal(defaultValue)
    }, [defaultValue]) // eslint-disable-line

    const update = (event: any) => {
        if (type === 'date' || type === 'color') {
            if (event !== defaultValue) return updateHandler({ [fieldName]: event })
            return null
        }

        if (type === 'checkbox') {
            updateHandler({ [fieldName]: !val })
            return setVal(!val)
        }

        if (type === 'number') {
            return updateHandler({ [fieldName]: parseFloat(val) })
        }

        const trimmedValue = event.target.value.trim()

        if (defaultValue !== trimmedValue) {
            updateHandler({ [fieldName]: trimmedValue })
            setVal(trimmedValue)
        }
    }

    if (type === 'input') {
        return (
            <td>
                <input
                    className={clsx(_.textarea)}
                    type='text'
                    value={val}
                    onChange={(e) => setVal(e.target.value)}
                    onBlur={update}
                />
            </td>
        )
    }

    if (type === 'number') {
        return (
            <td>
                <input
                    className={clsx(_.textarea)}
                    type='number'
                    value={val}
                    onChange={(e) => setVal(e.target.value)}
                    onBlur={update}
                />
            </td>
        )
    }

    if (type === 'textarea') {
        return (
            <td>
                <div>
                    <textarea
                        className={clsx(_.textarea)}
                        value={val}
                        onChange={(e) => setVal(e.target.value)}
                        onBlur={update}
                    />
                </div>
            </td>
        )
    }

    if (type === 'select') {
        return (
            <td className={_.selectCell}>
                <Select
                    className='fullWidth'
                    value={(defaultValue && options.find((o: any) => +o.value === +defaultValue)) || undefined}
                    placeholder={placeholder}
                    options={options}
                    onChange={(value) => {
                        updateHandler({ [fieldName]: value ? parseInt(value, 10) : null })
                    }}
                    filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
                    showSearch
                />
            </td>
        )
    }

    if (type === 'textDate') {
        const date = val ? dateFmt(new Date(val).getTime()) : ''

        return (
            <td>
                <div style={{ textAlign: 'center' }}>{date}</div>
            </td>
        )
    }

    if (type === 'date') {
        const test = val ? new Date(val) : undefined
        return (
            <td>
                <div className={_.dateWrapper}>
                    <DatePicker
                        onChange={update}
                        value={test}
                        calendarIcon={null}
                        clearIcon={null}
                        format='dd.MM.y'
                        disabled={mods && mods.includes('readOnly')}
                    />
                </div>
            </td>
        )
    }

    if (type === 'color') {
        return (
            <td className={_.colorCell}>
                <ColorPicker value={val} onChangeComplete={(color: Color) => update(`#${color.toHex()}`)} />
            </td>
        )
    }

    if (type === 'checkbox') {
        return (
            <td>
                <div className={_.checkBoxCell}>
                    <Switch defaultChecked={val} onChange={update} />
                </div>
            </td>
        )
    }

    return (
        <td>
            <div className={_.textCell}>{defaultValue}</div>
        </td>
    )
}

export default memo(Cell)
