import { gql } from "@apollo/client";

export const DRAFTS_QUERY = gql`
  query draftsQuery {
    drafts {
      id
      topic
      details
      created
      updated
      shop {
        id
        brand {
          name
          short_name
          color
        }
        building {
          name
          city {
            name
            abbreviation
            color
          }
          color
        }
        code
      }
      priority {
        id
        name
      }
    }
  }
`;


export const DRAFT_UPDATE = gql`
  mutation DraftUpdate($draft: DraftUpdate!) {
    draftUpdate(draft: $draft) {
      id
      topic
      details
    }
  }
`;
