import React, { memo, useEffect, useState } from 'react'
import DatePicker from 'react-date-picker'
import { Modal, Select } from 'antd'
import clsx from 'clsx'

import Button from 'components/Button'
import Table from 'components/Table'

import { setOptionsFormat, showErrors, inputCheckErrors, selectCheckErrors } from 'pages/EditorPage/helpers'

import _ from '../../CreateModal.module.css'

const CreateEstimateGroupModal = ({ onClose, onCreate, dataList, open }: any) => {
    const [errors, setErrors] = useState<string[]>([])

    const [name, setName] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [remark, setRemark] = useState('')
    const [company_restrictions, setСompanyrestrictions] = useState(0)

    const [date, setDate] = useState<Date | null>(null)
    const [finish, setFinish] = useState<Date | null>(null)

    const [state, setState] = useState(null)
    const [type, setType] = useState(null)

    const [states, setStates] = useState(dataList.states ? setOptionsFormat(dataList.states) : [])
    const [types, setTypes] = useState(dataList.types ? setOptionsFormat(dataList.types) : [])

    useEffect(() => {
        if (dataList.states?.length) setStates(setOptionsFormat(dataList.states))
    }, [JSON.stringify(dataList.states)]) // eslint-disable-line

    useEffect(() => {
        if (dataList.types?.length) setTypes(setOptionsFormat(dataList.types))
    }, [JSON.stringify(dataList.types)]) // eslint-disable-line

    const inputCheckField = inputCheckErrors(setErrors, errors)
    const selectCheckField = selectCheckErrors(setErrors, errors)

    return (
        <Modal title='Добавление сметной группы' open={open} onCancel={onClose} style={{ width: 400 }} footer={null}>
            <Table style={{ width: '100%' }}>
                <tr>
                    <td>Имя*</td>
                    <td>
                        <input
                            className={clsx(_.input, errors.includes('name') && _.error)}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onBlur={() => inputCheckField(setName, name, 'name')}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Заголовок*</td>
                    <td>
                        <input
                            className={clsx(_.input, errors.includes('title') && _.error)}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            onBlur={() => inputCheckField(setTitle, title, 'title')}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Дата</td>
                    <td className={_.date}>
                        <DatePicker
                            onChange={setDate}
                            value={date}
                            calendarIcon={null}
                            clearIcon={null}
                            format='dd.MM.y'
                        />
                    </td>
                </tr>
                <tr>
                    <td>Окончание</td>
                    <td className={_.date}>
                        <DatePicker
                            onChange={setFinish}
                            value={finish}
                            calendarIcon={null}
                            clearIcon={null}
                            format='dd.MM.y'
                        />
                    </td>
                </tr>
                <tr>
                    <td>Ограничение</td>
                    <td>
                        <input
                            type='number'
                            className={_.input}
                            value={company_restrictions}
                            onChange={(e: any) => setСompanyrestrictions(e.target.value)}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Статус*</td>
                    <td>
                        <Select
                            style={{ width: '100%' }}
                            value={state}
                            placeholder='Компания'
                            options={states}
                            onChange={setState}
                            className={clsx(errors.includes('state') && _.error)}
                            onBlur={() => selectCheckField(state, 'state')}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Тип*</td>
                    <td>
                        <Select
                            style={{ width: '100%' }}
                            value={type}
                            placeholder='Компания'
                            options={types}
                            onChange={setType}
                            className={clsx(errors.includes('type') && _.error)}
                            onBlur={() => selectCheckField(type, 'type')}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Замечание</td>
                    <td>
                        <input className={_.input} value={remark} onChange={(e) => setRemark(e.target.value)} />
                    </td>
                </tr>
                <tr>
                    <td>Описание</td>
                    <td>
                        <textarea
                            className={_.input}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </td>
                </tr>
            </Table>
            <div className={_.btnContainer}>
                <Button name='Отмена' mods={['blue']} onClick={onClose} />
                <Button
                    name='Сохранить'
                    onClick={() => {
                        if (name && title && state && type)
                            onCreate({
                                name,
                                date,
                                title,
                                remark,
                                finish,
                                description,
                                company_restrictions: +company_restrictions,
                                state_id: state,
                                type_id: type,
                            })
                        showErrors({ name, title, state }, setErrors, ['state', 'type'])
                    }}
                />
            </div>
        </Modal>
    )
}

export default memo(CreateEstimateGroupModal)
