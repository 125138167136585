export const archiveFilterOptions = [
    { value: 0, label: 'Только актуальные' },
    { value: 1, label: '1.5 месяца' },
    { value: 2, label: '3 месяца' },
    { value: 3, label: '6 месяцев' },
    { value: 4, label: '1 год' },
    { value: 5, label: '2 года' },
    { value: 6, label: '4 года' },
    { value: 7, label: 'Все' },
]
