import React, { memo, useState } from 'react'

import { BUILDINGS_QUERY, BUILDING_CREATE, BUILDING_UPDATE, BUILDING_DELETE } from 'connectors/gql/buildings.gql'
import { CITIES_QUERY } from 'connectors/gql/cities.gql'

import { useEntity, useSortAndFilter, useCreate, useUpdate, useDelete } from 'pages/EditorPage/hooks'
import { sendXLSX } from 'pages/EditorPage/helpers'

import Button from 'components/Button'
import Loader from 'components/Loader'
import Table from 'components/Table'
import Row from '../../components/Row'
import FilterRow from '../../components/Row/FilterRow'
import CreateBrandModal from './CreateModal/CreateModal'

import { columns, defaultSorts } from './columns'
import _ from '../styles.module.css'

const filters = {}
// @ts-ignore
columns.forEach((c) => (filters[c.path] = null))

const Buildings = () => {
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false)

    const [buildings, buildingsLoading] = useEntity(BUILDINGS_QUERY, 'buildings')
    const [cities, citiesLoading] = useEntity(CITIES_QUERY, 'cities', { full: true })

    const {
        filterFields,
        setFilterFields,
        sortedEntity: sortedBuildings,
        headerClick,
        headerSortsArrows,
        resetSort,
        resetFilters,
    } = useSortAndFilter(filters, buildings, columns, 'buildings', defaultSorts)

    const { onCreate, loading: createBuildingLoading } = useCreate(
        BUILDING_CREATE,
        BUILDINGS_QUERY,
        'buildingsQuery',
        'building',
        setIsModalCreateOpen,
    )

    const updateAction = useUpdate(BUILDING_UPDATE, 'building')
    const deleteAction = useDelete(BUILDING_DELETE, BUILDINGS_QUERY, 'buildingsQuery', 'buildingDelete')

    return (
        <div className={_.wrapper}>
            <div className={_.title}>Редактор ТЦ</div>

            <div className={_.addBtn}>
                <Button name='Добавить' onClick={() => setIsModalCreateOpen(true)} />
                <Button
                    name='Выгрузить в XLSX'
                    mods={['blue']}
                    onClick={() => sendXLSX(sortedBuildings, columns, 'buildings')}
                />
            </div>

            <div className={_.tableWrapper}>
                <Table
                    headers={columns}
                    headerClick={headerClick}
                    sorts={headerSortsArrows}
                    resetSort={resetSort}
                    showBorder
                    isSticky
                >
                    <FilterRow
                        columns={columns}
                        filterFields={filterFields}
                        setFilterFields={setFilterFields}
                        dataListForField={{
                            city: cities,
                        }}
                        reset={resetFilters}
                    />
                    {sortedBuildings.map((b: any) => (
                        <Row
                            key={b.id}
                            {...b}
                            columns={columns}
                            dataListForField={{
                                city: cities,
                            }}
                            updateAction={updateAction}
                            deleteAction={deleteAction}
                            canDelete
                        />
                    ))}
                </Table>
            </div>

            <CreateBrandModal
                open={isModalCreateOpen}
                onClose={() => setIsModalCreateOpen(false)}
                onCreate={onCreate}
                dataList={{
                    cities,
                }}
            />

            {(buildingsLoading || createBuildingLoading || citiesLoading) && <Loader />}
        </div>
    )
}

export default memo(Buildings)
