import React, { memo, useState } from 'react'
import clsx from 'clsx'
import { Modal } from 'antd'

import { IEditorCreateModalProps } from 'types/EditorCreateModal'

import { inputCheckErrors, showErrors } from 'helpers'
import Button from 'components/Button'
import Table from 'components/Table'

import _ from '../../CreateModal.module.css'

const CreateBrandModal = ({ onClose, onCreate, open }: IEditorCreateModalProps) => {
    const [errors, setErrors] = useState([])
    const [name, setName] = useState('')

    const inputCheckField = inputCheckErrors(setErrors, errors)

    return (
        <Modal open={open} title='Добавление региона' style={{ width: 400 }} footer={null}>
            <Table style={{ width: '100%' }}>
                <tr>
                    <td>Имя*</td>
                    <td>
                        <input
                            // @ts-ignore
                            className={clsx(_.input, errors.includes('name') && _.error)}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onBlur={() => inputCheckField(setName, name, 'name')}
                        />
                    </td>
                </tr>
            </Table>
            <div className={_.btnContainer}>
                <Button name='Отмена' mods={['blue']} onClick={onClose} />
                <Button
                    name='Сохранить'
                    onClick={() => {
                        if (name) onCreate({ name })
                        showErrors({ name }, setErrors)
                    }}
                />
            </div>
        </Modal>
    )
}

export default memo(CreateBrandModal)
