import { useCallback, useEffect, useState } from 'react'
import localforage from 'localforage'
import { useQuery } from '@apollo/client'
import clsx from 'clsx'

import { TICKETS_PAGE_QUERY, TICKETS_COUNT_PAGE_QUERY } from 'connectors/gql/ticket.gql.js'
import { isEmpty } from 'tools'

import { useSider } from 'components/Sider/sider.context'
import Loader from 'components/Loader'
import Table from 'components/Table'
import Pagination from 'components/Pagination'
import Tab from './components/Tab'
import Row from './components/Row'

import _ from './styles.module.css'

const restructurize = (tickets: any) =>
    tickets.reduce((acc: any, ticket: any) => {
        const city = ticket.shop.building.city.name
        const building = ticket.shop.building.name
        const brand = ticket.shop.brand.name

        const cityColor = ticket.shop.building.city.color
        const buildingColor = ticket.shop.building.color
        const brandColor = ticket.shop.brand.color

        if (!Object.prototype.hasOwnProperty.call(acc, city)) acc[city] = { name: city, data: {}, color: cityColor }

        if (!Object.prototype.hasOwnProperty.call(acc[city].data, building))
            acc[city].data[building] = { name: building, data: {}, color: buildingColor }

        if (!Object.prototype.hasOwnProperty.call(acc[city].data[building].data, brand))
            acc[city].data[building].data[brand] = { name: brand, data: [], color: brandColor }

        acc[city].data[building].data[brand].data.push(ticket)

        return acc
    }, {})

const pinCollapseClasses = ['collapsed', 'half', 'full']

const TicketsPage = ({
    variables,
    dictsLoading,
    dictsError,
    selectedDynamicField1,
    selectedDynamicField2,
    page,
    perPage,
    setPage,
    setPerPage,
}: any) => {
    // закреплённые храним полноценными объектами, т.к. в результате фильтрации их может не быть в списке отображаемых
    const [pinnedList, setPinnedList] = useState([])
    const [pinCollapseMod, setPinCollapseMod] = useState(0)
    const { closed } = useSider()

    // массив заявок с исключёнными-закреплёнными.
    // нужен что бы не обновлять полностью данные о всей заявке, при редактировании
    // (что бы обновить и текущую и закреплённую)
    const [ticketsWithoutPinned, setTicketsWithoutPinned] = useState([])
    const [matrix, setMatrix] = useState([])

    const { loading, error, data } = useQuery(TICKETS_PAGE_QUERY, { variables })
    const { data: count } = useQuery(TICKETS_COUNT_PAGE_QUERY, { variables })
    const ticketsCount = count?.countTicketsWithoutPagination || 0

    useEffect(() => {
        ;(async function init() {
            // установка закрепов из localStorage
            const ticketsPinned: string | null = await localforage.getItem('ticketsPinned')
            if (ticketsPinned) {
                const parsedTicketsPinned = JSON.parse(ticketsPinned)
                setPinnedList(parsedTicketsPinned)
            }
        })()
    }, [])

    useEffect(() => {
        if (!pinnedList.length) {
            setTicketsWithoutPinned(data?.tickets || [])
            return undefined
        }
        const pinnedIds = pinnedList.map((el: any) => el.id)
        const _ticketsWithoutPinned = data?.tickets.filter(({ id }: any) => !pinnedIds.includes(id)) || []
        setTicketsWithoutPinned(_ticketsWithoutPinned)
        // eslint-disable-next-line
    }, [JSON.stringify(data?.tickets || []), JSON.stringify(pinnedList)])

    useEffect(() => {
        const _matrix = restructurize(ticketsWithoutPinned)
        // setMatrix(_matrix);
        setMatrix(ticketsWithoutPinned)
    }, [ticketsWithoutPinned])

    // клик по кнопке закрепления заявки
    const setPin = useCallback(
        (ticket: any) => {
            let _pinnedList: any = [...pinnedList]
            if (_pinnedList.map((p: any) => p.id).includes(ticket.id)) {
                _pinnedList = _pinnedList.filter((pin: any) => pin.id !== ticket.id)
            } else {
                _pinnedList.push(ticket)
            }

            localforage.setItem('ticketsPinned', JSON.stringify(_pinnedList) || [])
            setPinnedList(_pinnedList)
        },
        [pinnedList],
    )

    const collapsePins = () => {
        let newMod = pinCollapseMod
        ++newMod
        if (newMod > 2) newMod = 0
        setPinCollapseMod(newMod)
    }

    if (loading || dictsLoading) return <Loader />
    if (error || dictsError) return <div>не удалось загрузить данные!</div>

    return (
        <div className={clsx(_.mainWrapper, !closed && _.isListOpen)}>
            {pinnedList.length > 0 && (
                <div className={clsx(_.pinnedWrapper, _[pinCollapseClasses[pinCollapseMod]])}>
                    <Tab name='Закреплённые' headerAction={collapsePins} isPins>
                        <Table>
                            {pinnedList.map((ticket: any, index, list) => (
                                <Row
                                    key={ticket.id}
                                    ticket={ticket}
                                    states={data.ticketStates}
                                    estimateGroups={data.estimateGroups}
                                    selectedDynamicField1={selectedDynamicField1}
                                    selectedDynamicField2={selectedDynamicField2}
                                    delimiter={index < list.length - 1}
                                    pinnedList={pinnedList}
                                    setPin={setPin}
                                />
                            ))}
                        </Table>
                    </Tab>
                </div>
            )}
            {(!data?.tickets || isEmpty(data?.tickets)) && (
                <div className={_.empty}>Согласно установленным фильтрам заявок не найдено</div>
            )}
            <div className={_.listWrapper}>
                <Table>
                    {matrix.map((ticket: any, index, list) => (
                        <Row
                            key={ticket.id}
                            ticket={ticket}
                            brandColor={ticket.shop.brand.color}
                            cityColor={ticket.shop.building.city.color}
                            buildingColor={ticket.shop.building.color}
                            states={data.ticketStates}
                            estimateGroups={data.estimateGroups}
                            selectedDynamicField1={selectedDynamicField1}
                            selectedDynamicField2={selectedDynamicField2}
                            delimiter={index < list.length - 1}
                            pinnedList={pinnedList}
                            setPin={setPin}
                        />
                    ))}
                </Table>
                {/* {Object.values(matrix).map((city, cityIndex, cityList) => (
          <Tab
            key={city.name}
            name={city.name}
            color={city.color}
            level={1}
            spaceAtBottom={cityIndex < cityList.length - 1}
          >
            {Object.values(city.data).map(
              (building, buildingIndex, buildingList) => (
                <Tab
                  key={city.name + building.name}
                  name={building.name}
                  color={building.color}
                  level={2}
                  spaceAtBottom={buildingIndex < buildingList.length - 1}
                >
                  {Object.values(building.data).map(
                    (brand, brandIndex, brandList) => (
                      <Tab
                        key={city.name + building.name + brand.name}
                        name={brand.name}
                        color={brand.color}
                        level={3}
                        spaceAtBottom={brandIndex < brandList.length - 1}
                      >
                        <Table>
                          {brand.data.map((ticket, index, list) => (
                            <Row
                              key={ticket.id}
                              ticket={ticket}
                              states={data.ticketStates}
                              estimateGroups={data.estimateGroups}
                              selectedDynamicField1={selectedDynamicField1}
                              selectedDynamicField2={selectedDynamicField2}
                              delimiter={index < list.length - 1}
                              pinnedList={pinnedList}
                              setPin={setPin}
                            />
                          ))}
                        </Table>
                      </Tab>
                    )
                  )}
                </Tab>
              )
            )}
          </Tab>
        ))} */}
                <Pagination
                    page={page}
                    perPage={perPage}
                    setPage={setPage}
                    setPerPage={setPerPage}
                    ticketsCount={ticketsCount}
                />
            </div>
        </div>
    )
}

export default TicketsPage
