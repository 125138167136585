import React, { memo } from 'react'

interface IClickableTicketCode {
    code: string
    divider?: boolean
}

export const ClickableTicketCode = memo(({ code, divider = false }: IClickableTicketCode) => {
    let href

    if (code.substr(0, 2) === 'SU') href = `https://support.city-gals.com/requests/${code.substring(2, code.length)}`

    if (code.substr(0, 3) === 'SES') {
        const _code = parseInt(code.slice(3), 10) + 150
        href = `https://platforma.ses-support.com/platform.php/failureNotifications/show/id/${_code}`
    }

    return (
        <span>
            {divider && ` • `}
            <a href={href} rel='noopener noreferrer' target='_blank'>
                {code}
            </a>
        </span>
    )
})
