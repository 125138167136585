import React, { memo, useState } from 'react'
// import Select from 'react-select'
import { AiOutlineInfoCircle, AiFillInfoCircle } from 'react-icons/ai'
import { FaTelegramPlane, FaLock } from 'react-icons/fa'
import clsx from 'clsx'

import { dateFmtFromStr, dateTimeFmt } from 'tools'
import ButtonSize from 'components/ButtonSize'
import { TICKET_MESSAGE_CREATE, TICKET_QUERY, TICKET_MESSAGE_UPDATE } from 'connectors/gql/ticket.gql'
import { useCreate, useUpdate } from 'helpers/hooks/editor'
import Loader from 'components/Loader'

// import { messagesList } from './mock'
import _ from './styles.module.css'
import { useKeycloak } from '@react-keycloak/web'

// const options = [
//   { value: 'all', label: 'Всем' },
//   { value: 'single', label: 'Не всем' },
// ]

// const selectStyles = {
//     control: styles => ({ ...styles, minHeight: 24, height: 24, border: 'none', cursor: 'pointer' }),
//     valueContainer: styles => ({ ...styles, minHeight: 24, height: 24 }),
//     indicatorsContainer: styles => ({ ...styles, opacity: 0, height: 0, width: 0 }),
//     indicatorSeparator: styles => ({ ...styles, opacity: 0 }),
//     multiValue: (styles) => ({ ...styles, height: 12 }),
//     multiValueLabel: (styles) => ({ ...styles, padding: 0 }),
//     input: (styles) => ({ ...styles, margin: 0 }),
//     menu: (styles) => ({ ...styles, marginTop: 1 }),
// }

// высота блока в сокращённом виде должна вмещать до 2х пиновых и 2х не пиновых сообщений (последних в хронологии)
// т.о. максимальная высота блока в сокращённом виде 283px. высота 1го сообщения 46px

const LastMessagesFilter = (messagesList: any) => {
    let maxPinned = 99
    let maxNoPinned = 5

    return messagesList.filter((message: any) => {
        if (message.is_pinned && maxPinned > 0) {
            maxPinned--
            return true
        }
        if (!message.is_pinned && maxNoPinned > 0) {
            maxNoPinned--
            return true
        }
        return false
    })
}

const Chat = ({ ticketId, messages }: any) => {
    const [newMessage, setNewMessage] = useState('')
    const [isBigChat, setIsBigChat] = useState(false)

    const { keycloak }: any = useKeycloak()
    const currentUserEmail = keycloak?.tokenParsed?.email || ''

    const { onCreate, loading: createMessageLoading } = useCreate(
        TICKET_MESSAGE_CREATE,
        TICKET_QUERY,
        'ticket',
        'message',
    )

    const createHandler = () => {
        if (newMessage)
            onCreate({ ticket_id: parseInt(ticketId, 10), text: newMessage }).then(() => {
                setNewMessage('')
            })
    }

    const updateAction = useUpdate(TICKET_MESSAGE_UPDATE, 'message')
    const updatePin = (message: any) => updateAction(ticketId)({ id: message.id, is_pinned: !message.is_pinned })

    const messagesList = messages ? [...messages] : []
    console.log(messagesList)
    // messagesList.sort((a, b) => a.created_at - b.created_at)
    // const pinnedList = messagesList.filter(m => !!m.is_pinned)
    const shortMessagesList = LastMessagesFilter(messagesList)
    const shortMessagesListHeight = 20 + (shortMessagesList.length || 1) * 46 // если сообщений нет, оставляем место для уведмления
    const currentList = isBigChat ? messagesList : shortMessagesList

    return (
        <div className={_.wrapper}>
            <div className={_.newMessage}>
                <input
                    className={_.textInput}
                    type='text'
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && createHandler()}
                />
                <button className={_.sendBtn} onClick={createHandler}>
                    <FaTelegramPlane />
                </button>
                {/* <div className={_.selectContainer}>
                    <Select
                        options={options}
                        styles={selectStyles}
                        value={{ value: 'all', label: 'Всем' }}
                        hasValue
                    />
                </div> */}
            </div>

            <div
                className={clsx(_.messages, isBigChat && _.longMessages)}
                style={{
                    ...(isBigChat ? {} : { maxHeight: shortMessagesListHeight, minHeight: shortMessagesListHeight }),
                }}
            >
                {currentList.length ? (
                    currentList.map((message: any) => {
                        // if (message.event === 'log') return (<div className={_.logMessage}>
                        //     {message.text} {message.date}
                        // </div>)
                        return (
                            <div
                                key={message.id}
                                className={clsx(message.user.email === currentUserEmail && _.rightMessage)}
                            >
                                <div
                                    className={clsx(
                                        _.wrapPin,
                                        message.user.email === currentUserEmail && _.rightMessageWrapPin,
                                    )}
                                >
                                    <div className={clsx(_.textMessage, message.is_pinned && _.pinnedMessage)}>
                                        {message.text}
                                    </div>
                                    <div className={_.messagePin} onClick={() => updatePin(message)}>
                                        {message.is_pinned ? <AiFillInfoCircle /> : <AiOutlineInfoCircle />}
                                    </div>
                                </div>
                                <div className={_.textUnderMessage}>
                                    {message.private && <FaLock className={_.lock} />}
                                    Контрактор: {message.user.family_name} {message.user.given_name}{' '}
                                    {dateFmtFromStr(message.created_at)}
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <div className='italic flex justify-center'>Нет сообщений</div>
                )}
            </div>
            <div className={_.bigSizeBtn} onClick={() => setIsBigChat(!isBigChat)}>
                <ButtonSize bigSize={isBigChat} />
            </div>

            {createMessageLoading && <Loader />}
        </div>
    )
}
export default memo(Chat)
