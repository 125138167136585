import { memo, useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'antd'
import clsx from 'clsx'

import { PRIORITIES_QUERY } from 'connectors/gql/priorities.gql'
import { setOptionsFormat } from 'helpers'
import { IPriority, ISelectOption } from 'types'

import _ from './styles.module.css'

const colorById: { [key: number]: string } = {
    1: 'black',
    2: 'orange',
    3: 'red',
}

interface IPrioritySelect {
    value: number
    onChange: (data: number) => void
    isSmall?: boolean
    className?: string
}

const PrioritiesSelect = ({ value, onChange, isSmall = false, ...props }: IPrioritySelect) => {
    const { data } = useQuery(PRIORITIES_QUERY)

    const [priorities, setPriorities] = useState<IPriority[]>(data?.ticket || [])
    const [options, setOptions] = useState<ISelectOption[]>(setOptionsFormat(priorities) || [])

    useEffect(() => {
        if (data) {
            setPriorities(data.priorities)
            setOptions(setOptionsFormat(data.priorities))
        }
    }, [JSON.stringify(data?.priorities || {})]) // eslint-disable-line

    return (
        <Select
            className={clsx(_[colorById[value]], isSmall && 'isSmallSelect')}
            value={options.find((o) => o.value === value)?.value}
            placeholder={'Приоритет не выбран'}
            options={options}
            onChange={onChange}
            optionRender={(option) => {
                return <div className={clsx(_[colorById[option.data.value]], _.option)}>{option.data.label}</div>
            }}
            {...props}
        />
    )
}

export default memo(PrioritiesSelect)
